import {
  Button,
  Chip,
  IconButton,
  ListItemIcon,
  ListItemText,
  Stack,
  SxProps,
  Theme,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import _ from "lodash";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AuthorizedMenuItem from "@/common/components/Auth/AuthorizedMenuItem";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import EntityTagsInfoDisplay from "@/common/components/EntityInfo/EntityTagsInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import BooleanValue from "@/common/components/Form/Display/BooleanValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import { ROUTE_PATH } from "@/common/constants/routing";
import { FilterCatalog } from "@/common/filters/filterCatalog";
import { TagStaticFilterInputDto, TagsHelper } from "@/common/helpers/entity/tags";
import { TenantRequestHelper } from "@/common/helpers/entity/tenantRequest";
import { getTypedPath } from "@/common/helpers/typedPath";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { usePageTabs } from "@/common/hooks/layout/usePageTabs";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { PropagatedDeps } from "@/common/hooks/render/usePropagatedDeps";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { ApiEnumName } from "@/common/services/enum";
import { FilterFieldType } from "@/common/ts/filters";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  FilterDefinitionDto,
  GeneralAttachedTagsInputDto,
  TagEntityType,
  TenantRequestDto,
  TenantRequestGetPaginatedDto,
} from "@/core/api/generated";

import TenantInline from "../../Tenant/TenantInline";
import TenantRequestDeleteModal from "../TenantRequestDeleteModal";
import TenantRequestStagePrimaryActions from "../TenantRequestStagePrimaryActions";

export enum TenantRequestPaginatedListTabs {
  All = "All",
  Incoming = "Incoming",
  Outcoming = "Outcoming",
}

const defaultDisplayProps = {
  breadcrumbs: true,
  counters: true,
  filters: true,
  create: true,
  viewVariant: ViewLayoutVariant.Page,
};

const defaultPropagatedDeps = {
  refetch: {},
};
export type TenantRequestPaginatedListPropagatedDepsMap = typeof defaultPropagatedDeps;

type TenantRequestGetPaginatedInputDto = Omit<TenantRequestGetPaginatedDto, "tags"> & {
  tags?: TagStaticFilterInputDto;
};

export interface TenantRequestPaginatedListOwnProps {
  displayProps?: Partial<typeof defaultDisplayProps>;
  initialValues?: {
    filterDefinitionDto?: Nil<FilterDefinitionDto>;
  };
  defaultValues?: {
    limit?: number;
  };
  propagatedDeps?: PropagatedDeps<TenantRequestPaginatedListPropagatedDepsMap>;
  sx?: SxProps<Theme>;
}

export type TenantRequestPaginatedListProps = TenantRequestPaginatedListOwnProps;

export default function TenantRequestPaginatedList({
  displayProps,
  initialValues,
  defaultValues,
  propagatedDeps,
  sx,
}: TenantRequestPaginatedListProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const { enqueueSnackbar } = useAppSnackbar();
  const currentTenant = useCurrentTenant();
  const pageTabs = usePageTabs<TenantRequestPaginatedListTabs>({
    tabIdsDefinition: TenantRequestPaginatedListTabs,
    defaultTabId: TenantRequestPaginatedListTabs.All,
    tabs: [],
  });
  const commonRequestParams = useCommonRequestParams<TenantRequestGetPaginatedInputDto>({
    statePersistence: {
      persistenceKey: EntityType.TenantRequest,
    },
    initialValues: {
      filterDefinitionDto: initialValues?.filterDefinitionDto || undefined,
    },
    defaultValues: {
      limit: defaultValues?.limit,
      params: {
        ...defaultValues,
      },
    },
    initParams: (filterDefinition) => {
      const tagFilter = filterDefinition.filterDefinition?.items.find(
        (x) => x.field === getTypedPath<TenantRequestDto>().tags.$path,
      );

      return {
        tags: {
          operator: tagFilter?.operator,
          query: tagFilter?.value as GeneralAttachedTagsInputDto,
        } as TagStaticFilterInputDto,
      };
    },
  });

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [toDelete, setToDelete] = useState<TenantRequestDto | undefined>(undefined);

  const isCountersVisible = displayProps?.counters && !commonRequestParams.isAnyFilters;

  const countersRequest = useApiRequest(
    apiClient.tenantRequestsApi.apiV1TenantToTenantTenantRequestsCountersGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
    },
  );
  const counters = countersRequest?.data;

  const paginatedTenantRequestsRequest = useApiRequest(
    apiClient.tenantRequestsApi.apiV1TenantToTenantTenantRequestsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      tenantRequestGetPaginatedDto: {
        ..._.omit(commonRequestParams.params, "tags"),
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
        tags:
          commonRequestParams.filterDefinition?.items
            .filter((x) => x.field === getTypedPath<TenantRequestDto>().tags.$path)
            .map((x) => TagsHelper.mapFromFilter(x))[0] || undefined,
        receiverTenantId:
          pageTabs.activeTabId === TenantRequestPaginatedListTabs.Incoming
            ? currentTenant?.id || undefined
            : undefined,
        senderTenantId:
          pageTabs.activeTabId === TenantRequestPaginatedListTabs.Outcoming
            ? currentTenant?.id || undefined
            : undefined,
      },
    },
    {
      deps: [
        ...commonRequestParams.deps,
        commonRequestParams.filterDefinition,
        pageTabs.activeTabId,
        propagatedDeps?.depsMap["refetch"],
      ],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const paginatedTenantRequests = paginatedTenantRequestsRequest?.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entities(undefined, EntityType.TenantRequest),
      DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.TenantRequest),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      countersRequest.refetch();
      paginatedTenantRequestsRequest.handleEntityChanged(data);
    },
  });

  return (
    <ViewLayoutV2
      displayProps={displayProps}
      header={
        <SimpleViewPageHeader
          title='Requests'
          primaryActions={
            displayProps?.create && (
              <AuthorizedElement permissionsAny={[AppPermission.TenantRequestManage]}>
                <Button
                  variant='contained'
                  color='primary'
                  startIcon={<AppIcon of='add' />}
                  component={RouterLink}
                  to={ROUTE_PATH.TENANT_REQUEST_CREATE}
                >
                  Create new request
                </Button>
              </AuthorizedElement>
            )
          }
        />
      }
    >
      <DataTabular<TenantRequestDto>
        tabs={{
          value: pageTabs.activeTabId,
          onChange: (e, val) => pageTabs.setActiveTab(val),
          tabs: [
            {
              label: (
                <Box>
                  {TenantRequestPaginatedListTabs.All}
                  {isCountersVisible && counters && (
                    <Tooltip title='Total'>
                      <Chip
                        sx={{ ml: 1 }}
                        size='small'
                        color='default'
                        variant='outlined'
                        label={counters?.totalCount ?? "..."}
                      />
                    </Tooltip>
                  )}
                </Box>
              ),
              value: TenantRequestPaginatedListTabs.All,
            },
            {
              label: (
                <Box>
                  {TenantRequestPaginatedListTabs.Incoming}
                  {isCountersVisible && counters && (
                    <Tooltip title='Total'>
                      <Chip
                        sx={{ ml: 1 }}
                        size='small'
                        color='default'
                        variant='outlined'
                        label={counters?.incoming?.totalCount ?? "..."}
                      />
                    </Tooltip>
                  )}
                </Box>
              ),
              value: TenantRequestPaginatedListTabs.Incoming,
            },
            {
              label: (
                <Box>
                  {TenantRequestPaginatedListTabs.Outcoming}
                  {isCountersVisible && counters && (
                    <Tooltip title='Total'>
                      <Chip
                        sx={{ ml: 1 }}
                        size='small'
                        color='default'
                        variant='outlined'
                        label={counters?.outcoming?.totalCount ?? "..."}
                      />
                    </Tooltip>
                  )}
                </Box>
              ),
              value: TenantRequestPaginatedListTabs.Outcoming,
            },
          ],
        }}
        columns={[
          {
            field: getTypedPath<TenantRequestDto>().id.$path,
            title: "ID",
            width: 250,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.id,
            filters: {
              fieldType: FilterFieldType.Id,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<TenantRequestDto>().name.$path,
            title: "Name",
            width: 250,
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.name || "-",
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<TenantRequestDto>().description.$path,
            title: "Description",
            width: 100,
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) => item.description || "-",
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<TenantRequestDto>().content.type.$path,
            title: "Type",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => (
              <InlineApiEnumValue type='TenantRequestType' value={item.content?.type} />
            ),
            filters: {
              fieldType: FilterFieldType.Enum,
              isUseDefaultOperators: true,
              fieldTypeMeta: {
                enum: {
                  enumName: ApiEnumName.TenantRequestType,
                },
              },
            },
          },
          {
            field: getTypedPath<TenantRequestDto>().content.operation.operationType.$path,
            title: "Operation type",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.content?.operation ? (
                <InlineApiEnumValue
                  type='TenantRequestOperationType'
                  value={item.content.operation?.operationType}
                />
              ) : (
                "-"
              ),
            filters: {
              fieldType: FilterFieldType.Enum,
              isUseDefaultOperators: true,
              fieldTypeMeta: {
                enum: {
                  enumName: ApiEnumName.TenantRequestOperationType,
                },
              },
            },
          },
          {
            field: getTypedPath<TenantRequestDto>().stageHistory.stage.$path,
            title: "Stage",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => (
              <InlineApiEnumValue type='TenantRequestStage' value={item.stageHistory?.stage} />
            ),
            filters: {
              fieldType: FilterFieldType.Enum,
              isUseDefaultOperators: true,
              fieldTypeMeta: {
                enum: {
                  enumName: ApiEnumName.TenantRequestStage,
                },
              },
            },
          },
          {
            field: getTypedPath<TenantRequestDto>().isOpen.$path,
            title: "Is open",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => <BooleanValue value={item.isOpen} />,
            filters: {
              fieldType: FilterFieldType.Boolean,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<TenantRequestDto>().senderTenant.id.$path,
            title: "Sender",
            width: 250,
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => <TenantInline entity={item.senderTenant} />,
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfTenant(),
            },
          },
          {
            field: getTypedPath<TenantRequestDto>().receiverTenant.id.$path,
            title: "Receiver",
            width: 250,
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => <TenantInline entity={item.receiverTenant} />,
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfTenant(),
            },
          },
          {
            field: getTypedPath<TenantRequestDto>().tags.$path,
            title: "Tags",
            flex: 0,
            renderCell: (item) => (
              <EntityTagsInfoDisplay
                entityType={TagEntityType.TenantRequest}
                entity={item}
                noDataPlaceholder='-'
                edit={{
                  onSaved: (newValue) =>
                    paginatedTenantRequestsRequest.updateData((data) => {
                      data.items?.forEach((item2) => {
                        if (item2.id === item.id) {
                          item2.tags = newValue || undefined;
                        }
                      });
                    }),
                }}
              />
            ),
            isVisible: true,
            isHideable: true,
            filters: {
              fieldType: FilterFieldType.Arbitrary,
              isUseSingleFilter: true,
              operators: FilterCatalog.getOperatorsForTagsField(),
            },
            isFilterable: true,
            isSortable: false,
            isColumnMenuDisabled: true,
            isToDisabled: true,
          },
        ]}
        rows={paginatedTenantRequests?.items}
        isLoading={paginatedTenantRequestsRequest.isLoading}
        getRowId={(item) => item.id!}
        rowTo={(item) => ROUTE_PATH.TENANT_REQUEST_VIEW(item.id)}
        rowActions={{
          variant: "arbitrary",
          renderActions: ({ item }) =>
            TenantRequestHelper.isSentByMe(currentTenant, item) ? (
              <Stack direction='row' spacing={0.5} justifyContent='flex-end' alignItems='center'>
                <TenantRequestStagePrimaryActions
                  variant='compact'
                  tenantRequest={item}
                  onUpdate={(newValue) => {
                    paginatedTenantRequestsRequest.updateData((data) => {
                      data.items = data.items?.map((x) => (x.id === newValue.id ? newValue : x));
                    });
                  }}
                />

                <MenuWithTrigger
                  withAuthCloseOnClick
                  trigger={
                    <IconButton>
                      <AppIcon of='moreVert' />
                    </IconButton>
                  }
                >
                  {TenantRequestHelper.isSentByMe(currentTenant, item) && (
                    <AuthorizedMenuItem
                      permissions={[AppPermission.TenantRequestManage]}
                      disabled={!TenantRequestHelper.canBeEdited(item)}
                      component={RouterLink}
                      to={ROUTE_PATH.TENANT_REQUEST_EDIT(item.id)}
                    >
                      <ListItemIcon>
                        <AppIcon of='edit' fontSize='small' />
                      </ListItemIcon>
                      <ListItemText>Edit</ListItemText>
                    </AuthorizedMenuItem>
                  )}
                  {TenantRequestHelper.isSentByMe(currentTenant, item) && (
                    <AuthorizedMenuItem
                      permissions={[AppPermission.TenantRequestManage]}
                      disabled={!TenantRequestHelper.canBeDeleted(item)}
                      onClick={() => {
                        setToDelete(item);
                        setIsDeleteModalOpen(true);
                      }}
                    >
                      <ListItemIcon>
                        <AppIcon of='delete' fontSize='small' />
                      </ListItemIcon>
                      <ListItemText>Delete</ListItemText>
                    </AuthorizedMenuItem>
                  )}
                </MenuWithTrigger>
              </Stack>
            ) : undefined,
        }}
        statePersistence={commonRequestParams.dataTabularProps.statePersistence}
        pagination={commonRequestParams.dataTabularProps.pagination}
        sort={commonRequestParams.dataTabularProps.sort}
        quickFilter={commonRequestParams.dataTabularProps.quickFilter}
        filters={commonRequestParams.dataTabularProps.filters}
        refetch={commonRequestParams.dataTabularProps.refetch}
      />

      {/* Delete */}
      {toDelete && (
        <TenantRequestDeleteModal
          entity={toDelete}
          open={isDeleteModalOpen}
          onClose={() => {
            setIsDeleteModalOpen(false);
            setToDelete(undefined);
          }}
          onDelete={() => {
            paginatedTenantRequestsRequest.refetch();
          }}
        />
      )}
    </ViewLayoutV2>
  );
}
