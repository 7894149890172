import { apiClient } from "@/core/api/ApiClient";
import { EntityType, WashDto } from "@/core/api/generated";

import BaseEntityDeleteModal, {
  BaseEntityDeleteModalInheritableProps,
} from "../components/BaseEntityDeleteModal";

export interface OwnProps {
  entity: WashDto;
}

type Props = OwnProps & BaseEntityDeleteModalInheritableProps;

export default function WashDeleteModal({ entity, ...otherProps }: Props) {
  return (
    <BaseEntityDeleteModal
      entityType={EntityType.Wash}
      entity={entity}
      entityId={entity.id}
      deleteFunc={async (params) => {
        await apiClient.washesApi.apiV1WashesWashIdDelete({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          washId: params.entityId,
        });
      }}
      {...otherProps}
    />
  );
}
