import { BarChart } from "@mui/x-charts";
import _ from "lodash";
import { useEffect, useMemo } from "react";

import { NumberHelper } from "@/common/helpers/number";
import { WidgetHelper } from "@/common/helpers/widget";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { ApiEnumName, enumService } from "@/common/services/enum";
import { apiClient } from "@/core/api/ApiClient";

import AppIcon from "../../Icons/AppIcon";
import BaseWidget from "../Base/BaseWidget";
import WidgetContent from "../Base/WidgetContent";
import WidgetHeader from "../Base/WidgetHeader";
import WidgetTitle from "../Base/WidgetTitle";
import WidgetTitleDefaultActions from "../Base/WidgetTitleDefaultActions";

export default function VehicleStatusSummaryWidget() {
  const dataRequest = useApiRequest(
    apiClient.widgetDataApi.apiV1WidgetDataVehicleStatusSummaryGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
    },
    {
      deps: [],
    },
  );
  const data = dataRequest?.data;

  useEffect(() => {
    return WidgetHelper.handleRefetchAndRecomputeDataRequestInEffect((requestParams) => {
      dataRequest.refetchWithNewParams({
        widgetDataGetBaseDto: {
          ...requestParams,
        },
      });
    });
  }, [dataRequest]);

  const chartData = useMemo(
    () => ({
      data: data?.vehicleStatus?.points?.map((x) => x.value) ?? [],
      xLabels:
        data?.vehicleStatus?.points?.map((x) =>
          enumService.getEnumValueName(ApiEnumName.VehicleStatus, x.label),
        ) ?? [],
    }),
    [data],
  );

  return (
    <BaseWidget>
      <WidgetHeader>
        <WidgetTitle
          icon={<AppIcon of='status' />}
          actions={
            <WidgetTitleDefaultActions
              data={data}
              onRecompute={async (requestParams) => {
                await dataRequest.refetchWithNewParams({
                  widgetDataGetBaseDto: {
                    ...requestParams,
                  },
                });
              }}
            />
          }
        >
          Vehicles by status
        </WidgetTitle>
      </WidgetHeader>

      <WidgetContent>
        <BarChart
          width={undefined} // undefined is responsive
          height={300}
          margin={{ top: 40, right: 10, bottom: 20, left: 140 }}
          layout='horizontal'
          grid={{ vertical: true, horizontal: false }}
          loading={dataRequest.isLoading}
          series={[
            {
              data: chartData.data,
              label: (location) =>
                location === "legend"
                  ? `Total vehicles: ${data?.vehicleTotalCount ?? "-"}`
                  : "Vehicles",
            },
          ]}
          // xAxis={[{ label: "Count" }]}
          xAxis={[
            {
              valueFormatter: (value) => NumberHelper.abbreviate(+value),
            },
          ]}
          yAxis={[{ data: chartData.xLabels, scaleType: "band", tickPlacement: "middle" }]}
          barLabel={(item, context) => {
            return _.isNil(item.value) || context.bar.width < 15 || context.bar.height < 15
              ? null
              : NumberHelper.abbreviate(item.value);
          }}
          slotProps={{
            legend: {
              hidden: false,
              direction: "row",
              position: { vertical: "top", horizontal: "right" },
              padding: 0,
            },
          }}
        ></BarChart>
      </WidgetContent>
    </BaseWidget>
  );
}
