import { Card, CardProps } from "@mui/material";
import { forwardRef } from "react";

export interface AppCardProps extends CardProps {
  variant2?: "standard" | "data";
}

/** Extends MUI's Card. */
export default forwardRef<HTMLDivElement, AppCardProps>(function AppCard(
  { variant2 = "standard", sx, children, ...otherProps }: AppCardProps,
  ref,
) {
  return (
    <Card
      ref={ref}
      sx={{
        ...(variant2 === "data" && {
          borderTopLeftRadius: 2,
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10,
          borderBottomLeftRadius: 2,
          borderLeftWidth: 3,
          borderLeftStyle: "solid",
          borderLeftColor: (th) => th.palette.divider,
        }),

        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </Card>
  );
});
