import { BarChart } from "@mui/x-charts";
import _ from "lodash";
import { useEffect, useMemo } from "react";

import { NumberHelper } from "@/common/helpers/number";
import { WidgetHelper } from "@/common/helpers/widget";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";

import AppIcon from "../../Icons/AppIcon";
import BaseWidget from "../Base/BaseWidget";
import WidgetContent from "../Base/WidgetContent";
import WidgetHeader from "../Base/WidgetHeader";
import WidgetTitle from "../Base/WidgetTitle";
import WidgetTitleDefaultActions from "../Base/WidgetTitleDefaultActions";

export default function DamageAndIssueSummaryWidget() {
  const dataRequest = useApiRequest(
    apiClient.widgetDataApi.apiV1WidgetDataDamageAndIssueSummaryGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
    },
    {
      deps: [],
    },
  );
  const data = dataRequest?.data;

  useEffect(() => {
    return WidgetHelper.handleRefetchAndRecomputeDataRequestInEffect((requestParams) => {
      dataRequest.refetchWithNewParams({
        widgetDataGetBaseDto: {
          ...requestParams,
        },
      });
    });
  }, [dataRequest]);

  const chartData = useMemo(
    () => ({
      data: data
        ? [
            data.vehicleWithDamageActual?.count ?? 0,
            data.damageActual?.count ?? 0,
            data.assessmentPending?.count ?? 0,
            data.assessmentActionRequired?.count ?? 0,
          ]
        : [],
      xLabels: data
        ? [
            { short: "Unrepaired vehicles", long: "Vehicles with unrepaired/actual damages" },
            { short: "Unrepaired damages", long: "Unrepaired/actual damages across all vehicles" },
            { short: "Active assessment cases", long: "Active unresolved assessment cases" },
            { short: "Action required", long: "Assessment cases which require action" },
          ]
        : [],
    }),
    [data],
  );

  return (
    <BaseWidget>
      <WidgetHeader>
        <WidgetTitle
          icon={<AppIcon of='damageShield' />}
          actions={
            <WidgetTitleDefaultActions
              data={data}
              onRecompute={async (requestParams) => {
                await dataRequest.refetchWithNewParams({
                  widgetDataGetBaseDto: {
                    ...requestParams,
                  },
                });
              }}
            />
          }
        >
          Damages & issues
        </WidgetTitle>
      </WidgetHeader>

      <WidgetContent>
        <BarChart
          width={undefined} // undefined is responsive
          height={300}
          margin={{ top: 40, right: 10, bottom: 60, left: 40 }}
          layout='vertical'
          grid={{ vertical: false, horizontal: true }}
          loading={dataRequest.isLoading}
          series={[
            {
              data: chartData.data,
              label: "Total count",
            },
          ]}
          xAxis={[
            {
              data: chartData.xLabels,
              scaleType: "band",
              tickPlacement: "middle",
              valueFormatter: (value, context) =>
                context.location === "tick"
                  ? `${value.short.toString().split(" ").join("\n")}`
                  : value.long.toString(),
            },
          ]}
          yAxis={[
            {
              valueFormatter: (value) => NumberHelper.abbreviate(+value),
            },
          ]}
          barLabel={(item, context) => {
            return _.isNil(item.value) || context.bar.width < 15 || context.bar.height < 15
              ? null
              : NumberHelper.abbreviate(item.value);
          }}
          slotProps={{
            legend: {
              hidden: false,
              direction: "row",
              position: { vertical: "top", horizontal: "right" },
              padding: 0,
            },
          }}
        />
      </WidgetContent>
    </BaseWidget>
  );
}
