import { WidgetDataGetBaseDto } from "@/core/api/generated";

import { widgetEventEmitter } from "../eventEmmiters/widgetEventEmitter";
import { cast } from "../ts/conversions";

export class WidgetHelper {
  public static handleRefetchAndRecomputeDataRequestInEffect(
    callback: (requestParams: Partial<WidgetDataGetBaseDto>) => void,
  ) {
    const subscription1 = widgetEventEmitter.on2("refetchAllDataRequested", (params) => {
      callback(
        cast<WidgetDataGetBaseDto>({
          isRecompute: false,
        }),
      );
    });
    const subscription2 = widgetEventEmitter.on2("recomputeAllDataRequested", (params) => {
      callback(
        cast<WidgetDataGetBaseDto>({
          isRecompute: true,
        }),
      );
    });

    return () => {
      subscription1.off();
      subscription2.off();
    };
  }
}
