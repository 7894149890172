import { Button, TypographyProps } from "@mui/material";

import PageTabsV2 from "@/App/Layouts/PageBody/PageTabsV2";
import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useCommonViewParams } from "@/common/hooks/layout/useCommonViewParams";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { useAppHistory } from "@/common/hooks/useAppHistory";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  SupplierDto,
  TagEntityType,
} from "@/core/api/generated";

import GeneralAttachedTagsDisplay from "../../General/GeneralTag/GeneralAttachedTagsDisplay";
import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import SupplierMenu from "../SupplierMenu";
import GeneralHistoryTabContent from "./Tabs/GeneralHistoryTabContent";
import OverviewTabContent from "./Tabs/OverviewTabContent";

export enum SupplierViewPageTabs {
  Overview = "Overview",
  GeneralHistory = "GeneralHistory",
}

const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};

interface OwnProps extends BaseEntityViewInheritableProps<SupplierDto> {
  supplierId: string | null | undefined;
  supplier: SupplierDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
}

type Props = OwnProps;

export default function SupplierView({
  supplierId,
  supplier,
  displayProps = defaultDisplayProps,
  headerProps = defaultHeaderProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const history = useAppHistory();
  const currentTenant = useCurrentTenant();
  const commonViewParams = useCommonViewParams({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityView(EntityType.Supplier),
      viewVariant: displayProps.viewVariant,
    },
  });

  const supplierRequest = useApiRequest(
    apiClient.suppliersApi.apiV1SuppliersSupplierIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      supplierId: supplierId!,
    },
    {
      deps: [supplierId],
      skip: !supplierId || !!supplier,
    },
  );
  supplier = supplier || supplierRequest?.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(currentTenant?.id, EntityType.Supplier, supplierId || ""),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      supplierRequest.handleEntityChanged(data);
    },
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        supplier && {
          idValue: supplier.id!,
          newTitle: supplier.localNumber || "",
        }) ||
      undefined,
  });

  return (
    <BaseEntityView
      entityType={EntityType.Supplier}
      entityId={supplierId}
      entity={supplier}
      entityRequest={supplierRequest}
    >
      <ViewLayoutV2
        displayProps={displayProps}
        header={
          displayProps.header && (
            <DetailedViewPageHeader
              image={undefined}
              title={`Supplier ${supplier?.name || supplier?.contact!.personName?.name}`}
              titleProps={{
                to: headerProps?.withLink ? ROUTE_PATH.SUPPLIER_VIEW(supplier?.id) : undefined,
                typographyProps: headerProps?.typographyProps,
              }}
              subtitle={
                <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                  <GeneralAttachedTagsDisplay
                    tags={supplier?.tags}
                    defaultIsFolded={false}
                    edit={{
                      entityType: TagEntityType.Supplier,
                      entityId: supplier?.id,
                      onSaved: (newValue) => {
                        supplierRequest.updateData((x) => {
                          x.tags = newValue || undefined;
                        });
                      },
                    }}
                  />
                </AuthorizedElement>
              }
              primaryActions={
                displayProps.actions && (
                  <AuthorizedElement permissions={[AppPermission.SupplierManage]}>
                    <Button
                      component={AppLink}
                      to={ROUTE_PATH.SUPPLIER_EDIT(supplier?.id)}
                      variant='outlined'
                      color='secondary'
                      size='medium'
                      startIcon={<AppIcon of='edit' />}
                    >
                      Edit
                    </Button>
                  </AuthorizedElement>
                )
              }
              secondaryActions={
                displayProps.actions &&
                supplier && (
                  <SupplierMenu
                    entity={supplier}
                    onDelete={() => history.goBack()}
                    onUpdate={(newValue) =>
                      newValue ? supplierRequest.replaceData(newValue) : supplierRequest.refetch()
                    }
                    displayProps={{
                      actions: {
                        edit: false,
                        delete: true,
                      },
                    }}
                  />
                )
              }
            />
          )
        }
      >
        <PageTabsV2
          tabIdsDefinition={SupplierViewPageTabs}
          defaultTabId={SupplierViewPageTabs.Overview}
          viewVariant={displayProps.viewVariant}
          commonViewParams={commonViewParams}
          tabs={[
            { label: "Overview", value: SupplierViewPageTabs.Overview, isHideable: false },
            { label: "History", value: SupplierViewPageTabs.GeneralHistory },
          ]}
        >
          {({ activeTabId: activeTab }) =>
            supplier && (
              <>
                {activeTab === SupplierViewPageTabs.Overview && (
                  <OverviewTabContent supplier={supplier} />
                )}
                {activeTab === SupplierViewPageTabs.GeneralHistory && (
                  <GeneralHistoryTabContent supplier={supplier} />
                )}
              </>
            )
          }
        </PageTabsV2>
      </ViewLayoutV2>
    </BaseEntityView>
  );
}
