import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useMemo, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import InlineUser from "@/App/MainAppView/components/User/InlineUser";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppIconButton from "@/common/components/Button/AppIconButton";
import DataExportModal from "@/common/components/DataExport/DataExportModal";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import EntityTagsInfoDisplay from "@/common/components/EntityInfo/EntityTagsInfoDisplay";
import AppIcon from "@/common/components/Icons/AppIcon";
import TableCellContentOfAttachments from "@/common/components/Table/TableCell/TableCellContentOfAttachments";
import TableCellContentOfCount from "@/common/components/Table/TableCell/TableCellContentOfCount";
import { ROUTE_PATH } from "@/common/constants/routing";
import { FilterCatalog } from "@/common/filters/filterCatalog";
import { EntityHelper } from "@/common/helpers/entity";
import { TagStaticFilterInputDto, TagsHelper } from "@/common/helpers/entity/tags";
import { getTypedPath } from "@/common/helpers/typedPath";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { PropagatedDeps } from "@/common/hooks/render/usePropagatedDeps";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { FilterFieldType } from "@/common/ts/filters";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DamageDetectionDto,
  DamageDetectionGetPaginatedDto,
  DataUpdatesHubClientMethodName,
  EntityType,
  ExportEntityType,
  GeneralAttachedTagsInputDto,
  TagEntityType,
} from "@/core/api/generated";

import ContractInline from "../../Contract/ContractInline";
import AffiliationInfoDisplay from "../../EntityAffiliation/AffiliationInfoDisplay";
import GeneralInspectorDisplay from "../../General/Display/GeneralInspectorDisplay";
import GeneralAttachedTagsOfEntitiesEditModal from "../../General/GeneralTag/GeneralAttachedTagsOfEntitiesEditModal";
import TenantStructureMembershipOfEntityEditModal from "../../Membership/TenantStructureMembershipOfEntityEditModal";
import VehicleInline from "../../Vehicle/VehicleInline";
import VehicleMileageInline from "../../Vehicle/VehicleMileageInline";
import VehicleVisualModelInline from "../../VehicleVisualModel/VehicleVisualModelInline";
import DamageDetectionMenu from "../DamageDetectionMenu";
import DamageDetectionsDeleteModal from "../DamageDetectionsDeleteModal";

enum BulkActionFlags {
  UpdateTenantStructureMembership = "UpdateTenantStructureMembership",
  UpdateTags = "UpdateTags",
  Export = "Export",
  Delete = "Delete",
}
const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  filters: true,
  create: true,
  edit: true,
  delete: true,
  viewVariant: ViewLayoutVariant.Page,
};

const defaultPropagatedDeps = {
  refetch: {},
};
export type DamageDetectionPaginatedListPropagatedDepsMap = typeof defaultPropagatedDeps;

type DamageDetectionGetPaginatedInputDto = Omit<DamageDetectionGetPaginatedDto, "tags"> & {
  tags?: TagStaticFilterInputDto;
};

export interface DamageDetectionPaginatedListOwnProps {
  displayProps?: Partial<typeof defaultDisplayProps>;
  defaultValues?: {
    limit?: number;
    vehicleId?: string | null;
    contractId?: string | null;
    customerId?: string | null;
    anyVehicleDamageIds?: string[] | null;
  };
  propagatedDeps?: PropagatedDeps<DamageDetectionPaginatedListPropagatedDepsMap>;
  sx?: SxProps<Theme>;
}

export type DamageDetectionPaginatedListProps = DamageDetectionPaginatedListOwnProps;

export default function DamageDetectionPaginatedList({
  displayProps,
  defaultValues,
  propagatedDeps,
  sx,
}: DamageDetectionPaginatedListProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const history = useHistory();
  const { enqueueSnackbar } = useAppSnackbar();
  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<DamageDetectionGetPaginatedInputDto>({
    statePersistence: {
      persistenceKey: EntityType.DamageDetection,
    },
    defaultValues: {
      limit: defaultValues?.limit,
      params: {
        ...defaultValues,
      },
    },
    initParams: (filterDefinition) => {
      const tagFilter = filterDefinition.filterDefinition?.items.find(
        (x) => x.field === getTypedPath<DamageDetectionDto>().tags.$path,
      );

      return {
        tags: {
          operator: tagFilter?.operator,
          query: tagFilter?.value as GeneralAttachedTagsInputDto,
        } as TagStaticFilterInputDto,
      };
    },
  });

  const [exportDamageDetectionIds, setExportDamageDetectionIds] = useState<string[] | null>(null);
  const [isDataExportModalOpened, setIsDataExportModalOpened] = useState(false);

  const paginatedDamageDetectionsRequest = useApiRequest(
    apiClient.damageDetectionsApi.apiV1DamageDetectionsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      damageDetectionGetPaginatedDto: {
        ..._.omit(commonRequestParams.params, "tags"),
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        tags:
          commonRequestParams.filterDefinition?.items
            .filter((x) => x.field === getTypedPath<DamageDetectionDto>().tags.$path)
            .map((x) => TagsHelper.mapFromFilter(x))[0] || undefined,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [
        ...commonRequestParams.deps,
        commonRequestParams.filterDefinition,
        propagatedDeps?.depsMap["refetch"],
      ],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const paginatedDamageDetections = useMemo(
    () => paginatedDamageDetectionsRequest?.data,
    [paginatedDamageDetectionsRequest.data],
  );

  const damageDetectionIds = useMemo(
    () => paginatedDamageDetections?.items?.map((item) => item.id!),
    [paginatedDamageDetections?.items],
  );

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.DamageDetection)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedDamageDetectionsRequest.handleEntityChanged(data);
    },
  });

  // menu, dialogs
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);
  const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  return (
    <ViewLayoutV2
      displayProps={displayProps}
      header={
        displayProps?.header && (
          <SimpleViewPageHeader
            title='Damage detections'
            primaryActions={
              displayProps?.create && (
                <AuthorizedElement permissions={[AppPermission.DamageDetectionManage]}>
                  <Button
                    variant='contained'
                    color='primary'
                    startIcon={<AppIcon of='add' />}
                    component={RouterLink}
                    to={ROUTE_PATH.DAMAGE_DETECTION_CREATE({
                      vehicleId: commonRequestParams.params?.vehicleId,
                      contractId: commonRequestParams.params?.contractId,
                    })}
                  >
                    Create new damage detection
                  </Button>
                </AuthorizedElement>
              )
            }
            secondaryActions={
              <>
                <IconButton onClick={handleMoreClick} sx={{ ml: "auto" }}>
                  <AppIcon of='moreVert' />
                </IconButton>

                <Menu anchorEl={menuAnchorEl} open={isMenuOpen} onClose={handleMenuClose}>
                  {/* <MenuItem component={RouterLink} to={ROUTE_PATH.DOCUMENTS_DAMAGE_DETECTIONS()}>
                      <AppIcon of='documents' fontSize='small' sx={{ mr: 1 }} />
                      <Typography component='span'>View documents</Typography>
                    </MenuItem> */}
                  <MenuItem component={RouterLink} to={ROUTE_PATH.DAMAGE_DETECTION_AGGREGATES}>
                    <AppIcon of='damageDetections' fontSize='small' sx={{ mr: 1 }} />
                    <Typography component='span'>View aggregated</Typography>
                  </MenuItem>
                  <AuthorizedElement permissions={[AppPermission.ExportManage]}>
                    <MenuItem onClick={() => setIsDataExportModalOpened(true)}>
                      <AppIcon of='export' fontSize='small' sx={{ mr: 1 }} />
                      <Typography component='span'>Export damage detections</Typography>
                    </MenuItem>
                  </AuthorizedElement>
                </Menu>
              </>
            }
          />
        )
      }
    >
      <DataTabular
        columns={[
          {
            field: getTypedPath<DamageDetectionDto>().id.$path,
            title: "ID",
            width: 250,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.id,
            filters: {
              fieldType: FilterFieldType.Id,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<DamageDetectionDto>().localNumber.$path,
            title: "Number",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => (
              <Stack direction='row' spacing={0.5}>
                <span>{item.localNumber}</span>
              </Stack>
            ),
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: "Info badges",
            title: "Info badges",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) => <EntityChipList entity={item} variant='compact' />,
          },
          {
            field: getTypedPath<DamageDetectionDto>().vehicle.id.$path,
            title: "Vehicle",
            width: 300,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) => <VehicleInline entity={item.vehicle} />,
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfVehicle(),
            },
          },
          {
            field: getTypedPath<DamageDetectionDto>().contract.id.$path,
            title: "Contract",
            width: 300,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) => (item.contract ? <ContractInline entity={item.contract} /> : "-"),
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfContract(),
            },
          },
          {
            field: getTypedPath<DamageDetectionDto>().vehicleVisualModel.id.$path,
            title: "Visual model",
            width: 300,
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.vehicleVisualModel ? (
                <VehicleVisualModelInline entity={item.vehicleVisualModel} />
              ) : (
                "-"
              ),
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfVehicleVisualModel(),
            },
          },
          {
            field: getTypedPath<DamageDetectionDto>().inspectedAt.$path,
            title: "Inspected at",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.inspectedAt ? <Datetime datetime={item.inspectedAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<DamageDetectionDto>().inspector.$path,
            title: "Inspector",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) =>
              item.inspector && (
                <GeneralInspectorDisplay
                  inspector={item.inspector}
                  inlineUserProps={{
                    withLink: false,
                  }}
                />
              ),
          },
          {
            field: getTypedPath<DamageDetectionDto>().mileage.$path,
            title: "Mileage",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => <VehicleMileageInline value={item.mileage} />,
            filters: {
              fieldType: FilterFieldType.Number,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<DamageDetectionDto>().fuelLevel.$path,
            title: "Fuel level",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.fuelLevel ?? "-",
            filters: {
              fieldType: FilterFieldType.Number,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<DamageDetectionDto>().notes.$path,
            title: "Notes",
            width: 100,
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) => item.notes || "-",
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<DamageDetectionDto>().createdAt.$path,
            title: "Created at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.createdAt ? <Datetime datetime={item.createdAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<DamageDetectionDto>().updatedAt.$path,
            title: "Updated at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.updatedAt ? <Datetime datetime={item.updatedAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<DamageDetectionDto>().createdBy.$path,
            title: "Created by",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.createdBy ? <InlineUser userId={item.createdBy} withAvatar /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
          {
            field: getTypedPath<DamageDetectionDto>().updatedBy.$path,
            title: "Updated by",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.updatedBy ? <InlineUser userId={item.updatedBy} withAvatar /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
          {
            field: getTypedPath<DamageDetectionDto>().items.$path,
            title: "Items",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            isToDisabled: true,
            renderCell: (item) => <TableCellContentOfCount count={item.items?.length} />,
          },
          {
            field: getTypedPath<DamageDetectionDto>().attachments.$path,
            title: "Attachments",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            isToDisabled: true,
            renderCell: (item) => <TableCellContentOfAttachments attachments={item.attachments} />,
          },
          {
            field: "affiliation",
            title: "Affiliation",
            description: "Affiliation to departments & locations",
            width: 120,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            isToDisabled: true,
            renderCell: (item) => (
              <AffiliationInfoDisplay
                variant='compact'
                tenantId={item.tenantId}
                departmentIds={item.departmentIds}
                locationIds={item.locationIds}
              />
            ),
          },
          {
            field: getTypedPath<DamageDetectionDto>().tags.$path,
            title: "Tags",
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            isColumnMenuDisabled: false,
            isToDisabled: true,
            renderCell: (item) => (
              <EntityTagsInfoDisplay
                entityType={TagEntityType.DamageDetection}
                entity={item}
                noDataPlaceholder='-'
                edit={{
                  onSaved: (newValue) =>
                    paginatedDamageDetectionsRequest.updateData((data) => {
                      data.items?.forEach((item2) => {
                        if (item2.id === item.id) {
                          item2.tags = newValue || undefined;
                        }
                      });
                    }),
                }}
              />
            ),
            filters: {
              fieldType: FilterFieldType.Arbitrary,
              isUseSingleFilter: true,
              operators: FilterCatalog.getOperatorsForTagsField(),
            },
          },
        ]}
        rows={paginatedDamageDetections?.items}
        isLoading={paginatedDamageDetectionsRequest.isLoading}
        getRowId={(item) => item.id!}
        rowTo={(item) => ROUTE_PATH.DAMAGE_DETECTION_VIEW(item.id)}
        renderRowAction={({ item }) => (
          <DamageDetectionMenu
            entity={item}
            onDelete={() => paginatedDamageDetectionsRequest.refetch()}
            onUpdate={() => paginatedDamageDetectionsRequest.refetch()}
            displayProps={{
              actions: {
                edit: displayProps?.edit || false,
                delete: displayProps?.delete || false,
                addToContract: true,
                downloadPdf: false,
                migrateToNewVisualModel: false,
                viewAllDamages: false,
                createDamageCostEvaluation: false,
                createRepairOperation: false,
              },
            }}
          />
        )}
        statePersistence={commonRequestParams.dataTabularProps.statePersistence}
        pagination={commonRequestParams.dataTabularProps.pagination}
        sort={commonRequestParams.dataTabularProps.sort}
        quickFilter={commonRequestParams.dataTabularProps.quickFilter}
        filters={commonRequestParams.dataTabularProps.filters}
        refetch={commonRequestParams.dataTabularProps.refetch}
        bulkActions={{
          enabled: true,
          definition: BulkActionFlags,
          actionTriggers: ({ currentAction, startAction, selectedIds }) => (
            <>
              <AppIconButton
                tooltipProps={{ title: "Update affiliation" }}
                onClick={() => startAction(BulkActionFlags.UpdateTenantStructureMembership)}
              >
                <AppIcon of='department' />
              </AppIconButton>
              <AppIconButton
                tooltipProps={{ title: "Update tags" }}
                onClick={() => startAction(BulkActionFlags.UpdateTags)}
              >
                <AppIcon of='tag' />
              </AppIconButton>
              <AppIconButton
                tooltipProps={{ title: "Delete" }}
                onClick={() => startAction(BulkActionFlags.Delete)}
              >
                <AppIcon of='delete' />
              </AppIconButton>
              <AppIconButton
                tooltipProps={{ title: "Export" }}
                onClick={() => startAction(BulkActionFlags.Export)}
              >
                <AppIcon of='export' />
              </AppIconButton>
            </>
          ),
          actionHandlers: ({ selectedIds, currentAction, cancelAction, completeAction }) => {
            const selectedEntities = EntityHelper.filterEntitiesByIds(
              paginatedDamageDetections?.items || [],
              selectedIds as string[],
            );
            return (
              <>
                <DamageDetectionsDeleteModal
                  entities={selectedEntities}
                  open={currentAction === BulkActionFlags.Delete}
                  onClose={() => cancelAction()}
                  onDelete={() => {
                    completeAction();
                    paginatedDamageDetectionsRequest.refetch();
                  }}
                />
                <TenantStructureMembershipOfEntityEditModal
                  open={currentAction === BulkActionFlags.UpdateTenantStructureMembership}
                  onClose={() => cancelAction()}
                  tenantStructureMembershipOfEntityEditProps={{
                    entityType: EntityType.DamageDetection,
                    entities: selectedEntities,
                    onSave: () => {
                      completeAction();
                      paginatedDamageDetectionsRequest.refetch();
                    },
                  }}
                />
                <GeneralAttachedTagsOfEntitiesEditModal
                  tagEntityType={TagEntityType.DamageDetection}
                  entities={selectedEntities}
                  open={currentAction === BulkActionFlags.UpdateTags}
                  onClose={() => cancelAction()}
                  onSaved={() => {
                    completeAction();
                    paginatedDamageDetectionsRequest.refetch();
                  }}
                />
                <DataExportModal
                  open={currentAction === BulkActionFlags.Export}
                  onClose={() => cancelAction()}
                  exportDataProps={{
                    entityType: ExportEntityType.DamageDetection,
                    ids: selectedIds as string[],
                    onExportDone: completeAction,
                    displayProps: {
                      mode: false,
                    },
                  }}
                />
              </>
            );
          },
        }}
      />

      {/* Export data modal */}
      <DataExportModal
        open={isDataExportModalOpened}
        onClose={() => setIsDataExportModalOpened(false)}
        exportDataProps={{
          entityType: ExportEntityType.DamageDetection,
          ids: damageDetectionIds,
          onExportDone: () => {
            setIsDataExportModalOpened(false);
          },
        }}
      />
    </ViewLayoutV2>
  );
}
