import { Box } from "@mui/material";
import { LineChart, LineChartProps } from "@mui/x-charts";
import moment from "moment";
import { useEffect, useMemo } from "react";

import { NumberHelper } from "@/common/helpers/number";
import { WidgetHelper } from "@/common/helpers/widget";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { WidgetDataOfCostPerMonthSummaryGetDto } from "@/core/api/generated";

import AppTooltip from "../../AppTooltip";
import AppIcon from "../../Icons/AppIcon";
import AppTypography from "../../Text/AppTypography";
import BaseWidget from "../Base/BaseWidget";
import WidgetContent from "../Base/WidgetContent";
import WidgetHeader from "../Base/WidgetHeader";
import WidgetTitle from "../Base/WidgetTitle";
import WidgetTitleDefaultActions from "../Base/WidgetTitleDefaultActions";

export default function CostSummaryWidget() {
  const last12MonthsRange = useMemo(
    () => ({
      from: moment().utc().subtract(12, "months").format(),
      to: moment().utc().format(),
    }),
    [],
  );

  const defaultRequestParams = useMemo<WidgetDataOfCostPerMonthSummaryGetDto>(
    () => ({
      range: last12MonthsRange,
    }),
    [],
  );

  const dataRequest = useApiRequest(
    apiClient.widgetDataApi.apiV1WidgetDataCostPerMonthSummaryGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      widgetDataOfCostPerMonthSummaryGetDto: {
        ...defaultRequestParams,
      },
    },
    {
      deps: [],
    },
  );
  const data = dataRequest?.data;

  useEffect(() => {
    return WidgetHelper.handleRefetchAndRecomputeDataRequestInEffect((requestParams) => {
      dataRequest.refetchWithNewParams({
        widgetDataOfCostPerMonthSummaryGetDto: {
          ...defaultRequestParams,
          ...requestParams,
        },
      });
    });
  }, [dataRequest]);

  const chartData = useMemo<{
    series: LineChartProps["series"];
    xLabels: Array<{ short: string; long: string }>;
  }>(
    () => ({
      series: data
        ? [
            {
              label: "Damage cost evaluation",
              id: data.damageCostEvaluation.dataSeriesId,
              data: data.damageCostEvaluation?.points?.map((x) => x.value) ?? [],
            },
            {
              label: "Repair",
              id: data.repairOperation.dataSeriesId,
              data: data.repairOperation?.points?.map((x) => x.value) ?? [],
            },
            {
              label: "Maintenance",
              id: data.maintenance.dataSeriesId,
              data: data.maintenance?.points?.map((x) => x.value) ?? [],
            },
            {
              label: "Wash",
              id: data.wash.dataSeriesId,
              data: data.wash?.points?.map((x) => x.value) ?? [],
            },
            {
              label: "Wheels & tires",
              id: data.wheelOperation.dataSeriesId,
              data: data.wheelOperation?.points?.map((x) => x.value) ?? [],
            },
            {
              label: (location) =>
                location === "legend" ? "C2C operations" : "Company-to-Company (C2C) operations",
              id: data.tenantToTenantOperationTotal.dataSeriesId,
              data: data.tenantToTenantOperationTotal?.points?.map((x) => x.value) ?? [],
            },
            {
              label: "Total",
              id: data.total.dataSeriesId,
              data: data.total?.points?.map((x) => x.value) ?? [],
            },
          ]
        : [],
      xLabels: data
        ? (data.dataLabels?.map((x) => ({
            short: moment(x).format("MMM yyyy"),
            long: moment(x).format("MMMM yyyy"),
          })) ?? [])
        : [],
    }),
    [data],
  );

  return (
    <BaseWidget>
      <WidgetHeader>
        <WidgetTitle
          icon={<AppIcon of='cost' />}
          actions={
            <WidgetTitleDefaultActions
              data={data}
              onRecompute={async (requestParams) => {
                await dataRequest.refetchWithNewParams({
                  widgetDataOfCostPerMonthSummaryGetDto: {
                    ...defaultRequestParams,
                    ...requestParams,
                  },
                });
              }}
            />
          }
        >
          <AppTooltip
            title={
              <Box>
                <Box>Costs for completed operations.</Box>
                <Box>NB: Company-to-Company (C2C) operations are not included in the total.</Box>
              </Box>
            }
          >
            <AppTypography isInheritFontStyle decoration={{ variant: "helpText" }}>
              Costs
            </AppTypography>
          </AppTooltip>
        </WidgetTitle>
      </WidgetHeader>

      <WidgetContent>
        <LineChart
          width={undefined} // undefined is responsive
          height={300}
          margin={{ top: 10, right: 240, bottom: 60, left: 40 }}
          grid={{ vertical: true, horizontal: true }}
          loading={dataRequest.isLoading}
          series={chartData.series}
          xAxis={[
            {
              data: chartData.xLabels,
              scaleType: "point",
              tickPlacement: "start",
              valueFormatter: (value, context) =>
                context.location === "tick"
                  ? `${value.short.toString().split(" ").join("\n")}`
                  : `${value.long.toString().split(" ").join("\n")}`,
            },
          ]}
          yAxis={[
            {
              valueFormatter: (value) => NumberHelper.abbreviate(+value),
            },
          ]}
          slotProps={{
            legend: {
              hidden: false,
              direction: "column",
              position: { vertical: "top", horizontal: "right" },
              padding: 0,
            },
          }}
        />
      </WidgetContent>
    </BaseWidget>
  );
}
