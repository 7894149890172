import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  InputLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { Formik } from "formik";
import _ from "lodash";
import moment, { Moment } from "moment";
import { useState } from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { DATETIME_FORMATS } from "@/common/constants/common";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { FileItem } from "@/common/fileItem";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useCurrentCurrency } from "@/common/hooks/useCurrentCurrency";
import { useUserAffiliation } from "@/common/hooks/useUserAffiliation";
import { useUserProfile } from "@/common/hooks/useUserProfile";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  CreateWashDto,
  EntityType,
  GeneralStageUpdateDtoOfWashStage,
  UpdateWashDto,
  VehicleDto,
  WashDto,
  WashScheduleType,
  WashStage,
} from "@/core/api/generated";

import SplitDropdownButton from "../../Button/SplitDropdownButton";
import FoldableBlock from "../../Display/FoldableBlock";
import ApiEnumSelect from "../../Enum/ApiEnumSelect";
import ApiEnumsAutocomplete from "../../Enum/ApiEnumsAutocomplete";
import GeneralValidationError from "../../Error/GeneralValidationError";
import FileUploader from "../../Files/FileUploader";
import FormActions from "../../Form/FormActions";
import FormikComputedField from "../../Form/Formik/FormikComputedField";
import AppTextArea from "../../Form/Input/AppTextArea";
import FuelLevelInput from "../../Form/Input/FuelLevelInput";
import MileageInput from "../../Form/Input/MileageInput";
import PriceSummaryInput from "../../PriceSummary/PriceSummaryInput";
import EntityAffiliationInput from "../EntityAffiliation/EntityAffiliationInput";
import GeneralAttachedTagsInput from "../General/GeneralTag/GeneralAttachedTagsInput";
import SupplierAutocompleteOrCreate from "../Supplier/SupplierAutocompleteOrCreate";
import VehicleAutocompleteOrCreate from "../Vehicle/VehicleAutocompleteOrCreate";
import BaseEntityCreateUpdate, {
  BaseEntityCreateUpdateInheritableProps,
} from "../components/BaseEntityCreateUpdate";

type DefaultValues = {
  vehicleId?: CreateWashDto["vehicleId"];
  supplierId?: CreateWashDto["supplierId"];
  departmentId?: CreateWashDto["departmentId"];
  locationId?: CreateWashDto["locationId"];
  tenantRequestsMeta?: CreateWashDto["tenantRequestsMeta"];
  currency?: CreateWashDto["currency"];
  scheduleType?: CreateWashDto["scheduleType"];
};

export interface WashCreateUpdateOwnProps
  extends BaseEntityCreateUpdateInheritableProps<WashDto, DefaultValues> {
  washId?: string;
}

export type WashCreateUpdateProps = WashCreateUpdateOwnProps;

export default function WashCreateUpdate({
  washId,
  defaultValues,
  onCreate,
  onUpdate,
  onSave,
}: WashCreateUpdateProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const profile = useUserProfile();
  const history = useHistory();
  const isCreate = !washId;
  const isEdit = !!washId;
  const currentCurrency = useCurrentCurrency();

  const [isAttachmentFilesUploading, setIsAttachmentFilesUploading] = useState(false);

  const washRequest = useApiRequest(
    apiClient.washesApi.apiV1WashesWashIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      washId: washId!,
    },
    {
      deps: [washId],
      skip: !washId,
    },
  );
  const wash = washRequest?.data;

  const { departments, locations } = useUserAffiliation();

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: wash && {
      idValue: wash.id!,
      newTitle: wash.localNumber || "",
    },
  });

  const vehicleIdComputed = defaultValues?.vehicleId || wash?.vehicle?.id;

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.Wash}
      entityId={washId}
      entity={wash}
      entityRequest={washRequest}
      isIniting={washRequest.isLoading || !currentCurrency}
    >
      <Formik<
        CreateWashDto &
          UpdateWashDto &
          BaseFormikValues & {
            vehicle: VehicleDto | undefined;
            supplier?: WashDto["supplier"];
            initialAttachments: WashDto["attachments"];
            total?: number;
          }
      >
        initialValues={{
          // temporary
          departmentId:
            defaultValues?.departmentId ||
            (wash?.departmentIds || []).at(0) ||
            (departments && departments[0] && departments[0].id) ||
            undefined,
          // temporary
          locationId:
            defaultValues?.locationId ||
            (wash?.locationIds || []).at(0) ||
            (locations && locations[0] && locations[0].id) ||
            undefined,
          vehicleId: wash?.vehicle?.id || defaultValues?.vehicleId || undefined,
          vehicle: undefined,
          supplierId: wash?.supplier?.id || defaultValues?.vehicleId || undefined,
          types: wash?.types || undefined,
          scheduleType:
            wash?.scheduleType ||
            defaultValues?.scheduleType ||
            WashScheduleType.Unscheduled ||
            undefined,
          scheduledAt: wash?.scheduledAt || undefined,
          responsibleUser: {
            isCurrentUser: true,
            ...(wash?.responsibleUser || {}),
          },
          mileage: wash?.mileage ?? undefined,
          fuelLevel: wash?.fuelLevel ?? undefined,
          notes: wash?.notes || "",
          currency: defaultValues?.currency || wash?.currency || currentCurrency || undefined,
          price: wash?.price || 0,
          discount: wash?.discount || undefined,
          tax: wash?.tax || undefined,
          initialAttachments: wash?.attachments || undefined,
          attachments: !_.isEmpty(wash?.attachments) ? wash?.attachments : undefined,
          tenantRequestsMeta:
            wash?.tenantRequestsMeta || defaultValues?.tenantRequestsMeta || undefined,
          tags: wash?.tags || undefined,
          newStage: undefined,
          submit: "",
        }}
        validationSchema={Yup.object().shape({})}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          const values2: typeof values = {
            ...values,
            scheduledAt: values.scheduledAt ? moment(values.scheduledAt).utc().format() : undefined,
          };

          try {
            if (isCreate) {
              const response = await apiClient.washesApi.apiV1WashesPost({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                createWashDto: { ...values2 },
              });
              enqueueSnackbar("Wash created.", { variant: "success" });
              onCreate && (await onCreate(response.data));
              onSave && (await onSave(response.data));
            } else {
              const response = await apiClient.washesApi.apiV1WashesWashIdPut({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                washId,
                updateWashDto: {
                  ...values2,
                },
              });
              enqueueSnackbar("Wash updated.", { variant: "success" });
              onUpdate && (await onUpdate(response.data));
              onSave && (await onSave(response.data));
            }

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setErrors,
          setFieldValue,
          setValues,
          submitForm,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <FormikComputedField<typeof values, Pick<typeof values, "vehicle">>
                deps={[values?.vehicleId]}
                compute={async (v) => {
                  const vehicleResponse = v.vehicleId
                    ? await apiClient.vehiclesApi.apiV1VehiclesVehicleIdGet({
                        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                        vehicleId: v.vehicleId!,
                      })
                    : null;
                  return {
                    vehicle: vehicleResponse?.data,
                  };
                }}
                onComputed={(computed) => {
                  setFieldValue("vehicle", computed.vehicle);
                }}
              />
              <Stack spacing={2}>
                <Stack spacing={2}>
                  <Box>
                    {/* Tags */}
                    <FormControl margin='dense' fullWidth>
                      <GeneralAttachedTagsInput
                        value={values.tags}
                        onChange={(newValue) => {
                          setFieldValue("tags", newValue);
                        }}
                      />
                      <FormHelperText error>
                        {ValidationHelper.getFormikErrorsAsString(errors.tags, {
                          isIncludeNested: false,
                        })}
                      </FormHelperText>
                    </FormControl>

                    {!isCreate && (
                      <TextField
                        fullWidth
                        disabled
                        label='Inspection number'
                        margin='dense'
                        type='text'
                        value={wash?.localNumber}
                        variant='outlined'
                      />
                    )}

                    <FormControl margin='dense' fullWidth error={Boolean(errors.vehicleId)}>
                      <VehicleAutocompleteOrCreate
                        autocompleteProps={{
                          required: true,
                          disabled: !isCreate || !_.isEmpty(vehicleIdComputed),
                          entityId: values.vehicleId,
                          isPreload: isCreate,
                          onChange: (newValue) => {
                            setFieldValue(`vehicleId`, newValue?.id);
                            if (newValue?.id) {
                              if (!values.departmentId) {
                                setFieldValue("departmentId", newValue?.departmentIds?.at(0));
                              }
                              if (!values.locationId) {
                                setFieldValue("locationId", newValue?.locationIds?.at(0));
                              }
                            }
                          },
                        }}
                        createFormPlacement='modal'
                        onCreate={(newValue) => {
                          setFieldValue(`vehicleId`, newValue?.id);
                          if (newValue?.id) {
                            if (!values.departmentId) {
                              setFieldValue("departmentId", newValue?.departmentIds?.at(0));
                            }
                            if (!values.locationId) {
                              setFieldValue("locationId", newValue?.locationIds?.at(0));
                            }
                          }
                        }}
                      />
                      <FormHelperText>{errors.vehicleId}</FormHelperText>
                    </FormControl>

                    <FormControl margin='dense' fullWidth error={Boolean(errors.supplierId)}>
                      <SupplierAutocompleteOrCreate
                        autocompleteProps={{
                          entityId: values.supplierId,
                          isPreload: isCreate,
                          onChange: (newValue) => {
                            setFieldValue(`supplierId`, newValue?.id);
                          },
                        }}
                        createFormPlacement='modal'
                        onCreate={(newValue) => {
                          setFieldValue(`supplierId`, newValue?.id);
                        }}
                      />
                      <FormHelperText>{errors.supplierId}</FormHelperText>
                    </FormControl>

                    {/* Entity affiliation */}
                    <EntityAffiliationInput
                      department={{
                        departmentId: values.departmentId,
                        onChange: (d) => {
                          setFieldValue("departmentId", d?.id);
                          setFieldValue("locationId", undefined);
                        },
                        error: errors.departmentId,
                      }}
                      location={{
                        locationId: values.locationId,
                        onChange: (l) => {
                          setFieldValue("locationId", l?.id);
                        },
                        searchFilters: { departmentId: values.departmentId },
                        createUpdateProps: { defaultValues: { departmentId: values.departmentId } },
                        error: errors.locationId,
                        disabled: !values.departmentId,
                      }}
                    />

                    <FormControl
                      fullWidth
                      margin='dense'
                      error={Boolean(touched.types && errors.types)}
                    >
                      <ApiEnumsAutocomplete
                        type='WashType'
                        values={values.types}
                        onChange={(newValue) => setFieldValue("types", newValue)}
                        label='Types'
                        textFieldProps={{
                          error: Boolean(errors.types),
                        }}
                      />
                      <FormHelperText>{touched.types && errors.types}</FormHelperText>
                    </FormControl>

                    <FormControl
                      fullWidth
                      margin='dense'
                      error={Boolean(touched.scheduleType && errors.scheduleType)}
                    >
                      <InputLabel required>Schedule type</InputLabel>
                      <ApiEnumSelect
                        type='WashScheduleType'
                        value={values.scheduleType}
                        onChange={(newValue) => {
                          setFieldValue("scheduleType", newValue),
                            setFieldValue("scheduledAt", undefined);
                        }}
                        selectProps={{
                          label: "Schedule type",
                          required: true,
                        }}
                      />
                      <FormHelperText>{touched.scheduleType && errors.scheduleType}</FormHelperText>
                    </FormControl>

                    {values.scheduleType === WashScheduleType.Scheduled && (
                      <FormControl
                        margin='dense'
                        fullWidth
                        error={Boolean(touched.scheduledAt && errors.scheduledAt)}
                      >
                        <MobileDateTimePicker
                          ampm={false}
                          label='Scheduled at'
                          value={(values.scheduledAt && moment(values.scheduledAt)) || null}
                          format={DATETIME_FORMATS.DISPLAY_DATETIME}
                          onChange={(newValue: Moment | null) => {
                            setFieldValue("scheduledAt", newValue?.format() || null);
                          }}
                          slots={{ textField: (params) => <TextField {...params} /> }}
                        />
                        <FormHelperText>{touched.scheduledAt && errors.scheduledAt}</FormHelperText>
                      </FormControl>
                    )}

                    <FormControl component='fieldset' variant='standard'>
                      <FormLabel component='legend' required>
                        Responsible user
                      </FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              disabled
                              required
                              checked={values.responsibleUser?.isCurrentUser}
                              onChange={handleChange}
                              name='responsibleUser.isCurrentUser'
                            />
                          }
                          label={profile?.personName?.name}
                        />
                      </FormGroup>
                    </FormControl>

                    {/* Mileage */}
                    <MileageInput
                      error={Boolean(touched.mileage && errors.mileage)}
                      helperText={touched.mileage && errors.mileage}
                      vehicleId={values.vehicleId}
                      vehicle={values.vehicle}
                      disabled={!values.vehicle}
                      fullWidth
                      name='mileage'
                      onBlur={handleBlur}
                      onChange={(newValue) => {
                        setFieldValue("mileage", newValue);
                      }}
                      value={values.mileage}
                    />

                    <FormControl
                      margin='dense'
                      fullWidth
                      error={Boolean(touched.fuelLevel && errors.fuelLevel)}
                    >
                      <Typography component='div' gutterBottom>
                        Fuel level
                      </Typography>

                      <FuelLevelInput
                        onBlur={handleBlur}
                        onChange={(e, val) => setFieldValue("fuelLevel", val ?? undefined)}
                        value={values.fuelLevel ?? undefined}
                      />
                      <FormHelperText>{touched.fuelLevel && errors.fuelLevel}</FormHelperText>
                    </FormControl>

                    <FormControl fullWidth margin='dense'>
                      <PriceSummaryInput
                        values={{
                          currency: values.currency,
                          subTotal: values.price,
                          discount: values.discount,
                          tax: values.tax,
                          total: values.total,
                        }}
                        formikProps={{
                          errors: {
                            subTotal: errors.price,
                            discount: errors.discount,
                            tax: errors.tax,
                            total: errors.total,
                          },
                        }}
                        displayProps={{
                          insurance: false,
                          calcExplanation: true,
                        }}
                        inputsProps={{
                          all: {
                            margin: "none",
                          },
                          subTotal: {
                            label: "Price",
                            required: true,
                          },
                          total: {
                            required: true,
                          },
                        }}
                        onChange={(newValue) => {
                          setFieldValue(`currency`, newValue?.currency);
                          setFieldValue(`price`, newValue?.subTotal);
                          setFieldValue(`discount`, newValue?.discount);
                          setFieldValue(`tax`, newValue?.tax);
                          setFieldValue(`total`, newValue?.total);
                        }}
                      />
                    </FormControl>
                  </Box>

                  {/* Other */}
                  <FoldableBlock
                    defaultIsFolded
                    trigger={{
                      label: (
                        <Typography component='span' variant='h6'>
                          Other
                        </Typography>
                      ),
                    }}
                  >
                    <AppTextArea
                      error={Boolean(touched.notes && errors.notes)}
                      fullWidth
                      helperText={touched.notes && errors.notes}
                      mode='notes'
                      margin='dense'
                      name='notes'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.notes}
                      variant='outlined'
                    />

                    <FormControl fullWidth component='fieldset' margin='dense'>
                      <FileUploader
                        multiple
                        defaultFiles={FileItem.createManyFrom(values.initialAttachments)}
                        onChange={(newFiles) => {
                          setFieldValue(
                            `attachments`,
                            FileItem.toManyGeneralAttachmentInputDto(newFiles),
                          );
                        }}
                        onUploadStarted={() => {
                          setIsAttachmentFilesUploading(true);
                        }}
                        onUploadFinished={() => {
                          setIsAttachmentFilesUploading(false);
                        }}
                      />
                    </FormControl>
                  </FoldableBlock>
                </Stack>

                <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                <FormActions>
                  <SplitDropdownButton
                    isSelectAndTriggerAction={false}
                    buttonProps={{
                      variant: "contained",
                      color: "primary",
                      type: "submit",
                      fullWidth: true,
                      loading: isSubmitting,
                      disabled: isAttachmentFilesUploading,
                    }}
                    options={[
                      {
                        content: "Save",
                        onClick: () => {
                          submitForm();
                        },
                      },
                      {
                        content: "Save & Start",
                        if: isCreate,
                        onClick: () => {
                          const newValue: GeneralStageUpdateDtoOfWashStage = {
                            newStage: WashStage.InProgress,
                          };
                          setFieldValue("newStage", newValue);
                          submitForm();
                        },
                      },
                      {
                        content: "Save & Complete",
                        if: isCreate,
                        onClick: () => {
                          const newValue: GeneralStageUpdateDtoOfWashStage = {
                            newStage: WashStage.Completed,
                          };
                          setFieldValue("newStage", newValue);
                          submitForm();
                        },
                      },
                    ]}
                  />
                </FormActions>
              </Stack>
            </form>
          );
        }}
      </Formik>
    </BaseEntityCreateUpdate>
  );
}
