import { Box, Stack, StackProps, Typography } from "@mui/material";
import React from "react";

interface Props {
  sx?: StackProps["sx"];
  items?: HorizontalTimelineItem[];
}

type HorizontalTimelineItem = {
  active?: boolean;
  content: React.ReactNode;
  sx?: StackProps["sx"];
};

const HorizontalTimeline = ({ sx, items = [] }: Props) => {
  // helper function to calculate visible items with ellipsis
  const calculateVisibleItems = (width: number) => {
    const itemWidth = 120; // approximate width of an item in pixels
    const visibleCount = Math.floor(width / itemWidth);

    if (visibleCount >= items.length) return items;

    const activeIndex = items.findIndex((item) => item.active);

    if (activeIndex === -1) {
      // no active item - fallback to first, second, and last items
      const result: HorizontalTimelineItem[] = [];
      items.forEach((item, index) => {
        if (index === 0 || index === 1 || index === items.length - 1) {
          result.push(item);
        } else if (result[result.length - 1]?.content !== "...") {
          result.push({ content: "..." });
        }
      });
      return result;
    }

    // if active item exists
    const result: HorizontalTimelineItem[] = [];

    items.forEach((item, index) => {
      if (
        index === 0 || // always show the first item
        index === items.length - 1 || // always show the last item
        index === activeIndex || // always show the active item
        index === activeIndex - 1 || // show one neighbor before the active item
        index === activeIndex + 1 // show one neighbor after the active item
      ) {
        result.push(item);
      } else if (result[result.length - 1]?.content !== "...") {
        result.push({ content: "..." });
      }
    });

    return result;
  };

  const [visibleItems, setVisibleItems] = React.useState(items);

  // Resize observer for responsive updates
  React.useEffect(() => {
    const handleResize = () => {
      const container = document.getElementById("horizontal-timeline");
      if (container) {
        setVisibleItems(calculateVisibleItems(container.offsetWidth));
      }
    };

    handleResize(); // Initial calculation
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [items]);

  return (
    <Stack
      id='horizontal-timeline'
      direction='row'
      alignItems='center'
      sx={{
        position: "relative",
        overflow: "hidden",
        ...sx,
      }}
    >
      {visibleItems.map((item, index) => (
        <React.Fragment key={index}>
          {/* Render the step */}
          <Box
            sx={{
              px: 3,
              py: 2,
              backgroundColor: (t) =>
                item.active
                  ? t.palette.background.activeItemBackground
                  : t.palette.background.default,
              color: item.active ? "text.primary" : "text.secondary",
              borderRadius: (t) => t.shapeCustom.borderRadiusCard,
              border: (t) => `1px solid ${item.active ? t.palette.secondary.main : "transparent"}`,
              whiteSpace: "nowrap",
              fontWeight: item.active ? "bold" : "normal",
              textAlign: "center",
              zIndex: 2,
              ...item.sx,
            }}
          >
            <Typography variant='subtitle2'>{item.content}</Typography>
          </Box>

          {/* Render the arrow */}
          {index < visibleItems.length - 1 && (
            <Box
              sx={{
                width: "30px",
                height: "1px",
                backgroundColor: (t) =>
                  item.active ? t.palette.secondary.main : t.palette.divider,
                position: "relative",
                mr: "6px",

                "&::after": {
                  content: '""',
                  position: "absolute",
                  // top: "50%",
                  right: "-6px",
                  transform: "translateY(-44%)",
                  borderLeft: (t) =>
                    `6px solid ${item.active ? t.palette.secondary.main : t.palette.divider}`,
                  borderTop: "3px solid transparent",
                  borderBottom: "3px solid transparent",
                },
              }}
            />
          )}
        </React.Fragment>
      ))}
    </Stack>
  );
};

export default HorizontalTimeline;
