import { apiClient } from "@/core/api/ApiClient";
import { EntityType, GeneralHistoryDto } from "@/core/api/generated";

import BaseEntityDeleteModal, {
  BaseEntityDeleteModalInheritableProps,
} from "../components/BaseEntityDeleteModal";

export interface OwnProps {
  entity: GeneralHistoryDto;
}

type Props = OwnProps & BaseEntityDeleteModalInheritableProps;

export default function GeneralHistoryDeleteModal({ entity, ...otherProps }: Props) {
  return (
    <BaseEntityDeleteModal
      entityType={EntityType.GeneralHistory}
      entity={entity}
      entityId={entity.id}
      title='Delete the history record?'
      body={`You're going to delete the history record. This action can't be undone.`}
      deleteFunc={async (params) => {
        await apiClient.generalHistoryApi.apiV1GeneralHistoryGeneralHistoryIdDelete({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          generalHistoryId: params.entityId,
        });
      }}
      {...otherProps}
    />
  );
}
