import { Alert, AlertTitle, Box, Button, LinearProgress, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  SubscriptionGetPaginatedDto,
} from "@/core/api/generated";

export default function Subscriptions() {
  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<SubscriptionGetPaginatedDto>({
    statePersistence: {
      persistenceKey: EntityType.Subscription,
    },
  });

  const paginatedSubscriptionsRequest = useApiRequest(
    apiClient.subscriptionsApi.apiV1SubscriptionsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      subscriptionGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const paginatedSubscriptions = paginatedSubscriptionsRequest?.data;

  const getCurrentSubscriptionRequest = useApiRequest(
    apiClient.subscriptionsApi.apiV1SubscriptionsCurrentGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
    },
  );
  const currentSubscription = getCurrentSubscriptionRequest.data!;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.Subscription)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedSubscriptionsRequest.handleEntityChanged(data);
    },
  });
  const isLoading =
    paginatedSubscriptionsRequest.isLoading || getCurrentSubscriptionRequest.isLoading;

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <ViewLayoutV2
      header={
        <SimpleViewPageHeader
          title='Subscriptions'
          actions={
            <>
              {currentSubscription && (
                <AuthorizedElement permissions={[AppPermission.TenantSubscriptionManage]}>
                  <Button
                    variant='contained'
                    color='primary'
                    startIcon={<AppIcon of='edit' />}
                    component={RouterLink}
                    to={ROUTE_PATH.MANAGEMENT_SUBSCRIPTION_PLANS}
                  >
                    Change subscription plan
                  </Button>
                </AuthorizedElement>
              )}
            </>
          }
        />
      }
    >
      {!currentSubscription && (
        <Alert severity='info'>
          <AlertTitle>{`You don't have any subscription yet.`}</AlertTitle>
          <Box>{`Please, select a subscription plan`}</Box>
          <Box>
            <Link component={RouterLink} to={ROUTE_PATH.MANAGEMENT_SUBSCRIPTION_PLANS}>
              {`Let's select`}
            </Link>
          </Box>
        </Alert>
      )}

      {currentSubscription && (
        <DataTabular
          columns={[
            {
              field: "plan.name",
              title: "Plan",
              flex: 1,
              renderCell: (item) => (
                <>
                  <span>{item.plan?.name}</span>
                </>
              ),
            },
            {
              field: "plan.price.price",
              title: "Price",
              flex: 1,
              renderCell: (item) => (
                <>
                  {item.plan?.price?.price} per {item.plan?.price?.period}
                </>
              ),
            },
            {
              field: "startsAt",
              title: "Start",
              flex: 1,
              renderCell: (item) => (
                <Datetime datetime={item.startsAt} direction='column' withDurationFromNow />
              ),
            },
            {
              field: "endsAt",
              title: "End",
              flex: 1,
              renderCell: (item) => (
                <Datetime datetime={item.endsAt} direction='column' withDurationFromNow />
              ),
            },
            {
              field: "status",
              title: "Status",
              flex: 1,
              renderCell: (item) => (
                <InlineApiEnumValue type='SubscriptionStatus' value={item.status} />
              ),
            },
          ]}
          rows={paginatedSubscriptions?.items}
          getRowId={(item) => item.id!}
          isLoading={paginatedSubscriptionsRequest.isLoading}
          statePersistence={commonRequestParams.dataTabularProps.statePersistence}
          pagination={commonRequestParams.dataTabularProps.pagination}
          sort={commonRequestParams.dataTabularProps.sort}
          quickFilter={commonRequestParams.dataTabularProps.quickFilter}
          filters={commonRequestParams.dataTabularProps.filters}
          refetch={commonRequestParams.dataTabularProps.refetch}
        />
      )}
    </ViewLayoutV2>
  );
}
