import {
  Alert,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  SxProps,
  Theme,
} from "@mui/material";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import InlineUser from "@/App/MainAppView/components/User/InlineUser";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AuthorizedMenuItem from "@/common/components/Auth/AuthorizedMenuItem";
import AppIconButton from "@/common/components/Button/AppIconButton";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import { ROUTE_PATH } from "@/common/constants/routing";
import { FilterCatalog } from "@/common/filters/filterCatalog";
import { EntityHelper } from "@/common/helpers/entity";
import { getTypedPath } from "@/common/helpers/typedPath";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { PropagatedDeps } from "@/common/hooks/render/usePropagatedDeps";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { FilterFieldType } from "@/common/ts/filters";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  CustomTagDto,
  CustomTagGetPaginatedDto,
  DataUpdatesHubClientMethodName,
  EntityType,
} from "@/core/api/generated";

import CustomTagDeleteModal from "../CustomTagDeleteModal";
import CustomTagsDeleteModal from "../CustomTagsDeleteModal";

enum BulkActionFlags {
  Delete = "Delete",
}

const defaultDisplayProps = {
  hints: true,
  filters: true,
  create: true,
  edit: true,
  delete: true,
  viewVariant: ViewLayoutVariant.Page,
};

const defaultPropagatedDeps = {
  refetch: {},
};
export type CustomTagPaginatedListPropagatedDepsMap = typeof defaultPropagatedDeps;

export interface CustomTagPaginatedListOwnProps {
  displayProps?: Partial<typeof defaultDisplayProps>;
  defaultValues?: {
    limit?: number;
    locationId?: string | null;
    productLocationId?: string | null;
  };
  propagatedDeps?: PropagatedDeps<CustomTagPaginatedListPropagatedDepsMap>;
  sx?: SxProps<Theme>;
}

export type CustomTagPaginatedListProps = CustomTagPaginatedListOwnProps;

export default function CustomTagPaginatedList({
  displayProps,
  defaultValues,
  propagatedDeps,
  sx,
}: CustomTagPaginatedListProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<CustomTagGetPaginatedDto>({
    statePersistence: {
      persistenceKey: EntityType.CustomTag,
    },
    defaultValues: {
      limit: defaultValues?.limit,
      params: {
        ...defaultValues,
      },
    },
  });

  const [customTag, setCustomTag] = useState<CustomTagDto | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const paginatedCustomTagsRequest = useApiRequest(
    apiClient.customTagsApi.apiV1TagsCustomGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      customTagGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps, propagatedDeps?.depsMap["refetch"]],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );

  const paginatedCustomTags = paginatedCustomTagsRequest.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.CustomTag)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedCustomTagsRequest.handleEntityChanged(data);
    },
  });

  return (
    <ViewLayoutV2
      displayProps={displayProps}
      header={
        <>
          {displayProps?.hints && (
            <Alert severity='info' sx={{ mb: 2 }}>
              Custom tags are used to give additional context or to group similar entities for easy
              viewing and search.
            </Alert>
          )}
          <SimpleViewPageHeader
            title='Custom tags'
            primaryActions={
              displayProps?.create && (
                <AuthorizedElement permissions={[AppPermission.CustomTagManage]}>
                  <Button
                    variant='contained'
                    color='primary'
                    startIcon={<AppIcon of='add' />}
                    component={RouterLink}
                    to={ROUTE_PATH.CUSTOM_TAG_CREATE()}
                  >
                    Create new custom tag
                  </Button>
                </AuthorizedElement>
              )
            }
          />
        </>
      }
    >
      <DataTabular
        columns={[
          {
            field: getTypedPath<CustomTagDto>().id.$path,
            title: "ID",
            width: 250,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.id,
            filters: {
              fieldType: FilterFieldType.Id,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<CustomTagDto>().name.$path,
            title: "Name",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.name || "-",
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<CustomTagDto>().description.$path,
            title: "Description",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) => item.description || "-",
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<CustomTagDto>().createdAt.$path,
            title: "Created at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.createdAt ? <Datetime datetime={item.createdAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<CustomTagDto>().updatedAt.$path,
            title: "Updated at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.updatedAt ? <Datetime datetime={item.updatedAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<CustomTagDto>().createdBy.$path,
            title: "Created by",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.createdBy ? <InlineUser userId={item.createdBy} withAvatar /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
          {
            field: getTypedPath<CustomTagDto>().updatedBy.$path,
            title: "Updated by",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.createdBy ? <InlineUser userId={item.createdBy} withAvatar /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
        ]}
        isLoading={paginatedCustomTagsRequest.isLoading}
        rows={paginatedCustomTags?.items}
        getRowId={(item) => item.id!}
        rowTo={(item) => ROUTE_PATH.CUSTOM_TAG_VIEW(item.id)}
        renderRowAction={(actionParams) => (
          <MenuWithTrigger
            withAuthCloseOnClick
            trigger={
              <IconButton>
                <AppIcon of='moreVert' />
              </IconButton>
            }
          >
            {displayProps?.edit && (
              <AuthorizedMenuItem
                permissions={[AppPermission.CustomTagManage]}
                component={RouterLink}
                to={ROUTE_PATH.CUSTOM_TAG_EDIT(actionParams.item.id)}
              >
                <ListItemIcon>
                  <AppIcon of='edit' fontSize='small' />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </AuthorizedMenuItem>
            )}

            {displayProps?.delete && (
              <AuthorizedMenuItem
                permissions={[AppPermission.CustomTagManage]}
                onClick={() => {
                  setCustomTag(actionParams.item);
                  setIsDeleteModalOpen(true);
                }}
              >
                <ListItemIcon>
                  <AppIcon of='delete' fontSize='small' />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
              </AuthorizedMenuItem>
            )}
          </MenuWithTrigger>
        )}
        statePersistence={commonRequestParams.dataTabularProps.statePersistence}
        pagination={commonRequestParams.dataTabularProps.pagination}
        sort={commonRequestParams.dataTabularProps.sort}
        quickFilter={commonRequestParams.dataTabularProps.quickFilter}
        filters={commonRequestParams.dataTabularProps.filters}
        refetch={commonRequestParams.dataTabularProps.refetch}
        bulkActions={{
          enabled: true,
          definition: BulkActionFlags,
          actionTriggers: ({ currentAction, startAction, selectedIds }) => (
            <>
              <AppIconButton
                tooltipProps={{ title: "Delete" }}
                onClick={() => startAction(BulkActionFlags.Delete)}
              >
                <AppIcon of='delete' />
              </AppIconButton>
            </>
          ),
          actionHandlers: ({ selectedIds, currentAction, cancelAction, completeAction }) => (
            <>
              <CustomTagsDeleteModal
                entities={EntityHelper.filterEntitiesByIds(
                  paginatedCustomTags?.items || [],
                  selectedIds as string[],
                )}
                open={currentAction === BulkActionFlags.Delete}
                onClose={() => cancelAction()}
                onDelete={() => {
                  completeAction();
                  paginatedCustomTagsRequest.refetch();
                }}
              />
            </>
          ),
        }}
      />

      {/* Delete confirmation */}
      {customTag && (
        <CustomTagDeleteModal
          entity={customTag}
          open={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onDelete={() => paginatedCustomTagsRequest.refetch()}
        />
      )}
    </ViewLayoutV2>
  );
}
