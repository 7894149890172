import { Box, Stack } from "@mui/material";
import { ReactNode } from "react";

import { pxToRemCustom } from "@/theme";

import TextLineSkeleton from "../../../Skeleton/TextLineSkeleton";
import AppTypography from "../../../Text/AppTypography";

export interface CounterWidgetContentProps {
  title: ReactNode;
  counter: ReactNode;
  icon?: ReactNode;
  isLoading?: boolean;
  to?: string;
  counterTo?: string;
}

export default function CounterWidgetContent({
  title,
  counter,
  icon,
  isLoading,
  to,
}: CounterWidgetContentProps) {
  return (
    <Box>
      <Stack spacing={1}>
        <Stack
          direction='row'
          spacing={1}
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <AppTypography variant='body1' sx={{ display: "flex", fontSize: pxToRemCustom(24) }}>
            {icon}
          </AppTypography>

          <AppTypography variant='subtitle1'>{title}</AppTypography>
        </Stack>

        <Stack direction='row' spacing={2} sx={{ justifyContent: "center", alignItems: "center" }}>
          <AppTypography variant='body1' sx={{ fontSize: pxToRemCustom(36) }}>
            {isLoading && <TextLineSkeleton sx={{ width: 60 }} />}
            {!isLoading && counter}
          </AppTypography>
        </Stack>
      </Stack>
    </Box>
  );
}
