import { apiClient } from "@/core/api/ApiClient";
import { DataGrantDto, EntityType } from "@/core/api/generated";

import BaseEntityDeleteModal, {
  BaseEntityDeleteModalInheritableProps,
} from "../components/BaseEntityDeleteModal";

export interface OwnProps {
  dataGrant: DataGrantDto;
}

type Props = OwnProps & BaseEntityDeleteModalInheritableProps;

export default function DataGrantDeleteModal({ dataGrant, ...otherProps }: Props) {
  return (
    <BaseEntityDeleteModal
      entityType={EntityType.DataGrant}
      entity={dataGrant}
      entityId={dataGrant.id}
      deleteFunc={async (params) => {
        await apiClient.dataGrantsApi.apiV1DataGrantsDataGrantIdDelete({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          dataGrantId: params.entityId,
        });
      }}
      {...otherProps}
    />
  );
}
