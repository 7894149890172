import { Card, CardContent, Stack, Typography } from "@mui/material";

import InlineCode from "@/common/components/Code/InlineCode";

export default function DevCards() {
  return (
    <Stack spacing={2}>
      <Typography component='div' variant='h2'>
        Cards
      </Typography>

      <Stack spacing={2}>
        <Typography component='div' variant='h5'>
          <InlineCode>MUI Cards</InlineCode>
        </Typography>

        <Stack direction='column' spacing={2}>
          <Typography component='div' variant='h6'>
            Variant
          </Typography>
          <Stack direction='row' spacing={2} alignItems='center'>
            <Stack spacing={2} sx={{ p: 4, backgroundColor: "white" }}>
              <Card>
                <CardContent>
                  Default card. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque
                  voluptate repellendus similique soluta laboriosam sequi harum doloremque commodi
                  facilis cupiditate fugit perferendis doloribus quisquam nulla rem, in ut est hic.
                </CardContent>
              </Card>

              <Card variant='elevation'>
                <CardContent>
                  Elevation card. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque
                  voluptate repellendus similique soluta laboriosam sequi harum doloremque commodi
                  facilis cupiditate fugit perferendis doloribus quisquam nulla rem, in ut est hic.
                </CardContent>
              </Card>

              <Card variant='outlined'>
                <CardContent>
                  Outlined card. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque
                  voluptate repellendus similique soluta laboriosam sequi harum doloremque commodi
                  facilis cupiditate fugit perferendis doloribus quisquam nulla rem, in ut est hic.
                </CardContent>
              </Card>

              <Card variant='contained'>
                <CardContent>
                  Contained card. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque
                  voluptate repellendus similique soluta laboriosam sequi harum doloremque commodi
                  facilis cupiditate fugit perferendis doloribus quisquam nulla rem, in ut est hic.
                </CardContent>
              </Card>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
