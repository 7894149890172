import { Alert } from "@mui/material";

import { apiClient } from "@/core/api/ApiClient";
import { EntityType, PoolItemDto } from "@/core/api/generated";

import GeneralStrictEntityRelationLink from "../General/Display/GeneralStrictEntityRelationLink";
import BaseEntityDeleteModal, {
  BaseEntityDeleteModalInheritableProps,
} from "../components/BaseEntityDeleteModal";

export interface OwnProps {
  entity: PoolItemDto;
}

type Props = OwnProps & BaseEntityDeleteModalInheritableProps;

export default function PoolItemDeleteModal({ entity, ...otherProps }: Props) {
  return (
    <BaseEntityDeleteModal
      entityType={EntityType.PoolItem}
      entity={entity}
      entityId={entity.id}
      deleteFunc={async (params) => {
        await apiClient.poolItemsApi.apiV1PoolsPoolIdItemsPoolItemIdDelete({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          poolId: entity.poolId || "",
          poolItemId: params.entityId,
        });
      }}
      bodyAfter={
        entity.usedByEntity && (
          <Alert severity='warning'>
            This pool item is used by{" "}
            <GeneralStrictEntityRelationLink value={entity.usedByEntity} />.{" "}
            {`Deletion may be forbidden until it's released.`}
          </Alert>
        )
      }
      {...otherProps}
    />
  );
}
