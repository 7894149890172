import MaintenancePaginatedList from "@/common/components/Entity/Maintenance/ListView/MaintenancePaginatedList";
import { useLocationState } from "@/common/hooks/useLocationParams";
import { FilterDefinitionDto } from "@/core/api/generated";

export interface MaintenanceListPageLocationState {
  filterDefinitionDto?: Nil<FilterDefinitionDto>;
}

export default function MaintenancesPage() {
  const locationState = useLocationState<MaintenanceListPageLocationState>();

  return (
    <>
      <MaintenancePaginatedList
        initialValues={{ filterDefinitionDto: locationState?.filterDefinitionDto }}
      />
    </>
  );
}
