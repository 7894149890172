import { Button, Stack, SxProps, Theme } from "@mui/material";
import { Box } from "@mui/system";
import _ from "lodash";
import { useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import InlineUser from "@/App/MainAppView/components/User/InlineUser";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppIconButton from "@/common/components/Button/AppIconButton";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import EntityTagsInfoDisplay from "@/common/components/EntityInfo/EntityTagsInfoDisplay";
import CurrencyValue from "@/common/components/Form/Display/CurrencyValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppPopover from "@/common/components/Popover/AppPopover";
import AppPopoverContent from "@/common/components/Popover/AppPopoverContent";
import GeneralPriceSummaryDisplay from "@/common/components/PriceSummary/GeneralPriceSummaryDisplay";
import TableCellContentOfAttachments from "@/common/components/Table/TableCell/TableCellContentOfAttachments";
import AppTypography from "@/common/components/Text/AppTypography";
import { ROUTE_PATH } from "@/common/constants/routing";
import { FilterCatalog } from "@/common/filters/filterCatalog";
import { EntityHelper } from "@/common/helpers/entity";
import { TagStaticFilterInputDto, TagsHelper } from "@/common/helpers/entity/tags";
import { getTypedPath } from "@/common/helpers/typedPath";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { PropagatedDeps } from "@/common/hooks/render/usePropagatedDeps";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { ApiEnumName } from "@/common/services/enum";
import { FilterFieldType } from "@/common/ts/filters";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  FilterDefinitionDto,
  GeneralAttachedTagsInputDto,
  MaintenanceDto,
  MaintenanceGetPaginatedDto,
  TagEntityType,
} from "@/core/api/generated";

import AffiliationInfoDisplay from "../../EntityAffiliation/AffiliationInfoDisplay";
import GeneralByWhoDisplay from "../../General/Display/GeneralByWhoDisplay";
import GeneralCurrencyDisplay from "../../General/Display/GeneralCurrencyDisplay";
import GeneralDiscountDisplay from "../../General/GeneralDiscount/GeneralDiscountDisplay";
import GeneralAttachedTagsOfEntitiesEditModal from "../../General/GeneralTag/GeneralAttachedTagsOfEntitiesEditModal";
import GeneralTaxDisplay from "../../General/GeneralTax/GeneralTaxDisplay";
import TenantStructureMembershipOfEntityEditModal from "../../Membership/TenantStructureMembershipOfEntityEditModal";
import SupplierInline from "../../Supplier/SupplierInline";
import VehicleInline from "../../Vehicle/VehicleInline";
import VehicleMileageInline from "../../Vehicle/VehicleMileageInline";
import MaintenanceMenu from "../MaintenanceMenu";
import MaintenancesDeleteModal from "../MaintenancesDeleteModal";

enum BulkActionFlags {
  UpdateTenantStructureMembership = "UpdateTenantStructureMembership",
  UpdateTags = "UpdateTags",
  Delete = "Delete",
}
const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  filters: true,
  create: true,
  edit: true,
  delete: true,
  viewVariant: ViewLayoutVariant.Page,
};

const defaultPropagatedDeps = {
  refetch: {},
};
export type MaintenancePaginatedListPropagatedDepsMap = typeof defaultPropagatedDeps;

type MaintenanceGetPaginatedInputDto = Omit<MaintenanceGetPaginatedDto, "tags"> & {
  tags?: TagStaticFilterInputDto;
};
export interface MaintenancePaginatedListOwnProps {
  displayProps?: Partial<typeof defaultDisplayProps>;
  initialValues?: {
    filterDefinitionDto?: Nil<FilterDefinitionDto>;
  };
  defaultValues?: {
    limit?: number;
    vehicleId?: string | null;
  };
  propagatedDeps?: PropagatedDeps<MaintenancePaginatedListPropagatedDepsMap>;
  sx?: SxProps<Theme>;
}

export type MaintenancePaginatedListProps = MaintenancePaginatedListOwnProps;

export default function MaintenancePaginatedList({
  displayProps,
  initialValues,
  defaultValues,
  propagatedDeps,
  sx,
}: MaintenancePaginatedListProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const { enqueueSnackbar } = useAppSnackbar();
  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<MaintenanceGetPaginatedInputDto>({
    statePersistence: {
      persistenceKey: EntityType.Maintenance,
    },
    initialValues: {
      filterDefinitionDto: initialValues?.filterDefinitionDto || undefined,
    },
    defaultValues: {
      limit: defaultValues?.limit,
      params: {
        ...defaultValues,
      },
    },
    initParams: (filterDefinition) => {
      const tagFilter = filterDefinition.filterDefinition?.items.find(
        (x) => x.field === getTypedPath<MaintenanceDto>().tags.$path,
      );

      return {
        tags: {
          operator: tagFilter?.operator,
          query: tagFilter?.value as GeneralAttachedTagsInputDto,
        } as TagStaticFilterInputDto,
      };
    },
  });

  const [isDataExportModalOpened, setIsDataExportModalOpened] = useState(false);

  const paginatedMaintenancesRequest = useApiRequest(
    apiClient.maintenancesApi.apiV1MaintenancesGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      maintenanceGetPaginatedDto: {
        ..._.omit(commonRequestParams.params, "tags"),
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        tags:
          commonRequestParams.filterDefinition?.items
            .filter((x) => x.field === getTypedPath<MaintenanceDto>().tags.$path)
            .map((x) => TagsHelper.mapFromFilter(x))[0] || undefined,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [
        ...commonRequestParams.deps,
        commonRequestParams.filterDefinition,
        propagatedDeps?.depsMap["refetch"],
      ],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );

  const paginatedMaintenances = useMemo(
    () => paginatedMaintenancesRequest?.data,
    [paginatedMaintenancesRequest?.data],
  );

  const maintenanceIds = useMemo(
    () => paginatedMaintenances?.items?.map((item) => item.id!) || [],
    [paginatedMaintenances],
  );

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.Maintenance)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedMaintenancesRequest.handleEntityChanged(data);
    },
  });

  // menu, dialogs
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);

  const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  return (
    <Box>
      <ViewLayout
        displayProps={displayProps}
        header={
          displayProps?.header && (
            <SimpleViewPageHeader
              title='Maintenances'
              primaryActions={
                displayProps?.create && (
                  <AuthorizedElement
                    permissionsAny={[
                      AppPermission.MaintenancePerform,
                      AppPermission.MaintenanceManage,
                    ]}
                  >
                    <Button
                      variant='contained'
                      color='primary'
                      startIcon={<AppIcon of='add' />}
                      component={RouterLink}
                      to={ROUTE_PATH.MAINTENANCE_CREATE({
                        vehicleId: commonRequestParams.params?.vehicleId,
                      })}
                    >
                      Create new maintenance
                    </Button>
                  </AuthorizedElement>
                )
              }
            />
          )
        }
      >
        <Stack direction='column' spacing={1}>
          <DataTabular
            columns={[
              {
                field: getTypedPath<MaintenanceDto>().id.$path,
                title: "ID",
                width: 250,
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => item.id,
                filters: {
                  fieldType: FilterFieldType.Id,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<MaintenanceDto>().localNumber.$path,
                title: "Number",
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => (
                  <Stack direction='row' spacing={0.5}>
                    <span>{item.localNumber}</span>{" "}
                    <EntityChipList entity={item} variant='compact' />
                  </Stack>
                ),
                filters: {
                  fieldType: FilterFieldType.String,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<MaintenanceDto>().name.$path,
                title: "Name",
                width: 200,
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => item.name || "-",
                filters: {
                  fieldType: FilterFieldType.String,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<MaintenanceDto>().stageHistory.stage.$path,
                title: "Stage",
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => item.stageHistory?.stage,
                filters: {
                  fieldType: FilterFieldType.Enum,
                  isUseDefaultOperators: true,
                  fieldTypeMeta: {
                    enum: {
                      enumName: ApiEnumName.MaintenanceStage,
                    },
                  },
                },
              },
              {
                field: getTypedPath<MaintenanceDto>().vehicle.id.$path,
                title: "Vehicle",
                width: 300,
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) => <VehicleInline entity={item.vehicle} />,
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfVehicle(),
                },
              },
              {
                field: getTypedPath<MaintenanceDto>().supplier.id.$path,
                title: "Supplier",
                width: 300,
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) =>
                  item.supplier ? <SupplierInline entity={item.supplier} /> : "-",
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfSupplier(),
                },
              },
              {
                field: getTypedPath<MaintenanceDto>().responsibleUser.$path,
                title: "Responsible user",
                width: 200,
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) =>
                  item.responsibleUser ? <GeneralByWhoDisplay who={item.responsibleUser} /> : "-",
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
                },
              },
              {
                field: getTypedPath<MaintenanceDto>().mileage.$path,
                title: "Mileage",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => <VehicleMileageInline value={item.mileage} />,
                filters: {
                  fieldType: FilterFieldType.Number,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<MaintenanceDto>().fuelLevel.$path,
                title: "Fuel level",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => item.fuelLevel ?? "-",
                filters: {
                  fieldType: FilterFieldType.Number,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<MaintenanceDto>().notes.$path,
                title: "Notes",
                width: 100,
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) => item.notes || "-",
                filters: {
                  fieldType: FilterFieldType.String,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<MaintenanceDto>().currency.code.$path,
                title: "Currency",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.currency ? <GeneralCurrencyDisplay currency={item.currency} /> : "-",
                filters: {
                  fieldType: FilterFieldType.Enum,
                  isUseDefaultOperators: true,
                  fieldTypeMeta: {
                    enum: {
                      enumName: ApiEnumName.CurrencyCode,
                    },
                  },
                },
              },
              {
                field: getTypedPath<MaintenanceDto>().price.$path,
                title: "Price",
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => <CurrencyValue value={item.price} currency={item.currency} />,
                filters: {
                  fieldType: FilterFieldType.Number,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<MaintenanceDto>().subTotal.$path,
                title: "Sub total",
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => (
                  <CurrencyValue value={item.subTotal} currency={item.currency} />
                ),
                filters: {
                  fieldType: FilterFieldType.Number,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<MaintenanceDto>().discount.$path,
                title: "Discount",
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                renderCell: (item) => (
                  <GeneralDiscountDisplay
                    discount={item.discount}
                    currency={item.currency}
                    detailsPlacement='tooltip'
                  />
                ),
              },
              {
                field: getTypedPath<MaintenanceDto>().subTotalIncDiscount.$path,
                title: "Sub total (inc. discount)",
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => (
                  <CurrencyValue value={item.subTotalIncDiscount} currency={item.currency} />
                ),
                filters: {
                  fieldType: FilterFieldType.Number,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<MaintenanceDto>().tax.$path,
                title: "Tax",
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                renderCell: (item) => <GeneralTaxDisplay tax={item.tax} currency={item.currency} />,
              },
              {
                field: getTypedPath<MaintenanceDto>().total.$path,
                title: "Total",
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => (
                  <AppPopover
                    hoverBehavior={{}}
                    trigger={
                      <AppTypography decoration={{ variant: "helpText" }}>
                        <CurrencyValue value={item.total} currency={item.currency} />
                      </AppTypography>
                    }
                  >
                    <AppPopoverContent>
                      <GeneralPriceSummaryDisplay
                        direction='column'
                        summary={{
                          currency: item.currency,
                          subTotal: item.subTotal,
                          discount: item.discount || undefined,
                          tax: item.tax || undefined,
                          total: item.total || 0,
                        }}
                      />
                    </AppPopoverContent>
                  </AppPopover>
                ),
                filters: {
                  fieldType: FilterFieldType.Number,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<MaintenanceDto>().createdAt.$path,
                title: "Created at",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.createdAt ? <Datetime datetime={item.createdAt} withDurationFromNow /> : "-",
                filters: {
                  fieldType: FilterFieldType.Date,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<MaintenanceDto>().updatedAt.$path,
                title: "Updated at",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.updatedAt ? <Datetime datetime={item.updatedAt} withDurationFromNow /> : "-",
                filters: {
                  fieldType: FilterFieldType.Date,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<MaintenanceDto>().createdBy.$path,
                title: "Created by",
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) =>
                  item.createdBy ? <InlineUser userId={item.createdBy} withAvatar /> : "-",
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
                },
              },
              {
                field: getTypedPath<MaintenanceDto>().updatedBy.$path,
                title: "Updated by",
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) =>
                  item.updatedBy ? <InlineUser userId={item.updatedBy} withAvatar /> : "-",
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
                },
              },
              {
                field: getTypedPath<MaintenanceDto>().attachments.$path,
                title: "Attachments",
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                isToDisabled: true,
                renderCell: (item) => (
                  <TableCellContentOfAttachments attachments={item.attachments} />
                ),
              },
              {
                field: "affiliation",
                title: "Affiliation",
                description: "Affiliation to departments & locations",
                width: 120,
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                isToDisabled: true,
                renderCell: (item) => (
                  <AffiliationInfoDisplay
                    variant='compact'
                    tenantId={item.tenantId}
                    departmentIds={item.departmentIds}
                    locationIds={item.locationIds}
                  />
                ),
              },
              {
                field: getTypedPath<MaintenanceDto>().tags.$path,
                title: "Tags",
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                isColumnMenuDisabled: false,
                isToDisabled: true,
                renderCell: (item) => (
                  <EntityTagsInfoDisplay
                    entityType={TagEntityType.Maintenance}
                    entity={item}
                    noDataPlaceholder='-'
                    edit={{
                      onSaved: (newValue) =>
                        paginatedMaintenancesRequest.updateData((data) => {
                          data.items?.forEach((item2) => {
                            if (item2.id === item.id) {
                              item2.tags = newValue || undefined;
                            }
                          });
                        }),
                    }}
                  />
                ),
                filters: {
                  fieldType: FilterFieldType.Arbitrary,
                  isUseSingleFilter: true,
                  operators: FilterCatalog.getOperatorsForTagsField(),
                },
              },
            ]}
            rows={paginatedMaintenances?.items}
            isLoading={paginatedMaintenancesRequest.isLoading}
            getRowId={(item) => item.id!}
            rowTo={(item) => ROUTE_PATH.MAINTENANCE_VIEW(item.id)}
            renderRowAction={({ item }) => (
              <MaintenanceMenu
                entity={item}
                onDelete={() => paginatedMaintenancesRequest.refetch()}
                onUpdate={() => paginatedMaintenancesRequest.refetch()}
                displayProps={{
                  actions: {
                    edit: displayProps?.edit || false,
                    delete: displayProps?.delete || false,
                  },
                }}
              />
            )}
            statePersistence={commonRequestParams.dataTabularProps.statePersistence}
            pagination={commonRequestParams.dataTabularProps.pagination}
            sort={commonRequestParams.dataTabularProps.sort}
            quickFilter={commonRequestParams.dataTabularProps.quickFilter}
            filters={commonRequestParams.dataTabularProps.filters}
            refetch={commonRequestParams.dataTabularProps.refetch}
            bulkActions={{
              enabled: true,
              definition: BulkActionFlags,
              actionTriggers: ({ currentAction, startAction, selectedIds }) => (
                <>
                  <AppIconButton
                    tooltipProps={{ title: "Update affiliation" }}
                    onClick={() => startAction(BulkActionFlags.UpdateTenantStructureMembership)}
                  >
                    <AppIcon of='department' />
                  </AppIconButton>
                  <AppIconButton
                    tooltipProps={{ title: "Update tags" }}
                    onClick={() => startAction(BulkActionFlags.UpdateTags)}
                  >
                    <AppIcon of='tag' />
                  </AppIconButton>
                  <AppIconButton
                    tooltipProps={{ title: "Delete" }}
                    onClick={() => startAction(BulkActionFlags.Delete)}
                  >
                    <AppIcon of='delete' />
                  </AppIconButton>
                </>
              ),
              actionHandlers: ({ selectedIds, currentAction, cancelAction, completeAction }) => {
                const selectedEntities = EntityHelper.filterEntitiesByIds(
                  paginatedMaintenances?.items || [],
                  selectedIds as string[],
                );
                return (
                  <>
                    <TenantStructureMembershipOfEntityEditModal
                      open={currentAction === BulkActionFlags.UpdateTenantStructureMembership}
                      onClose={() => cancelAction()}
                      tenantStructureMembershipOfEntityEditProps={{
                        entityType: EntityType.Maintenance,
                        entities: selectedEntities,
                        onSave: () => {
                          completeAction();
                          paginatedMaintenancesRequest.refetch();
                        },
                      }}
                    />
                    <GeneralAttachedTagsOfEntitiesEditModal
                      tagEntityType={TagEntityType.Maintenance}
                      entities={selectedEntities}
                      open={currentAction === BulkActionFlags.UpdateTags}
                      onClose={() => cancelAction()}
                      onSaved={() => {
                        completeAction();
                        paginatedMaintenancesRequest.refetch();
                      }}
                    />
                    <MaintenancesDeleteModal
                      entities={EntityHelper.filterEntitiesByIds(
                        paginatedMaintenances?.items || [],
                        selectedIds as string[],
                      )}
                      open={currentAction === BulkActionFlags.Delete}
                      onClose={() => cancelAction()}
                      onDelete={() => {
                        completeAction();
                        paginatedMaintenancesRequest.refetch();
                      }}
                    />
                  </>
                );
              },
            }}
          />
        </Stack>
      </ViewLayout>
    </Box>
  );
}
