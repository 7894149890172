import { Box } from "@mui/material";
import { ReactNode } from "react";

interface Props {
  children: Iterable<ReactNode>;
}

export default function CounterWidgetCardGrid({ children }: Props) {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
        gap: 1,
      }}
    >
      {children}
    </Box>
  );
}
