import { CircularProgress, Stack, Typography } from "@mui/material";
import { useMemo } from "react";

import DataTabular from "@/common/components/DataTabular/DataTabular";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { TabularColumnModel } from "@/common/ts/dataTabular";
import { apiClient } from "@/core/api/ApiClient";
import { SpreadsheetRowDtoOfString } from "@/core/api/generated";

interface Props {
  importId: string;
}

export default function SpreadsheetDataView({ importId }: Props) {
  const dataRequest = useApiRequest(
    apiClient.dataImportApi.apiV1DataImportDataImportIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      importId: importId,
    },
    {
      deps: [importId],
    },
  );

  const columns = useMemo(() => {
    return (
      dataRequest.data
        ? dataRequest.data.columns?.map((column, index) => ({
            field: "column" + index,
            title: column,
            flex: 1,
            xxs: true,
            renderCell: (row) => (
              <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
                <Typography>{row?.items?.at(index) || "-"}</Typography>
              </Stack>
            ),
          }))
        : []
    ) as TabularColumnModel<SpreadsheetRowDtoOfString>[];
  }, [dataRequest.data]);

  return (
    <Stack
      direction='column'
      sx={{ py: 1, minWidth: 400, minHeight: 400, justifyContent: "center" }}
      spacing={1}
    >
      {dataRequest.isLoading && (
        <Stack
          direction='column'
          spacing={2}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <CircularProgress />
          <Typography>Spreadsheet is loading ...</Typography>
        </Stack>
      )}
      {dataRequest.data && (
        <DataTabular
          variant='datagrid'
          columns={columns}
          rows={dataRequest.data!.rows!}
          getRowId={(item) => dataRequest.data?.rows?.indexOf(item).toString() || ""}
        />
      )}
    </Stack>
  );
}
