import { TypedEventEmitter } from "./typedEventEmitter";

export type WidgetEventsMap = {
  // list of supported events

  /** Request data refetch for all widgets.*/
  refetchAllDataRequested: undefined;
  /** Request data recompute for all widgets.*/
  recomputeAllDataRequested: undefined;
};

/** Common tenant-to-tenant events. */
class WidgetEventEmitter extends TypedEventEmitter<WidgetEventsMap> {}

export const widgetEventEmitter = new WidgetEventEmitter();
