import { apiClient } from "@/core/api/ApiClient";
import { EntityType, SpotDto } from "@/core/api/generated";

import BaseEntityDeleteModal, {
  BaseEntityDeleteModalInheritableProps,
} from "../components/BaseEntityDeleteModal";

export interface OwnProps {
  entity: SpotDto;
}

type Props = OwnProps & BaseEntityDeleteModalInheritableProps;

export default function SpotDeleteModal({ entity, ...otherProps }: Props) {
  return (
    <BaseEntityDeleteModal
      cascadeActionDisplay={true}
      entityType={EntityType.Spot}
      entity={entity}
      entityId={entity.id}
      deleteFunc={async (params) => {
        await apiClient.spotsApi.apiV1SpotsSpotIdDelete({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          spotId: params.entityId,
          isDeleteCascade: params.isDeleteCascade,
        });
      }}
      {...otherProps}
    />
  );
}
