import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
//import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Box, Stack, StackProps } from "@mui/material";

import AppTypography from "../Text/AppTypography";

interface Props {
  sx?: StackProps["sx"];
  items?: VerticalTimelineItem[];
}

type VerticalTimelineItem = {
  active?: boolean;
  title: string;
  content: React.ReactNode;
  sx?: StackProps["sx"];
};

const VerticalTimeline = ({ sx, items }: Props) => {
  return (
    <Stack sx={{ ...sx }}>
      {items?.map((item, index) => (
        <Stack
          key={index}
          sx={{
            flexDirection: "row",
            minHeight: 40,
            color: (t) => (item.active ? "inherit" : t.palette.text.disabled),
            ...item.sx,
          }}
        >
          <Stack>
            <RadioButtonCheckedIcon
              viewBox='2 2 20 20'
              sx={{
                width: 20,
                height: 20,
                color: (t) => (item.active ? t.palette.secondary.main : "inherit"),
              }}
            />

            {index !== items.length - 1 && (
              <Box
                sx={{
                  height: "100%",
                  background: (t) =>
                    item.active
                      ? `linear-gradient(to bottom, ${t.palette.secondary.main}, ${
                          items[index + 1].active ? t.palette.secondary.main : t.palette.divider
                        })`
                      : `linear-gradient(to bottom, currentColor, currentColor)`,
                  width: "2px",
                  m: "auto",
                }}
              />
            )}
          </Stack>

          <Stack sx={{ flex: 1, pt: "1px", pl: 1 }}>
            <AppTypography variant='subtitle2'>{item.title}</AppTypography>

            <Stack
              sx={{
                mt: 1,
                mb: 2,
              }}
            >
              {item.content}
            </Stack>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export default VerticalTimeline;
