import { useEffect } from "react";
import { useHistory } from "react-router";

import { WidgetHelper } from "@/common/helpers/widget";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";

import AppIcon from "../../Icons/AppIcon";
import BaseWidget from "../Base/BaseWidget";
import WidgetContent from "../Base/WidgetContent";
import WidgetHeader from "../Base/WidgetHeader";
import WidgetTitle from "../Base/WidgetTitle";
import WidgetTitleDefaultActions from "../Base/WidgetTitleDefaultActions";
import CounterWidgetCard from "../General/Counter/CounterWidgetCard";
import CounterWidgetCardGrid from "../General/Counter/CounterWidgetCardGrid";
import CounterWidgetContent from "../General/Counter/CounterWidgetContent";

export default function VehicleInFlowSummaryWidget() {
  const history = useHistory();

  const dataRequest = useApiRequest(
    apiClient.widgetDataApi.apiV1WidgetDataVehicleInFlowSummaryGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
    },
    {
      deps: [],
    },
  );
  const data = dataRequest?.data;

  useEffect(() => {
    return WidgetHelper.handleRefetchAndRecomputeDataRequestInEffect((requestParams) => {
      dataRequest.refetchWithNewParams({
        widgetDataGetBaseDto: {
          ...requestParams,
        },
      });
    });
  }, [dataRequest]);

  return (
    <BaseWidget>
      <WidgetHeader>
        <WidgetTitle
          icon={<AppIcon of='dataConnected' />}
          actions={
            <WidgetTitleDefaultActions
              data={data}
              onRecompute={async (requestParams) => {
                await dataRequest.refetchWithNewParams({
                  widgetDataGetBaseDto: {
                    ...requestParams,
                  },
                });
              }}
            />
          }
        >
          Vehicles in flow
        </WidgetTitle>
      </WidgetHeader>

      <WidgetContent>
        <CounterWidgetCardGrid>
          <CounterWidgetCard>
            <CounterWidgetContent
              title='Awaiting allocation'
              icon={<AppIcon of='pending' />}
              counter={data?.awaitingAllocation?.count}
              // counter={
              //   <AppLink
              //     to={ROUTE_PATH.ASSET_SUBSCRIPTIONS}
              //     onClick={(e) => {
              //       e.preventDefault();
              //       history.push(
              //         ROUTE_PATH.ASSET_SUBSCRIPTIONS,
              //         cast<AssetSubscriptionListPageLocationState>({
              //           filterDefinitionDto: data?.awaitingAllocation?.filterDefinition,
              //         }),
              //       );
              //     }}
              //   >
              //     {data?.awaitingAllocation?.count}
              //   </AppLink>
              // }
              isLoading={dataRequest.isLoading}
            />
          </CounterWidgetCard>

          <CounterWidgetCard>
            <CounterWidgetContent
              title='Ready to check-out'
              icon={<AppIcon of='checkOut' />}
              counter={data?.canCheckOut?.count}
              // counter={
              //   <AppLink
              //     to={ROUTE_PATH.CONTRACTS}
              //     onClick={(e) => {
              //       e.preventDefault();
              //       history.push(
              //         ROUTE_PATH.CONTRACTS,
              //         cast<ContractListPageLocationState>({
              //           filterDefinitionDto: data?.canCheckOut?.filterDefinition,
              //         }),
              //       );
              //     }}
              //   >
              //     {data?.canCheckOut?.count}
              //   </AppLink>
              // }
              isLoading={dataRequest.isLoading}
            />
          </CounterWidgetCard>

          <CounterWidgetCard>
            <CounterWidgetContent
              title='Ready to check-in'
              icon={<AppIcon of='checkIn' />}
              counter={data?.canCheckIn?.count}
              // counter={
              //   <AppLink
              //     to={ROUTE_PATH.CONTRACTS}
              //     onClick={(e) => {
              //       e.preventDefault();
              //       history.push(
              //         ROUTE_PATH.CONTRACTS,
              //         cast<ContractListPageLocationState>({
              //           filterDefinitionDto: data?.canCheckIn?.filterDefinition,
              //         }),
              //       );
              //     }}
              //   >
              //     {data?.canCheckIn?.count}
              //   </AppLink>
              // }
              isLoading={dataRequest.isLoading}
            />
          </CounterWidgetCard>

          <CounterWidgetCard>
            <CounterWidgetContent
              title='Returning'
              icon={<AppIcon of='data' />}
              counter={data?.returning?.count}
              isLoading={dataRequest.isLoading}
            />
          </CounterWidgetCard>

          <CounterWidgetCard>
            <CounterWidgetContent
              title='Vehicles with issues'
              icon={<AppIcon of='data' />}
              counter={data?.withIssues?.count}
              isLoading={dataRequest.isLoading}
            />
          </CounterWidgetCard>
        </CounterWidgetCardGrid>
      </WidgetContent>
    </BaseWidget>
  );
}
