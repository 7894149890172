import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import _ from "lodash";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import AppIconButton from "@/common/components/Button/AppIconButton";
import Datetime from "@/common/components/Datetime/Datetime";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FileListView from "@/common/components/Files/FileListView";
import InfoTile from "@/common/components/Form/Display/InfoTile";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppTypography from "@/common/components/Text/AppTypography";
import { FileItem } from "@/common/fileItem";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { EntityType, VisualInspectionDto } from "@/core/api/generated";

import ContractLink from "../../../Contract/ContractLink";
import DepartmentLink from "../../../Department/DepartmentLink";
import AffiliationInfoDisplay from "../../../EntityAffiliation/AffiliationInfoDisplay";
import UpdateEntityAffiliationCascadeModal from "../../../EntityAffiliation/UpdateEntityAffiliationCascadeModal";
import GeneralInspectorDisplay from "../../../General/Display/GeneralInspectorDisplay";
import LocationLink from "../../../Location/LocationLink";
import VehicleLink from "../../../Vehicle/VehicleLink";
import VisualInspectionSettingsDisplay from "../../VisualInspectionSettingsDisplay";

interface Props {
  visualInspection: VisualInspectionDto;
  onUpdate: () => void;
}

export default function OverviewTabContent({ visualInspection, onUpdate }: Props) {
  const { enqueueSnackbar } = useAppSnackbar();
  const history = useHistory();

  const [isUpdateAffiliationModalOpened, setIsUpdateAffiliationModalOpened] = useState(false);
  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={
          <PageTabHeader
            title={"Details"}
            subtitle2={<EntityCreatedByInfoDisplay entity={visualInspection} />}
          />
        }
      >
        <Stack direction='column' spacing={2}>
          <Stack spacing={1}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xxs: "1fr",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(4, 1fr)",
                },
                gridTemplateRows: "auto",
                rowGap: 1,
                columnGap: 1,
              }}
            >
              <InfoTile
                label='Number'
                iconOf='personId'
                isEmpty={_.isNil(visualInspection?.localNumber)}
              >
                {visualInspection?.localNumber}
              </InfoTile>

              <InfoTile
                label='Department'
                iconOf='department'
                isEmpty={_.isNil((visualInspection?.departmentIds || [])[0])}
              >
                <DepartmentLink
                  entity={undefined}
                  entityId={(visualInspection?.departmentIds || [])[0]}
                />
              </InfoTile>

              <InfoTile
                label='Location'
                iconOf='location'
                isEmpty={_.isNil((visualInspection?.locationIds || [])[0])}
              >
                <LocationLink
                  entity={undefined}
                  entityId={(visualInspection?.locationIds || [])[0]}
                />
              </InfoTile>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xxs: "1fr",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(4, 1fr)",
                },
                gridTemplateRows: "auto",
                rowGap: 1,
                columnGap: 1,
              }}
            >
              <InfoTile
                label='Vehicle'
                iconOf='vehicle'
                isEmpty={_.isNil(visualInspection?.vehicle)}
              >
                <VehicleLink entity={visualInspection?.vehicle} />
              </InfoTile>

              <InfoTile
                label='Contract'
                iconOf='contract'
                isEmpty={_.isNil(visualInspection?.contract)}
              >
                <ContractLink entity={visualInspection?.contract} />
              </InfoTile>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xxs: "1fr",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(4, 1fr)",
                },
                gridTemplateRows: "auto",
                rowGap: 1,
                columnGap: 1,
              }}
            >
              <InfoTile
                label='Inspected at'
                iconOf='date'
                isEmpty={_.isNil(visualInspection?.inspectedAt)}
              >
                <Datetime datetime={visualInspection?.inspectedAt} />
              </InfoTile>

              <InfoTile
                label='Inspector'
                iconOf='inspector'
                isEmpty={_.isNil(visualInspection?.inspector)}
              >
                <GeneralInspectorDisplay
                  inspector={visualInspection?.inspector}
                  inlineUserProps={{ withLink: true, withAvatar: true, withPoper: true }}
                />
              </InfoTile>

              <InfoTile label='Notes' iconOf='notes' isEmpty={_.isEmpty(visualInspection?.notes)}>
                {visualInspection?.notes}
              </InfoTile>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xxs: "1fr",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(4, 1fr)",
                },
                gridTemplateRows: "auto",
                rowGap: 1,
                columnGap: 1,
              }}
            >
              <InfoTile label='Mileage' iconOf='gauge' isEmpty={_.isNil(visualInspection?.mileage)}>
                {visualInspection?.mileage?.mileage ? (
                  <AppTypography>
                    {visualInspection.mileage.mileage}{" "}
                    <InlineApiEnumValue
                      type='MileageUnitType'
                      value={visualInspection.mileage.unit}
                    />
                  </AppTypography>
                ) : (
                  "-"
                )}
              </InfoTile>

              <InfoTile
                label='Fuel level'
                iconOf='fuel'
                isEmpty={_.isNil(visualInspection?.fuelLevel)}
              >
                {visualInspection?.fuelLevel}
              </InfoTile>
            </Box>
          </Stack>

          {/* Affiliation */}
          <Stack spacing={1} sx={{ mt: 2 }}>
            <Typography variant='h3'>
              Affiliation{" "}
              <AppIconButton
                tooltipProps={{ title: "Edit entity affiliation" }}
                onClick={() => setIsUpdateAffiliationModalOpened(true)}
              >
                <AppIcon of='edit' fontSize='small' />
              </AppIconButton>
            </Typography>
            <AffiliationInfoDisplay
              tenantId={visualInspection.tenantId}
              departmentIds={visualInspection.departmentIds}
              locationIds={visualInspection.locationIds}
              sx={{ mt: 1 }}
            />
            <UpdateEntityAffiliationCascadeModal
              open={isUpdateAffiliationModalOpened}
              onClose={() => setIsUpdateAffiliationModalOpened(false)}
              entityType={EntityType.VisualInspection}
              entityId={visualInspection.id}
              onUpdate={onUpdate}
            />
          </Stack>

          {!_.isEmpty(visualInspection?.attachments) && (
            <Box>
              <FileListView
                files={FileItem.createManyFrom(visualInspection?.attachments)}
                maxVisibleFiles={10}
                itemWidth={45}
              />
            </Box>
          )}

          {/* Items */}
          <Stack spacing={2}>
            <Typography component='span' variant='h3'>
              Inspections
            </Typography>

            {_.isEmpty(visualInspection?.items) && <NoDataAlert />}

            <Stack direction='column' spacing={1}>
              {visualInspection?.items?.map((item, index) => {
                return (
                  <Card key={index}>
                    <CardContent>
                      <Stack spacing={2}>
                        <Typography component='span' variant='subtitle1'>
                          <InlineApiEnumValue type='VehicleArea' value={item.area} />
                        </Typography>

                        <Box>
                          <FileListView
                            files={FileItem.createManyFrom(item?.attachments)}
                            maxVisibleFiles={10}
                            itemWidth={45}
                          />
                        </Box>
                      </Stack>
                    </CardContent>
                  </Card>
                );
              })}
            </Stack>
          </Stack>

          <Stack spacing={2}>
            {!visualInspection?.settings && <NoDataAlert />}

            <VisualInspectionSettingsDisplay settings={visualInspection?.settings} />
          </Stack>
        </Stack>
      </ViewLayout>
    </PageTabContent>
  );
}
