import ContractPaginatedList from "@/common/components/Entity/Contract/ListView/ContractPaginatedList";
import { useLocationState } from "@/common/hooks/useLocationParams";
import { FilterDefinitionDto } from "@/core/api/generated";

export interface ContractListPageLocationState {
  filterDefinitionDto?: Nil<FilterDefinitionDto>;
}

export default function ContractsPage() {
  const locationState = useLocationState<ContractListPageLocationState>();

  return (
    <>
      <ContractPaginatedList
        initialValues={{ filterDefinitionDto: locationState?.filterDefinitionDto }}
      />
    </>
  );
}
