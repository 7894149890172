import { Paper, Stack, Typography } from "@mui/material";

import InlineCode from "@/common/components/Code/InlineCode";

export default function DevPaper() {
  return (
    <Stack spacing={2}>
      <Typography component='div' variant='h2'>
        Paper
      </Typography>

      <Stack spacing={2}>
        <Typography component='div' variant='h5'>
          <InlineCode>MUI Paper</InlineCode>
        </Typography>

        <Stack direction='column' spacing={2}>
          <Typography component='div' variant='h6'>
            Variant
          </Typography>
          <Stack direction='row' spacing={2} alignItems='center'>
            <Stack spacing={2} sx={{ p: 4, backgroundColor: "white" }}>
              <Paper>
                Default paper. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque
                voluptate repellendus similique soluta laboriosam sequi harum doloremque commodi
                facilis cupiditate fugit perferendis doloribus quisquam nulla rem, in ut est hic.
              </Paper>

              <Paper variant='elevation'>
                Elevation paper. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque
                voluptate repellendus similique soluta laboriosam sequi harum doloremque commodi
                facilis cupiditate fugit perferendis doloribus quisquam nulla rem, in ut est hic.
              </Paper>

              <Paper variant='outlined'>
                Outline paper. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque
                voluptate repellendus similique soluta laboriosam sequi harum doloremque commodi
                facilis cupiditate fugit perferendis doloribus quisquam nulla rem, in ut est hic.
              </Paper>

              <Paper variant='contained'>
                Contained paper. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque
                voluptate repellendus similique soluta laboriosam sequi harum doloremque commodi
                facilis cupiditate fugit perferendis doloribus quisquam nulla rem, in ut est hic.
              </Paper>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
