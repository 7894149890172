import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

type SvgIconType = typeof SvgIcon;

export default forwardRef<SVGSVGElement, SvgIconProps>(function CloudUploadIcon(
  props: SvgIconProps,
  ref,
) {
  return (
    <SvgIcon
      width='40'
      height='40'
      viewBox='0 0 40 40'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.4997 5C24.8013 5 28.5913 7.76833 29.958 11.7417C34.023 12.2933 37.1663 15.7867 37.1663 20C37.1663 22.035 36.4263 23.9933 35.083 25.515C34.753 25.8867 34.2947 26.0783 33.833 26.0783C33.4413 26.0783 33.048 25.9417 32.7297 25.6617C32.0413 25.05 31.9747 23.9983 32.5847 23.3067C33.3897 22.3967 33.833 21.22 33.833 20C33.833 17.2433 31.5897 15 28.833 15H28.6663C27.873 15 27.1897 14.44 27.033 13.6617C26.4097 10.575 23.663 8.33333 20.4997 8.33333C17.338 8.33333 14.5897 10.575 13.968 13.6617C13.8113 14.44 13.1263 15 12.333 15H12.1663C9.40967 15 7.16634 17.2433 7.16634 20C7.16634 21.22 7.60967 22.3967 8.41634 23.3067C9.02467 23.9983 8.95967 25.05 8.26967 25.6617C7.57967 26.2717 6.52634 26.2033 5.91801 25.515C4.57301 23.9933 3.83301 22.035 3.83301 20C3.83301 15.7867 6.97634 12.2933 11.0413 11.7417C12.4097 7.76833 16.1997 5 20.4997 5ZM19.3417 18.8002C19.9983 18.1735 21.0367 18.1785 21.6783 18.8218L26.6783 23.8218C27.33 24.4735 27.33 25.5268 26.6783 26.1785C26.3533 26.5035 25.9267 26.6668 25.5 26.6668C25.0733 26.6668 24.6467 26.5035 24.3217 26.1785L22.1667 24.0235V33.3335C22.1667 34.2552 21.42 35.0002 20.5 35.0002C19.58 35.0002 18.8333 34.2552 18.8333 33.3335V23.9268L16.6583 26.0268C15.9967 26.6685 14.9417 26.6468 14.3017 25.9852C13.6617 25.3218 13.68 24.2685 14.3417 23.6285L19.3417 18.8002Z'
      />
    </SvgIcon>
  );
});
