// https://mui.com/x/react-charts/styling/#color-palette
import { ChartsColorPalette } from "@mui/x-charts";

export const chartsColorPaletteLight: ChartsColorPalette = [
  "#41B1FF",
  "#1E81C5",
  "#245679",
  "#E8BC6A",
  "#93D5DD",
  "#A76201",
  "#8FD290",
  "#4390BF",
  "#84CBF8",
  "#2F6830",
];

export const chartsColorPaletteDark: ChartsColorPalette = [...chartsColorPaletteLight];

export const chartsColorPaletteDefault: ChartsColorPalette = (theme) =>
  theme == "light" ? chartsColorPaletteLight : chartsColorPaletteDark;
