import { apiClient } from "@/core/api/ApiClient";
import { EntityType, MaintenanceDto } from "@/core/api/generated";

import BaseEntityDeleteModal, {
  BaseEntityDeleteModalInheritableProps,
} from "../components/BaseEntityDeleteModal";

export interface OwnProps {
  entity: MaintenanceDto;
}

type Props = OwnProps & BaseEntityDeleteModalInheritableProps;

export default function MaintenanceDeleteModal({ entity, ...otherProps }: Props) {
  return (
    <BaseEntityDeleteModal
      entityType={EntityType.Maintenance}
      entity={entity}
      entityId={entity.id}
      deleteFunc={async (params) => {
        await apiClient.maintenancesApi.apiV1MaintenancesMaintenanceIdDelete({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          maintenanceId: params.entityId,
        });
      }}
      {...otherProps}
    />
  );
}
