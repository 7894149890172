import { LinearProgress } from "@mui/material";

import PageTabsV2 from "@/App/Layouts/PageBody/PageTabsV2";
import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useTenantProfile } from "@/common/hooks/entity/tenant/useTenantProfile";
import { apiClient } from "@/core/api/ApiClient";

import EmailSettingsTabContent from "./Tabs/EmailSettingsTabContent";
import MainSettingsTabContent from "./Tabs/MainSettingsTabContent";

export enum TenantSettingsEditPageTabs {
  Main = "Main",
  Email = "Email",
}

export default function CreateUpdateTenantSettingsPage() {
  const tenantProfile = useTenantProfile();

  const tenantSettingsRequest = useApiRequest(
    apiClient.tenantProfileApi.apiV1TenantProfileSettingsGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
    },
  );
  const tenantSettings = tenantSettingsRequest?.data;

  return (
    <ViewLayoutV2 header={<SimpleViewPageHeader title={"Company settings"} />}>
      {tenantSettingsRequest.isLoading && <LinearProgress />}

      <PageTabsV2
        tabIdsDefinition={TenantSettingsEditPageTabs}
        defaultTabId={TenantSettingsEditPageTabs.Main}
        tabs={[
          { label: "Main", value: TenantSettingsEditPageTabs.Main },
          { label: "Email", value: TenantSettingsEditPageTabs.Email },
        ]}
      >
        {({ activeTabId: activeTab }) =>
          tenantSettings && (
            <>
              {activeTab === TenantSettingsEditPageTabs.Main && (
                <MainSettingsTabContent tenantSettings={tenantSettings} />
              )}
              {activeTab === TenantSettingsEditPageTabs.Email && (
                <EmailSettingsTabContent tenantSettings={tenantSettings} />
              )}
            </>
          )
        }
      </PageTabsV2>
    </ViewLayoutV2>
  );
}
