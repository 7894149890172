import { apiClient } from "@/core/api/ApiClient";
import { DamageCostEvaluationDto, EntityType } from "@/core/api/generated";

import BaseEntityDeleteModal, {
  BaseEntityDeleteModalInheritableProps,
} from "../components/BaseEntityDeleteModal";

export interface OwnProps {
  entity: DamageCostEvaluationDto;
}

type Props = OwnProps & BaseEntityDeleteModalInheritableProps;

export default function DamageCostEvaluationDeleteModal({
  entity,
  onDelete,
  ...otherProps
}: Props) {
  return (
    <BaseEntityDeleteModal
      entityType={EntityType.DamageCostEvaluation}
      entity={entity}
      entityId={entity.id}
      deleteFunc={async (params) => {
        await apiClient.damageCostEvaluationsApi.apiV1DamageCostEvaluationsDamageCostEvaluationIdDelete(
          {
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            damageCostEvaluationId: params.entityId,
          },
        );
      }}
      {...otherProps}
    />
  );
}
