import { apiClient } from "@/core/api/ApiClient";
import { EntityType } from "@/core/api/generated";
import { TeslaConnectionDto } from "@/core/api/generated/v0.1-demo";

import BaseEntityDeleteModal, {
  BaseEntityDeleteModalInheritableProps,
} from "../components/BaseEntityDeleteModal";

export interface OwnProps {
  entity: TeslaConnectionDto;
}

type Props = OwnProps & BaseEntityDeleteModalInheritableProps;

export default function TeslaConnectionDeleteModal({ entity, ...otherProps }: Props) {
  return (
    <BaseEntityDeleteModal
      entityType={EntityType.TeslaConnection}
      entity={entity}
      entityId={entity.id}
      deleteFunc={async (params) => {
        await apiClient.teslaConnectionsApi.apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdDelete(
          {
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            teslaConnectionId: params.entityId,
          },
        );
      }}
      {...otherProps}
    />
  );
}
