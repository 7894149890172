import { Button, Chip, Stack, TypographyProps } from "@mui/material";
import { useState } from "react";

import PageTabs from "@/App/Layouts/PageBody/PageTabs";
import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useCommonViewParams } from "@/common/hooks/layout/useCommonViewParams";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { useAppHistory } from "@/common/hooks/useAppHistory";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  AssetSubscriptionDto,
  DataUpdatesHubClientMethodName,
  EntityType,
} from "@/core/api/generated";

import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import AssetSubscriptionMenu from "../AssetSubscriptionMenu";
import AssetSubscriptionReallocateAssetModal from "../AssetSubscriptionReallocateAssetModal";
import AssetTabContent from "./Tabs/AssetTabContent";
import ContractTabContent from "./Tabs/ContractTabContent";
import CustomerTabContent from "./Tabs/CustomerTabContent";
import GeneralHistoryTabContent from "./Tabs/GeneralHistoryTabContent";
import OverviewTabContent from "./Tabs/OverviewTabContent";
import PoolTabContent from "./Tabs/PoolTabContent";
import SubscriptionPlanTabContent from "./Tabs/SubscriptionPlanTabContent";

export enum AssetSubscriptionViewPageTabs {
  Overview = "Overview",
  SubscriptionPlan = "SubscriptionPlan",
  Contract = "Contract",
  Customer = "Customer",
  Asset = "Asset",
  Pool = "Pool",
  GeneralHistory = "GeneralHistory",
}

const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};

interface OwnProps {
  assetSubscriptionId: string | null | undefined;
  assetSubscription: AssetSubscriptionDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
}

type Props = OwnProps & BaseEntityViewInheritableProps<AssetSubscriptionDto>;

export default function AssetSubscriptionView({
  assetSubscriptionId,
  assetSubscription,
  displayProps = defaultDisplayProps,
  headerProps = defaultHeaderProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const currentTenant = useCurrentTenant();
  const commonViewParams = useCommonViewParams({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityView(EntityType.AssetSubscription),
      viewVariant: displayProps.viewVariant,
    },
  });
  const history = useAppHistory();
  const [isConfirmAllocation, setIsConfirmAllocation] = useState<boolean | undefined>(undefined);
  const [isReallocateVehicleModalOpen, setIsReallocateVehicleModalOpen] = useState(false);

  const assetSubscriptionRequest = useApiRequest(
    apiClient.assetSubscriptionsApi.apiV1AssetSubscriptionsAssetSubscriptionIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      assetSubscriptionId: assetSubscriptionId!,
    },
    {
      deps: [assetSubscriptionId],
      skip: !assetSubscriptionId || !!assetSubscription,
    },
  );
  assetSubscription = assetSubscription || assetSubscriptionRequest?.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(
        currentTenant?.id,
        EntityType.AssetSubscription,
        assetSubscription?.id || "",
      ),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      assetSubscriptionRequest.handleEntityChanged(data);
    },
  });
  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        assetSubscription && {
          idValue: assetSubscription.id!,
          newTitle: assetSubscription.localNumber || "",
        }) ||
      undefined,
  });

  return (
    <BaseEntityView
      entityType={EntityType.AssetSubscription}
      entityId={assetSubscriptionId}
      entity={assetSubscription}
      entityRequest={assetSubscriptionRequest}
    >
      <ViewLayout
        displayProps={displayProps}
        header={
          displayProps.header && (
            <DetailedViewPageHeader
              image={undefined}
              title={
                <>
                  {`Asset subscription ${assetSubscription?.localNumber}${
                    assetSubscription?.externalNumber
                      ? ` / ${assetSubscription?.externalNumber}`
                      : ""
                  } (${assetSubscription?.plan?.localNumber}, ${
                    assetSubscription?.plan?.name
                  })`}{" "}
                  <Chip
                    size='small'
                    variant='filled'
                    color='default'
                    label={
                      <InlineApiEnumValue
                        type='SubscriptionStatus'
                        value={assetSubscription?.status}
                      />
                    }
                  />
                </>
              }
              titleProps={{
                to: headerProps?.withLink
                  ? ROUTE_PATH.ASSET_SUBSCRIPTION_VIEW(assetSubscription?.id)
                  : undefined,
                typographyProps: headerProps?.typographyProps,
              }}
              primaryActions={
                displayProps.actions && (
                  <Stack direction='row' spacing={1}>
                    <AuthorizedElement permissions={[AppPermission.AssetSubscriptionManage]}>
                      <Button
                        component={AppLink}
                        to={ROUTE_PATH.ASSET_SUBSCRIPTION_EDIT(assetSubscription?.id)}
                        variant='outlined'
                        color='text'
                        size='medium'
                        startIcon={<AppIcon of='edit' />}
                      >
                        Edit
                      </Button>
                    </AuthorizedElement>

                    {assetSubscription?.mustConfirmAllocation && (
                      <AuthorizedElement permissions={[AppPermission.AssetSubscriptionManage]}>
                        <Button
                          variant='contained'
                          color='primary'
                          size='medium'
                          startIcon={<AppIcon of='done' />}
                          onClick={() => {
                            setIsConfirmAllocation(true);
                            setIsReallocateVehicleModalOpen(true);
                          }}
                        >
                          Confirm allocation
                        </Button>
                      </AuthorizedElement>
                    )}
                  </Stack>
                )
              }
              secondaryActions={
                displayProps.actions &&
                assetSubscription && (
                  <AssetSubscriptionMenu
                    onDelete={() => history.goBack()}
                    onUpdate={(newValue) =>
                      newValue
                        ? assetSubscriptionRequest.replaceData(newValue)
                        : assetSubscriptionRequest.refetch()
                    }
                    entity={assetSubscription}
                    displayProps={{
                      actions: {
                        edit: false,
                        delete: true,
                        eventLog: true,
                      },
                    }}
                  />
                )
              }
            />
          )
        }
      >
        <PageTabs
          tabIdsDefinition={AssetSubscriptionViewPageTabs}
          defaultTabId={AssetSubscriptionViewPageTabs.Overview}
          viewVariant={displayProps?.viewVariant}
          commonViewParams={commonViewParams}
          tabs={[
            { label: "Overview", value: AssetSubscriptionViewPageTabs.Overview, isHideable: false },
            { label: "Subscription plan", value: AssetSubscriptionViewPageTabs.SubscriptionPlan },
            { label: "Contract", value: AssetSubscriptionViewPageTabs.Contract },
            { label: "Customer", value: AssetSubscriptionViewPageTabs.Customer },
            { label: "Asset", value: AssetSubscriptionViewPageTabs.Asset },
            { label: "Pool", value: AssetSubscriptionViewPageTabs.Pool },
            { label: "History", value: AssetSubscriptionViewPageTabs.GeneralHistory },
          ]}
        >
          {({ activeTabId: activeTab }) =>
            assetSubscription && (
              <>
                {activeTab === AssetSubscriptionViewPageTabs.Overview && (
                  <OverviewTabContent
                    assetSubscription={assetSubscription}
                    onUpdate={(newValue) =>
                      newValue
                        ? assetSubscriptionRequest.replaceData(newValue)
                        : assetSubscriptionRequest.refetch()
                    }
                  />
                )}
                {activeTab === AssetSubscriptionViewPageTabs.SubscriptionPlan && (
                  <SubscriptionPlanTabContent assetSubscription={assetSubscription} />
                )}
                {activeTab === AssetSubscriptionViewPageTabs.Contract && (
                  <ContractTabContent assetSubscription={assetSubscription} />
                )}
                {activeTab === AssetSubscriptionViewPageTabs.Customer && (
                  <CustomerTabContent assetSubscription={assetSubscription} />
                )}
                {activeTab === AssetSubscriptionViewPageTabs.Asset && (
                  <AssetTabContent assetSubscription={assetSubscription} />
                )}
                {activeTab === AssetSubscriptionViewPageTabs.Pool && (
                  <PoolTabContent
                    assetSubscription={assetSubscription}
                    onUpdated={(newValue) => assetSubscriptionRequest.replaceData(newValue)}
                  />
                )}
                {activeTab === AssetSubscriptionViewPageTabs.GeneralHistory && (
                  <GeneralHistoryTabContent assetSubscription={assetSubscription} />
                )}
              </>
            )
          }
        </PageTabs>
      </ViewLayout>
      <Stack spacing={2}>
        {/* Reallocate asset */}
        {assetSubscription && (
          <AssetSubscriptionReallocateAssetModal
            open={isReallocateVehicleModalOpen}
            onClose={() => setIsReallocateVehicleModalOpen(false)}
            updateProps={{
              assetSubscription: assetSubscription,
              defaultValues: {
                isConfirmed: isConfirmAllocation,
              },
              onSave: (newValue) => {
                setIsReallocateVehicleModalOpen(false);
                assetSubscriptionRequest?.refetch();
              },
            }}
          />
        )}
      </Stack>
    </BaseEntityView>
  );
}
