import AssetSubscriptionPaginatedList from "@/common/components/Entity/AssetSubscription/ListView/AssetSubscriptionPaginatedList";
import { useLocationState } from "@/common/hooks/useLocationParams";
import { FilterDefinitionDto } from "@/core/api/generated";

export interface AssetSubscriptionListPageLocationState {
  filterDefinitionDto?: Nil<FilterDefinitionDto>;
}

export default function AssetSubscriptionsPage() {
  const locationState = useLocationState<AssetSubscriptionListPageLocationState>();

  return (
    <>
      <AssetSubscriptionPaginatedList
        displayProps={{
          breadcrumbs: true,
          filters: true,
        }}
        initialValues={{ filterDefinitionDto: locationState?.filterDefinitionDto }}
      />
    </>
  );
}
