import { Box } from "@mui/material";

import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import FileUsageLockSubjectLink from "@/common/components/Entity/File/FileUsageLockSubjectLink";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import TimeSpanDisplay from "@/common/components/Form/Display/TimeSpanDisplay";
import { getTypedPath } from "@/common/helpers/typedPath";
import { FileUsageLockDto } from "@/core/api/generated";

interface Props {
  usageLocks: FileUsageLockDto[] | undefined | null;
}

export default function FileUsageLockTabular({ usageLocks }: Props) {
  return (
    <Box>
      <DataTabular
        columns={[
          {
            field: getTypedPath<FileUsageLockDto>().id.$path,
            title: "ID",
            width: 100,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) => item.id,
          },
          {
            field: getTypedPath<FileUsageLockDto>().subject.tenantId.$path,
            title: "Subject tenant id",
            width: 100,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) => item.subject?.tenantId || "-",
          },
          {
            field: getTypedPath<FileUsageLockDto>().subject.type.$path,
            title: "Subject type",
            width: 100,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) =>
              item.subject?.type ? (
                <InlineApiEnumValue type='FileUsageLockSubjectType' value={item.subject?.type} />
              ) : (
                "-"
              ),
          },
          {
            field: getTypedPath<FileUsageLockDto>().subject.entityType.$path,
            title: "Subject entity type",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) =>
              item.subject?.entityType ? (
                <InlineApiEnumValue type='EntityType' value={item.subject?.entityType} />
              ) : (
                "-"
              ),
          },
          {
            field: getTypedPath<FileUsageLockDto>().subject.entityId.$path,
            title: "Subject entity ID",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) => item.subject?.entityId || "-",
          },
          {
            field: "subjectEntityLink",
            title: "Subject entity",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) =>
              item.subject?.entityId ? <FileUsageLockSubjectLink value={item.subject} /> : "-",
          },
          {
            field: getTypedPath<FileUsageLockDto>().subject.computedIdentifier.$path,
            title: "Subject computed identifier",
            width: 100,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) => item.subject?.computedIdentifier || "-",
          },
          {
            field: getTypedPath<FileUsageLockDto>().lockedAt.$path,
            title: "Locked at",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) =>
              item.lockedAt ? <Datetime datetime={item.lockedAt} withDurationFromNow /> : "-",
          },
          {
            field: getTypedPath<FileUsageLockDto>().unlockedAt.$path,
            title: "Unlocked at",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) =>
              item.unlockedAt ? <Datetime datetime={item.unlockedAt} withDurationFromNow /> : "-",
          },
          {
            field: getTypedPath<FileUsageLockDto>().lockDuration.$path,
            title: "Lock duration",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) =>
              item.lockDuration ? <TimeSpanDisplay value={item.lockDuration} /> : "-",
          },
        ]}
        rows={usageLocks}
        getRowId={(item) => item.id || ""}
      />
    </Box>
  );
}
