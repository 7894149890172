import { apiClient } from "@/core/api/ApiClient";
import { EntityType, UserDto } from "@/core/api/generated";

import BaseEntityDeleteModal, {
  BaseEntityDeleteModalInheritableProps,
} from "../components/BaseEntityDeleteModal";

export interface OwnProps {
  entity: UserDto;
}

type Props = OwnProps & BaseEntityDeleteModalInheritableProps;

export default function UserDeleteModal({ entity, ...otherProps }: Props) {
  return (
    <BaseEntityDeleteModal
      entityType={EntityType.User}
      entity={entity}
      entityId={entity.id}
      deleteFunc={async (params) => {
        await apiClient.usersApi.apiV1UsersUserIdDelete({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          userId: entity.id!,
        });
      }}
      {...otherProps}
    />
  );
}
