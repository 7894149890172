import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AppTooltip from "@/common/components/AppTooltip";
import AppIconButton from "@/common/components/Button/AppIconButton";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import ContractBlock from "@/common/components/Entity/Contract/ContractBlock";
import { RepairOperationAddVehicleDamagesModal } from "@/common/components/Entity/RepairOperation/RepairOperationAddVehicleDamagesModal";
import VehicleBlock from "@/common/components/Entity/Vehicle/VehicleBlock";
import VehicleDamageLink from "@/common/components/Entity/VehicleDamage/VehicleDamageLink";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FileListView from "@/common/components/Files/FileListView";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import InfoTile from "@/common/components/Form/Display/InfoTile";
import AppIcon from "@/common/components/Icons/AppIcon";
import DamagePointsByVisualModelViewer from "@/common/components/Images/DamagePointsByVisualModelViewer";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import AppTypography from "@/common/components/Text/AppTypography";
import { ROUTE_PATH } from "@/common/constants/routing";
import { FileItem } from "@/common/fileItem";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { DamageDetectionDto, EntityType, VehicleDamageDto } from "@/core/api/generated";

import AffiliationInfoDisplay from "../../../EntityAffiliation/AffiliationInfoDisplay";
import UpdateEntityAffiliationCascadeModal from "../../../EntityAffiliation/UpdateEntityAffiliationCascadeModal";
import GeneralInspectorDisplay from "../../../General/Display/GeneralInspectorDisplay";

interface Props {
  damageDetection: DamageDetectionDto;
  onUpdate: () => void;
}

export default function OverviewTabContent({ damageDetection, onUpdate }: Props) {
  const { enqueueSnackbar } = useAppSnackbar();
  const history = useHistory();

  const [isUpdateAffiliationModalOpened, setIsUpdateAffiliationModalOpened] = useState(false);
  const [isCommunicationInfoModalOpen, setIsCommunicationInfoModalOpen] = useState(false);
  const [isCreateCommunicationEntryModalOpen, setIsCreateCommunicationEntryModalOpen] =
    useState(false);

  const [isAddToRepairOperationModalOpen, setIsAddToRepairOperationModalOpen] = useState(false);
  const [damageToAddToRepairOperation, setDamageToAddToRepairOperation] = useState<
    VehicleDamageDto | undefined
  >(undefined);
  const visualModelRequest = useApiRequest(
    apiClient.vehicleVisualModelsApi.apiV1ReferenceDataVehiclesVisualModelsModelIdGet,
    {
      modelId: damageDetection?.vehicleVisualModel?.id || "",
    },
    {
      deps: [damageDetection.id],
      skip: !damageDetection?.vehicleVisualModel?.id,
    },
  );
  const visualModel = visualModelRequest?.data;
  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={
          <PageTabHeader
            title={"Details"}
            subtitle2={<EntityCreatedByInfoDisplay entity={damageDetection} />}
          />
        }
      >
        <Stack direction='column' spacing={2}>
          <Stack direction='column' spacing={1}>
            <Stack direction={{ xxs: "column", md: "row" }} alignItems='flex-start' spacing={2}>
              <VehicleBlock
                sx={{ maxWidth: { md: "400px" } }}
                variant='reference'
                vehicle={damageDetection.vehicle}
                withDetailsToggle
                withLink
              />

              <ContractBlock
                sx={{ maxWidth: { md: "400px" } }}
                variant='reference'
                contract={damageDetection.contract}
                withDetailsToggle
                withLink
              />
            </Stack>

            <Stack spacing={1}>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    xxs: "1fr",
                    sm: "repeat(2, 1fr)",
                    md: "repeat(4, 1fr)",
                  },
                  gridTemplateRows: "auto",
                  rowGap: 1,
                  columnGap: 1,
                }}
              >
                <InfoTile
                  label='Inspected at'
                  iconOf='date'
                  isEmpty={_.isNil(damageDetection?.inspectedAt)}
                >
                  <Datetime datetime={damageDetection?.inspectedAt} />
                </InfoTile>

                <InfoTile
                  label='Inspector'
                  iconOf='inspector'
                  isEmpty={_.isNil(damageDetection?.inspector)}
                >
                  <GeneralInspectorDisplay
                    inspector={damageDetection?.inspector}
                    inlineUserProps={{ withLink: true, withAvatar: true, withPoper: true }}
                  />
                </InfoTile>

                <InfoTile label='Notes' iconOf='notes' isEmpty={_.isEmpty(damageDetection?.notes)}>
                  {damageDetection?.notes}
                </InfoTile>
              </Box>

              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    xxs: "1fr",
                    sm: "repeat(2, 1fr)",
                    md: "repeat(4, 1fr)",
                  },
                  gridTemplateRows: "auto",
                  rowGap: 1,
                  columnGap: 1,
                }}
              >
                <InfoTile
                  label='Mileage'
                  iconOf='gauge'
                  isEmpty={_.isNil(damageDetection?.mileage)}
                >
                  {damageDetection?.mileage ? (
                    <AppTypography>
                      {damageDetection.mileage.mileage}{" "}
                      <InlineApiEnumValue
                        type='MileageUnitType'
                        value={damageDetection.mileage.unit}
                      />
                    </AppTypography>
                  ) : (
                    "-"
                  )}
                </InfoTile>

                <InfoTile
                  label='Fuel level'
                  iconOf='fuel'
                  isEmpty={_.isNil(damageDetection?.fuelLevel)}
                >
                  {damageDetection?.fuelLevel}
                </InfoTile>
              </Box>
            </Stack>

            {/* Affiliation */}
            <Stack spacing={1} sx={{ mt: 2 }}>
              <Typography variant='subtitle1'>
                Affiliation{" "}
                <AppIconButton
                  tooltipProps={{ title: "Edit entity affiliation" }}
                  onClick={() => setIsUpdateAffiliationModalOpened(true)}
                >
                  <AppIcon of='edit' fontSize='small' />
                </AppIconButton>
              </Typography>
              <AffiliationInfoDisplay
                tenantId={damageDetection.tenantId}
                departmentIds={damageDetection.departmentIds}
                locationIds={damageDetection.locationIds}
                sx={{ mt: 1 }}
              />
              <UpdateEntityAffiliationCascadeModal
                open={isUpdateAffiliationModalOpened}
                onClose={() => setIsUpdateAffiliationModalOpened(false)}
                entityType={EntityType.DamageDetection}
                entityId={damageDetection.id}
                onUpdate={onUpdate}
              />
            </Stack>

            <Stack
              direction='column'
              sx={{
                p: 2,
                // border: (theme) => `1px solid ${theme.palette.divider}`,
                borderRadius: "8px",
              }}
              spacing={1}
            >
              <Stack direction='row' justifyContent='space-between'>
                <Typography component='div' variant='h6'>
                  Damages
                </Typography>
              </Stack>

              <DamagePointsByVisualModelViewer
                visualModelId={visualModel?.id}
                damages={damageDetection?.items || []}
              />

              {damageDetection.attachments!.length !== 0 && (
                <Box sx={{ mt: 2 }}>
                  <FileListView
                    files={FileItem.createManyFrom(damageDetection.attachments)}
                    maxVisibleFiles={10}
                    itemWidth={45}
                  />
                </Box>
              )}
              {damageDetection.notes && (
                <Box sx={{ mt: 2 }}>
                  <FieldValue label='Notes'>{damageDetection.notes}</FieldValue>
                </Box>
              )}
            </Stack>

            <DataTabular
              columns={[
                {
                  field: "partType.name",
                  title: "Part type",
                  flex: 1,
                  renderCell: (item) => (
                    <Stack>
                      <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
                        <Box>{item.partType?.name}</Box>
                        <Typography component='span' variant='body2' color='text.secondary'>
                          (<InlineApiEnumValue type='VehicleArea' value={item.area} /> /{" "}
                          {(item.projection && (
                            <InlineApiEnumValue type='VehicleProjection' value={item.projection} />
                          )) ||
                            "-"}
                          )
                        </Typography>
                      </Stack>
                    </Stack>
                  ),
                },
                {
                  field: "damageType.name",
                  title: "Damage type",
                  flex: 1,
                  renderCell: (item) => item.damageType?.name,
                },
                {
                  field: "damage.id",
                  title: "Damage",
                  flex: 1,
                  renderCell: (item) =>
                    item.damage ? (
                      <VehicleDamageLink
                        vehicleId={item.damage?.vehicleId}
                        entity={item.damage}
                        withIcon
                      />
                    ) : (
                      "-"
                    ),
                },
                {
                  field: "notes",
                  title: "Notes",
                  width: 200,
                  renderCell: (item) =>
                    item.notes ? (
                      <AppTooltip title={item.notes || ""}>
                        <AppTypography ellipsing={{ enabled: true }}>{item.notes}</AppTypography>
                      </AppTooltip>
                    ) : (
                      "-"
                    ),
                },
                {
                  field: "attachments",
                  title: "Attachments",
                  width: 150,
                  renderCell: (item) => (
                    <>
                      {item.attachments!.length !== 0 && (
                        <FileListView
                          files={FileItem.createManyFrom(item.attachments)}
                          maxVisibleFiles={2}
                          itemWidth={50}
                        />
                      )}
                    </>
                  ),
                },
              ]}
              rows={damageDetection.items!}
              rowHeight={65}
              getRowId={(item) => item.id!}
              borderVariant='bordered'
              isColumnSelectorEnabled={false}
              isColumnMenuEnabled={false}
              isPaginationEnabled={false}
              renderRowAction={({ item }) => (
                <>
                  <MenuWithTrigger
                    trigger={
                      <IconButton>
                        <AppIcon of='moreVert' />
                      </IconButton>
                    }
                  >
                    {({ handleClose }) => [
                      <MenuItem
                        key={0}
                        onClick={async () => {
                          try {
                            const vehicleDamage = (
                              await apiClient.vehicleDamagesApi.apiV1VehiclesVehicleIdDamagesGetByDamageDetectionItemGet(
                                {
                                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                                  vehicleId: damageDetection.vehicle!.id!,
                                  damageDetectionId: damageDetection.id!,
                                  itemId: item.id!,
                                },
                              )
                            ).data;
                            if (vehicleDamage) {
                              history.push(
                                ROUTE_PATH.VEHICLE_DAMAGE_VIEW({
                                  vehicleId: vehicleDamage.vehicle!.id!,
                                  vehicleDamageId: vehicleDamage.id!,
                                }),
                              );
                            }
                          } catch (err) {
                            const validation2 = ValidationHelper.handleApiErrorResponse(err);
                            enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
                          }
                        }}
                      >
                        <ListItemIcon>
                          <AppIcon of='view' fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>View damage</ListItemText>
                      </MenuItem>,
                      <MenuItem
                        key={1}
                        onClick={async () => {
                          try {
                            const vehicleDamage = (
                              await apiClient.vehicleDamagesApi.apiV1VehiclesVehicleIdDamagesGetByDamageDetectionItemGet(
                                {
                                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                                  vehicleId: damageDetection.vehicle!.id!,
                                  damageDetectionId: damageDetection.id!,
                                  itemId: item.id!,
                                },
                              )
                            ).data;
                            if (vehicleDamage) {
                              setIsAddToRepairOperationModalOpen(true);
                              setDamageToAddToRepairOperation(vehicleDamage);
                              handleClose();
                            }
                          } catch (err) {
                            const validation2 = ValidationHelper.handleApiErrorResponse(err);
                            enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
                          }
                        }}
                      >
                        <ListItemIcon>
                          <AppIcon of='add' fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Add damage to repair operation</ListItemText>
                      </MenuItem>,
                    ]}
                  </MenuWithTrigger>
                </>
              )}
            />
          </Stack>

          {/* Add to repair operation */}
          {damageToAddToRepairOperation && (
            <RepairOperationAddVehicleDamagesModal
              open={isAddToRepairOperationModalOpen}
              onClose={() => setIsAddToRepairOperationModalOpen(false)}
              damages={[damageToAddToRepairOperation]}
              onSaved={() => onUpdate && onUpdate()}
            />
          )}
        </Stack>
      </ViewLayout>
    </PageTabContent>
  );
}
