import { Box, Stack } from "@mui/material";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import AppIconButton from "@/common/components/Button/AppIconButton";
import AppIcon from "@/common/components/Icons/AppIcon";
import TextLineSkeleton from "@/common/components/Skeleton/TextLineSkeleton";
import ActiveOperationCountWidget from "@/common/components/Widgets/Business/ActiveOperationCountWidget";
import CostSummaryWidget from "@/common/components/Widgets/Business/CostSummaryWidget";
import DamageAndIssueSummaryWidget from "@/common/components/Widgets/Business/DamageAndIssueSummaryWidget";
import VehicleInFlowSummaryWidget from "@/common/components/Widgets/Business/VehicleInFlowSummaryWidget";
import VehicleStatusSummaryWidget from "@/common/components/Widgets/Business/VehicleStatusSummaryWidget";
import { widgetEventEmitter } from "@/common/eventEmmiters/widgetEventEmitter";
import { useUserProfile } from "@/common/hooks/useUserProfile";

export default function DashboardV1() {
  const profile = useUserProfile();

  return (
    <ViewLayoutV2
      displayProps={{ breadcrumbs: false }}
      header={
        <SimpleViewPageHeader
          title={
            <Stack direction='row' sx={{ justifyContent: "space-between", alignItems: "center" }}>
              <Box>
                {!profile && <TextLineSkeleton />}
                {profile && (
                  <>Hi, {`${profile?.personName?.name || profile?.email || "User"} 👋`}</>
                )}
              </Box>

              <Box>
                <AppIconButton
                  variant='text'
                  color='inherit'
                  size='small'
                  tooltipProps={{ title: "Recompute all data" }}
                  onClick={() => {
                    widgetEventEmitter.emit("recomputeAllDataRequested", undefined);
                  }}
                >
                  <AppIcon of='recompute' />
                </AppIconButton>
              </Box>
            </Stack>
          }
        />
      }
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(400px, 700px))",
          gap: 2,
        }}
      >
        <VehicleStatusSummaryWidget />

        <VehicleInFlowSummaryWidget />

        <DamageAndIssueSummaryWidget />

        <ActiveOperationCountWidget />

        <CostSummaryWidget />
      </Box>
    </ViewLayoutV2>
  );
}
