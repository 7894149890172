import { ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

import BaseEntitySearchAutocomplete, {
  BaseEntitySearchAutocompleteInheritableProps,
} from "@/common/components/Entity/components/BaseEntitySearchAutocomplete";
import AppIcon from "@/common/components/Icons/AppIcon";
import { renderIf } from "@/common/helpers/render/renderIf";
import { StringHelper } from "@/common/helpers/string";
import { enumService } from "@/common/services/enum";
import { AutocompleteOptionType, BaseAutocompleteOption } from "@/common/ts/autocomplete";
import { apiClient } from "@/core/api/ApiClient";
import { WheelOperationDto, WheelOperationGetPaginatedDto } from "@/core/api/generated";

import { getVehicleOptionTitle } from "../Vehicle/VehicleAutocomplete";
import VehicleInline from "../Vehicle/VehicleInline";

export type WheelOperationAutocompleteOption = BaseAutocompleteOption<WheelOperationDto>;

export const getWheelOperationOptionTitle = (data: WheelOperationDto): string =>
  StringHelper.joinIntoString(
    [
      data.vehicle ? getVehicleOptionTitle(data.vehicle) : data.localNumber,
      enumService.getEnumValueName("WheelOperationStage", data.stageHistory?.stage),
    ],
    ", ",
    {
      skipEmpty: true,
    },
  );

export const wheelOperationToOption = (
  data: WheelOperationDto,
): WheelOperationAutocompleteOption => ({
  id: data.id!,
  title: getWheelOperationOptionTitle(data),
  optionType: AutocompleteOptionType.Normal,
  data: data,
});

export interface WheelOperationAutocompleteProps
  extends BaseEntitySearchAutocompleteInheritableProps<WheelOperationDto> {
  searchFilters?: Partial<Omit<WheelOperationGetPaginatedDto, "search" | "includeIds">>;
}

export default function WheelOperationAutocomplete({
  searchFilters,

  isPreload = true,
  ...otherProps
}: WheelOperationAutocompleteProps) {
  return (
    <BaseEntitySearchAutocomplete
      sx={{ minWidth: 200, flex: 1 }}
      entityToOption={wheelOperationToOption}
      isPreload={isPreload}
      request={{
        requestFunc: apiClient.wheelOperationsApi.apiV1WheelOperationsGetPost,
        limit: 25,
        parameters: {
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          wheelOperationGetPaginatedDto: {
            ...searchFilters,
          },
        },
        combineParameters: (params, newParams) => ({
          ...params,
          wheelOperationSearchPaginatedDto: {
            ...params.wheelOperationGetPaginatedDto,
            ...newParams,
          },
        }),
        deps: [searchFilters],
      }}
      label='Wheel operation'
      placeholder='Search...'
      renderOption={(props, option: WheelOperationAutocompleteOption) => {
        return (
          <ListItem {...props}>
            <ListItemIcon>
              {renderIf()
                .if(option.optionType === AutocompleteOptionType.Normal)
                .then(<AppIcon of='data' />)
                .elseif(option.optionType === AutocompleteOptionType.DynamicCreateNew)
                .then(<AppIcon of='add' />)
                .render()}
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  <Typography
                    variant={
                      option.optionType === AutocompleteOptionType.DynamicCreateNew
                        ? "subtitle1"
                        : "body1"
                    }
                    component='div'
                  >
                    {option.title}
                  </Typography>
                  {option.data && (
                    <Typography component='div' variant='body2'>
                      {option?.data?.vehicle && (
                        <VehicleInline entity={option.data.vehicle} withIcon={false} />
                      )}
                    </Typography>
                  )}
                </>
              }
            />
          </ListItem>
        );
      }}
      {...otherProps}
    />
  );
}
