import WashPaginatedList from "@/common/components/Entity/Wash/ListView/WashPaginatedList";
import { useLocationState } from "@/common/hooks/useLocationParams";
import { FilterDefinitionDto } from "@/core/api/generated";

export interface WashListPageLocationState {
  filterDefinitionDto?: Nil<FilterDefinitionDto>;
}

export default function WashesPage() {
  const locationState = useLocationState<WashListPageLocationState>();

  return (
    <>
      <WashPaginatedList
        initialValues={{ filterDefinitionDto: locationState?.filterDefinitionDto }}
      />
    </>
  );
}
