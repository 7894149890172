import { Button, IconButton, MenuItem, Stack, SxProps, Theme, Typography } from "@mui/material";
import _ from "lodash";
import { useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import InlineUser from "@/App/MainAppView/components/User/InlineUser";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppIconButton from "@/common/components/Button/AppIconButton";
import DataExportModal from "@/common/components/DataExport/DataExportModal";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import EntityTagsInfoDisplay from "@/common/components/EntityInfo/EntityTagsInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import TableCellContentOfCount from "@/common/components/Table/TableCell/TableCellContentOfCount";
import { ROUTE_PATH } from "@/common/constants/routing";
import { FilterCatalog } from "@/common/filters/filterCatalog";
import { EntityHelper } from "@/common/helpers/entity";
import { TagStaticFilterInputDto, TagsHelper } from "@/common/helpers/entity/tags";
import { getTypedPath } from "@/common/helpers/typedPath";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { PropagatedDeps } from "@/common/hooks/render/usePropagatedDeps";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { ApiEnumName } from "@/common/services/enum";
import { FilterFieldType } from "@/common/ts/filters";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  CustomerDto,
  CustomerGetPaginatedDto,
  DataUpdatesHubClientMethodName,
  EntityType,
  ExportEntityType,
  GeneralAttachedTagsInputDto,
  TagEntityType,
} from "@/core/api/generated";

import AffiliationInfoDisplay from "../../EntityAffiliation/AffiliationInfoDisplay";
import GeneralAddressDisplay from "../../General/Display/GeneralAddressDisplay";
import GeneralAttachedTagsOfEntitiesEditModal from "../../General/GeneralTag/GeneralAttachedTagsOfEntitiesEditModal";
import TenantStructureMembershipOfEntityEditModal from "../../Membership/TenantStructureMembershipOfEntityEditModal";
import CustomerContactCard from "../CustomerContactCard";
import CustomerMenu from "../CustomerMenu";
import CustomersDeleteModal from "../CustomersDeleteModal";

const defaultDisplayProps = {
  filters: true,
  counters: true,
  create: true,
  edit: true,
  delete: true,
  viewVariant: ViewLayoutVariant.Page,
};

enum BulkActionFlags {
  UpdateTenantStructureMembership = "UpdateTenantStructureMembership",
  UpdateTags = "UpdateTags",
  Export = "Export",
  Delete = "Delete",
}

const defaultPropagatedDeps = {
  refetch: {},
};

export type CustomerPaginatedListPropagatedDepsMap = typeof defaultPropagatedDeps;
type CustomerGetPaginatedInputDto = Omit<CustomerGetPaginatedDto, "tags"> & {
  tags?: TagStaticFilterInputDto;
};
export interface CustomerPaginatedListOwnProps {
  displayProps?: Partial<typeof defaultDisplayProps>;
  defaultValues?: {
    limit?: number;
    vehicleId?: string;
    customerId?: string;
  };
  propagatedDeps?: PropagatedDeps<CustomerPaginatedListPropagatedDepsMap>;
  sx?: SxProps<Theme>;
}

export type CustomerPaginatedListProps = CustomerPaginatedListOwnProps;

export default function CustomerPaginatedList({
  displayProps,
  defaultValues,
  propagatedDeps,
  sx,
}: CustomerPaginatedListProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<CustomerGetPaginatedInputDto>({
    statePersistence: {
      persistenceKey: EntityType.Customer,
    },
    defaultValues: {
      limit: defaultValues?.limit,
      params: {
        ...defaultValues,
      },
    },
    initParams: (filterDefinition) => {
      const tagFilter = filterDefinition.filterDefinition?.items.find(
        (x) => x.field === getTypedPath<CustomerDto>().tags.$path,
      );

      return {
        tags: {
          operator: tagFilter?.operator,
          query: tagFilter?.value as GeneralAttachedTagsInputDto,
        } as TagStaticFilterInputDto,
      };
    },
  });

  const [isDataExportModalOpened, setIsDataExportModalOpened] = useState(false);

  const paginatedCustomersRequest = useApiRequest(
    apiClient.customersApi.apiV1CustomersGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      customerGetPaginatedDto: {
        ..._.omit(commonRequestParams.params, "tags"),
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        tags:
          commonRequestParams.filterDefinition?.items
            .filter((x) => x.field === getTypedPath<CustomerDto>().tags.$path)
            .map((x) => TagsHelper.mapFromFilter(x))[0] || undefined,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [
        ...commonRequestParams.deps,
        commonRequestParams.filterDefinition,
        propagatedDeps?.depsMap["refetch"],
      ],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );

  const paginatedCustomers = useMemo(
    () => paginatedCustomersRequest?.data,
    [paginatedCustomersRequest?.data],
  );

  const customerIds = useMemo(
    () => paginatedCustomers?.items?.map((x) => x.id!) || [],
    [paginatedCustomers?.items],
  );

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.Customer)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedCustomersRequest.handleEntityChanged(data);
    },
  });

  return (
    <ViewLayoutV2
      displayProps={displayProps}
      header={
        <SimpleViewPageHeader
          title='Customers'
          primaryActions={
            displayProps?.create && (
              <AuthorizedElement permissions={[AppPermission.CustomerManage]}>
                <Button
                  variant='contained'
                  color='primary'
                  startIcon={<AppIcon of='add' />}
                  component={RouterLink}
                  to={ROUTE_PATH.CUSTOMER_CREATE}
                >
                  Create new customer
                </Button>
              </AuthorizedElement>
            )
          }
          secondaryActions={
            <AuthorizedElement permissions={[AppPermission.ExportManage]}>
              <MenuWithTrigger
                trigger={
                  <IconButton>
                    <AppIcon of='moreVert' />
                  </IconButton>
                }
              >
                <MenuItem onClick={() => setIsDataExportModalOpened(true)}>
                  <AppIcon of='export' fontSize='small' sx={{ mr: 1 }} />
                  <Typography>Export customers</Typography>
                </MenuItem>
              </MenuWithTrigger>
            </AuthorizedElement>
          }
        />
      }
    >
      <DataTabular
        columns={[
          {
            field: getTypedPath<CustomerDto>().id.$path,
            title: "ID",
            width: 250,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.id,
            filters: {
              fieldType: FilterFieldType.Id,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<CustomerDto>().localNumber.$path,
            title: "Number",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.localNumber,
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<CustomerDto>().name.$path,
            title: "Name",
            width: 300,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) => (
              <Stack>
                <Typography component='div'>
                  {item.name || item.contact?.personName?.name}
                </Typography>
                <Typography component='div' variant='body2'>
                  {item.contact?.email}
                </Typography>
              </Stack>
            ),
          },
          {
            field: getTypedPath<CustomerDto>().type.$path,
            title: "Type",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => <InlineApiEnumValue type='CustomerType' value={item.type} />,
            filters: {
              fieldType: FilterFieldType.Enum,
              isUseDefaultOperators: true,
              fieldTypeMeta: {
                enum: {
                  enumName: ApiEnumName.CustomerType,
                },
              },
            },
          },
          {
            field: getTypedPath<CustomerDto>().contact.personName.name.$path,
            title: "Primary contact name",
            width: 200,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.contact?.personName?.name || "-",
          },
          {
            field: getTypedPath<CustomerDto>().contact.email.$path,
            title: "Primary contact email",
            width: 300,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.contact?.email || "-",
          },
          {
            field: getTypedPath<CustomerDto>().contact.phoneNumber.$path,
            title: "Primary contact phone",
            width: 200,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.contact?.phoneNumber || "-",
          },
          {
            field: getTypedPath<CustomerDto>().contact.nationalIdentityNumber.number.$path,
            title: "Primary contact national identity number",
            width: 200,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.contact?.nationalIdentityNumber?.number || "-",
          },
          {
            field: getTypedPath<CustomerDto>().contacts.$path,
            title: "Contacts",
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) => (
              <TableCellContentOfCount
                count={item.contacts?.length}
                popoverContent={
                  <Stack spacing={1}>
                    {item.contacts?.map((contact) => (
                      <CustomerContactCard
                        key={contact.id}
                        contact={contact}
                        sx={{ minWidth: 300 }}
                      />
                    ))}
                  </Stack>
                }
              />
            ),
          },
          {
            field: getTypedPath<CustomerDto>().address.$path,
            title: "Address",
            width: 300,
            renderCell: (item) =>
              item.address ? <GeneralAddressDisplay address={item.address} direction='row' /> : "-",
            renderCellDetails: (item) => (
              <GeneralAddressDisplay address={item.address} direction='column' />
            ),
          },
          {
            field: getTypedPath<CustomerDto>().notes.$path,
            title: "Notes",
            width: 100,
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) => item.notes || "-",
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<CustomerDto>().createdAt.$path,
            title: "Created at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.createdAt ? <Datetime datetime={item.createdAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<CustomerDto>().updatedAt.$path,
            title: "Updated at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.updatedAt ? <Datetime datetime={item.updatedAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<CustomerDto>().createdBy.$path,
            title: "Created by",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.createdBy ? <InlineUser userId={item.createdBy} withAvatar /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
          {
            field: getTypedPath<CustomerDto>().updatedBy.$path,
            title: "Updated by",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.updatedBy ? <InlineUser userId={item.updatedBy} withAvatar /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
          {
            field: "affiliation",
            title: "Affiliation",
            description: "Affiliation to departments & locations",
            width: 120,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            isToDisabled: true,
            renderCell: (item) => (
              <AffiliationInfoDisplay
                variant='compact'
                withoutTenant
                tenantId={item.tenantId}
                departmentIds={item.departmentIds}
                locationIds={item.locationIds}
              />
            ),
          },
          {
            field: getTypedPath<CustomerDto>().tags.$path,
            title: "Tags",
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            isColumnMenuDisabled: false,
            isToDisabled: true,
            renderCell: (item) => (
              <EntityTagsInfoDisplay
                entityType={TagEntityType.Customer}
                entity={item}
                noDataPlaceholder='-'
                edit={{
                  onSaved: (newValue) =>
                    paginatedCustomersRequest.updateData((data) => {
                      data.items?.forEach((item2) => {
                        if (item2.id === item.id) {
                          item2.tags = newValue || undefined;
                        }
                      });
                    }),
                }}
              />
            ),
            filters: {
              fieldType: FilterFieldType.Arbitrary,
              isUseSingleFilter: true,
              operators: FilterCatalog.getOperatorsForTagsField(),
            },
          },
        ]}
        rows={paginatedCustomers?.items}
        isLoading={paginatedCustomersRequest.isLoading}
        getRowId={(item) => item.id!}
        rowTo={(item) => ROUTE_PATH.CUSTOMER_VIEW(item.id)}
        renderRowAction={({ item }) => (
          <CustomerMenu
            entity={item}
            onDelete={() => paginatedCustomersRequest.refetch()}
            onUpdate={() => paginatedCustomersRequest.refetch()}
            displayProps={{
              actions: {
                edit: displayProps?.edit || false,
                delete: displayProps?.delete || false,
                inviteAsUser: true,
              },
            }}
          />
        )}
        statePersistence={commonRequestParams.dataTabularProps.statePersistence}
        pagination={commonRequestParams.dataTabularProps.pagination}
        sort={commonRequestParams.dataTabularProps.sort}
        quickFilter={commonRequestParams.dataTabularProps.quickFilter}
        filters={commonRequestParams.dataTabularProps.filters}
        refetch={commonRequestParams.dataTabularProps.refetch}
        bulkActions={{
          enabled: true,
          definition: BulkActionFlags,
          actionTriggers: ({ currentAction, startAction, selectedIds }) => (
            <>
              <AppIconButton
                tooltipProps={{ title: "Update affiliation" }}
                onClick={() => startAction(BulkActionFlags.UpdateTenantStructureMembership)}
              >
                <AppIcon of='department' />
              </AppIconButton>
              <AppIconButton
                tooltipProps={{ title: "Update tags" }}
                onClick={() => startAction(BulkActionFlags.UpdateTags)}
              >
                <AppIcon of='tag' />
              </AppIconButton>
              <AppIconButton
                tooltipProps={{ title: "Export" }}
                onClick={() => startAction(BulkActionFlags.Export)}
              >
                <AppIcon of='export' />
              </AppIconButton>
              <AppIconButton
                tooltipProps={{ title: "Delete" }}
                onClick={() => startAction(BulkActionFlags.Delete)}
              >
                <AppIcon of='delete' />
              </AppIconButton>
            </>
          ),
          actionHandlers: ({ selectedIds, currentAction, cancelAction, completeAction }) => {
            const selectedEntities = EntityHelper.filterEntitiesByIds(
              paginatedCustomers?.items || [],
              selectedIds as string[],
            );
            return (
              <>
                <TenantStructureMembershipOfEntityEditModal
                  open={currentAction === BulkActionFlags.UpdateTenantStructureMembership}
                  onClose={() => cancelAction()}
                  tenantStructureMembershipOfEntityEditProps={{
                    entityType: EntityType.Customer,
                    entities: selectedEntities,
                    onSave: () => {
                      completeAction();
                      paginatedCustomersRequest.refetch();
                    },
                  }}
                />
                <GeneralAttachedTagsOfEntitiesEditModal
                  tagEntityType={TagEntityType.Customer}
                  entities={selectedEntities}
                  open={currentAction === BulkActionFlags.UpdateTags}
                  onClose={() => cancelAction()}
                  onSaved={() => {
                    completeAction();
                    paginatedCustomersRequest.refetch();
                  }}
                />
                <CustomersDeleteModal
                  entities={selectedEntities}
                  open={currentAction === BulkActionFlags.Delete}
                  onClose={() => cancelAction()}
                  onDelete={() => {
                    completeAction();
                    paginatedCustomersRequest.refetch();
                  }}
                />
                {/* Export data modal */}
                <DataExportModal
                  open={currentAction === BulkActionFlags.Export}
                  onClose={() => cancelAction()}
                  exportDataProps={{
                    entityType: ExportEntityType.Customer,
                    ids: selectedIds as string[],
                    onExportDone: completeAction,
                    displayProps: {
                      mode: false,
                    },
                  }}
                />
              </>
            );
          },
        }}
      />

      {/* Export data modal */}
      <DataExportModal
        open={isDataExportModalOpened}
        onClose={() => setIsDataExportModalOpened(false)}
        exportDataProps={{
          entityType: ExportEntityType.Customer,
          ids: customerIds,
          onExportDone: () => {
            setIsDataExportModalOpened(false);
          },
        }}
      />
    </ViewLayoutV2>
  );
}
