import { CardContent } from "@mui/material";
import { ReactNode } from "react";

import { TypeHelper } from "@/common/helpers/type";

import AppCard from "../../Card/AppCard";
import CardActionAreaFacade from "../../Mui/Facades/CardActionAreaFacade";
import SimpleCardSkeleton from "../../Skeleton/SimpleCardSkeleton";

export interface BaseWidgetProps {
  to?: string;
  isLoading?: boolean;
  children: ReactNode;
}

export interface BaseWidgetInheritablePops {
  to?: string;
  isLoading?: boolean;
}

export default function BaseWidget({ to, isLoading, children }: BaseWidgetProps) {
  return (
    <AppCard variant='elevation' variant2='data'>
      <CardActionAreaFacade enabled={!TypeHelper.isEmpty(to)} to={to}>
        <CardContent>
          {isLoading && <SimpleCardSkeleton />}

          {!isLoading && children}
        </CardContent>
      </CardActionAreaFacade>
    </AppCard>
  );
}
