import { Box } from "@mui/material";
import { ReactNode } from "react";

export interface WidgetContentProps {
  children: ReactNode;
}

export default function WidgetContent({ children }: WidgetContentProps) {
  return <Box>{children}</Box>;
}
