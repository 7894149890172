import { Button, Stack, TypographyProps } from "@mui/material";

import PageTabs from "@/App/Layouts/PageBody/PageTabs";
import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useCommonViewParams } from "@/common/hooks/layout/useCommonViewParams";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { useAppHistory } from "@/common/hooks/useAppHistory";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  ProductLocationDto,
} from "@/core/api/generated";

import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import ProductLocationMenu from "../ProductLocationMenu";
import OverviewTabContent from "./Tabs/OverviewTabContent";
import SpotsTabContent from "./Tabs/SpotsTabContent";

export enum ProductLocationViewPageTabs {
  Overview = "Overview",
  Spots = "Spots",
}

const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};

interface OwnProps extends BaseEntityViewInheritableProps<ProductLocationDto> {
  productLocationId: string | null | undefined;
  productLocation: ProductLocationDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
}

type Props = OwnProps;

export default function ProductLocationView({
  productLocationId,
  productLocation,
  displayProps = defaultDisplayProps,
  headerProps = defaultHeaderProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const history = useAppHistory();
  const currentTenant = useCurrentTenant();
  const commonViewParams = useCommonViewParams({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityView(EntityType.ProductLocation),
      viewVariant: displayProps.viewVariant,
    },
  });

  const productLocationRequest = useApiRequest(
    apiClient.productLocationsApi.apiV1ProductLocationsProductLocationIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      productLocationId: productLocationId!,
    },
    {
      deps: [productLocationId],
      skip: !productLocationId || !!productLocation,
    },
  );
  productLocation = productLocation || productLocationRequest?.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(
        currentTenant?.id,
        EntityType.ProductLocation,
        productLocation?.id || "",
      ),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      productLocationRequest.handleEntityChanged(data);
    },
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        productLocation && {
          idValue: productLocation.id!,
          newTitle: productLocation.localNumber || "",
        }) ||
      undefined,
  });

  return (
    <BaseEntityView
      entityType={EntityType.ProductLocation}
      entityId={productLocationId}
      entity={productLocation}
      entityRequest={productLocationRequest}
    >
      <ViewLayout
        displayProps={displayProps}
        header={
          displayProps.header && (
            <DetailedViewPageHeader
              image={undefined}
              title={`Sales location ${productLocation?.name} (${productLocation?.localNumber})`}
              titleProps={{
                to: headerProps?.withLink
                  ? ROUTE_PATH.PRODUCT_SALES_LOCATION_VIEW(productLocation?.id)
                  : undefined,
                typographyProps: headerProps?.typographyProps,
              }}
              primaryActions={
                displayProps.actions && (
                  <AuthorizedElement permissions={[AppPermission.ProductLocationManage]}>
                    <Button
                      component={AppLink}
                      to={ROUTE_PATH.PRODUCT_SALES_LOCATION_EDIT(productLocation?.id)}
                      variant='outlined'
                      color='text'
                      size='medium'
                      startIcon={<AppIcon of='edit' />}
                    >
                      Edit
                    </Button>
                  </AuthorizedElement>
                )
              }
              secondaryActions={
                displayProps.actions &&
                productLocation && (
                  <ProductLocationMenu
                    entity={productLocation}
                    onDelete={() => history.goBack()}
                    onUpdate={(newValue) =>
                      newValue
                        ? productLocationRequest.replaceData(newValue)
                        : productLocationRequest.refetch()
                    }
                    displayProps={{ actions: { edit: false, delete: true } }}
                  />
                )
              }
            />
          )
        }
      >
        <Stack spacing={2}>
          <PageTabs
            tabIdsDefinition={ProductLocationViewPageTabs}
            defaultTabId={ProductLocationViewPageTabs.Overview}
            commonViewParams={commonViewParams}
            tabs={[
              { label: "Overview", value: ProductLocationViewPageTabs.Overview, isHideable: false },
              { label: "Spots", value: ProductLocationViewPageTabs.Spots },
            ]}
          >
            {({ activeTabId: activeTab }) =>
              productLocation && (
                <>
                  {activeTab === ProductLocationViewPageTabs.Overview && (
                    <OverviewTabContent
                      productLocation={productLocation}
                      onUpdate={productLocationRequest.refetch}
                    />
                  )}
                  {activeTab === ProductLocationViewPageTabs.Spots && (
                    <SpotsTabContent productLocation={productLocation} />
                  )}
                </>
              )
            }
          </PageTabs>
        </Stack>
      </ViewLayout>
    </BaseEntityView>
  );
}
