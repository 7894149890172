import { apiClient } from "@/core/api/ApiClient";
import { DepartmentDto, EntityType } from "@/core/api/generated";

import BaseEntityDeleteModal, {
  BaseEntityDeleteModalInheritableProps,
} from "../components/BaseEntityDeleteModal";

export interface OwnProps {
  entity: DepartmentDto;
}

type Props = OwnProps & BaseEntityDeleteModalInheritableProps;

export default function DepartmentDeleteModal({ entity, ...otherProps }: Props) {
  return (
    <BaseEntityDeleteModal
      entityType={EntityType.Department}
      entity={entity}
      entityId={entity.id}
      deleteFunc={async (params) => {
        await apiClient.departmentsApi.apiV1DepartmentsDepartmentIdDelete({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          departmentId: params.entityId,
          isDeleteCascade: params.isDeleteCascade,
        });
      }}
      {...otherProps}
    />
  );
}
