import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import AppIcon from "@/common/components/Icons/AppIcon";
import RouteCategoryIndex from "@/common/components/Route/RouteCategoryIndex";
import { ROUTE_PATH } from "@/common/constants/routing";

export default function OperationsPage() {
  return (
    <ViewLayoutV2 header={<SimpleViewPageHeader title='Operations' />}>
      <RouteCategoryIndex
        direction='row'
        childrenLinks={[
          {
            icon: <AppIcon of='visualInspections' />,
            title: "Visual inspections",
            to: ROUTE_PATH.VISUAL_INSPECTIONS,
          },
          {
            icon: <AppIcon of='damageDetections' />,
            title: "Damage detections",
            to: ROUTE_PATH.DAMAGE_DETECTIONS,
          },
          {
            icon: <AppIcon of='damageCostEvaluations' />,
            title: "Damage cost evaluations",
            to: ROUTE_PATH.DAMAGE_COST_EVALUATIONS,
          },
          {
            icon: <AppIcon of='accessoryChecks' />,
            title: "Accessory checks",
            to: ROUTE_PATH.ACCESSORY_CHECKS,
          },
          {
            icon: <AppIcon of='repairOperations' />,
            title: "Repair operations",
            to: ROUTE_PATH.REPAIR_OPERATIONS,
          },
          {
            icon: <AppIcon of='wheelOperation' />,
            title: "Wheel operations",
            to: ROUTE_PATH.WHEEL_OPERATIONS,
          },
          {
            icon: <AppIcon of='wash' />,
            title: "Washes",
            to: ROUTE_PATH.WASHES,
          },
          {
            icon: <AppIcon of='maintenance' />,
            title: "Maintenances",
            to: ROUTE_PATH.MAINTENANCES,
          },
        ]}
      />
    </ViewLayoutV2>
  );
}
