import { Stack } from "@mui/material";
import { ReactNode } from "react";

import { pxToRemCustom } from "@/theme";

import AppTypography from "../../Text/AppTypography";

export interface WidgetTitleProps {
  icon?: ReactNode;
  children: ReactNode;
  actions?: ReactNode;
}

export default function WidgetTitle({ icon, children, actions }: WidgetTitleProps) {
  return (
    <Stack
      direction='row'
      spacing={2}
      sx={{ justifyContent: "space-between", alignItems: "center" }}
    >
      <Stack
        direction='row'
        spacing={1}
        sx={{ justifyContent: "flex-start", alignItems: "center" }}
      >
        {icon && (
          <AppTypography variant='body1' sx={{ display: "flex", fontSize: pxToRemCustom(24) }}>
            {icon}
          </AppTypography>
        )}

        <AppTypography variant='subtitle1'>{children}</AppTypography>
      </Stack>

      {actions && (
        <Stack
          direction='row'
          spacing={1}
          sx={{ justifyContent: "flex-start", alignItems: "center" }}
        >
          {actions}
        </Stack>
      )}
    </Stack>
  );
}
