import { Box, Button, DialogContentText, Stack, SxProps, Theme } from "@mui/material";
import { useMemo } from "react";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import InlineUser from "@/App/MainAppView/components/User/InlineUser";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import ConfirmationModalWithTrigger from "@/common/components/Modals/ConfirmationModalWithTrigger";
import TableCellContentOfCount from "@/common/components/Table/TableCell/TableCellContentOfCount";
import AppTypography from "@/common/components/Text/AppTypography";
import { ROUTE_PATH } from "@/common/constants/routing";
import { FilterCatalog } from "@/common/filters/filterCatalog";
import { getTypedPath } from "@/common/helpers/typedPath";
import { UrlHelper } from "@/common/helpers/url";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { PropagatedDeps } from "@/common/hooks/render/usePropagatedDeps";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { ApiEnumName } from "@/common/services/enum";
import { teslaService } from "@/common/services/tesla";
import { FilterFieldType } from "@/common/ts/filters";
import { appCommonConfig } from "@/config/config";
import { apiClient } from "@/core/api/ApiClient";
import { AppPermission, DataUpdatesHubClientMethodName, EntityType } from "@/core/api/generated";
import { TeslaConnectionDto, TeslaConnectionGetPaginatedDto } from "@/core/api/generated/v0.1-demo";

import UserInline from "../../User/UserInline";
import TeslaConnectionMenu from "../TeslaConnectionMenu";

const defaultDisplayProps = {
  header: true,
  filters: true,
  create: true,
  edit: true,
  delete: true,
  viewVariant: ViewLayoutVariant.Page,
};

const defaultPropagatedDeps = {
  refetch: {},
};

export type TeslaConnectionPaginatedListPropagatedDepsMap = typeof defaultPropagatedDeps;

export interface TeslaConnectionPaginatedListOwnProps {
  displayProps?: Partial<typeof defaultDisplayProps>;
  propagatedDeps?: PropagatedDeps<TeslaConnectionPaginatedListPropagatedDepsMap>;
  sx?: SxProps<Theme>;
}

export type TeslaConnectionPaginatedListProps = TeslaConnectionPaginatedListOwnProps;

export default function TeslaConnectionPaginatedList({
  displayProps,
  propagatedDeps,
  sx,
}: TeslaConnectionPaginatedListProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const { enqueueSnackbar } = useAppSnackbar();
  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<TeslaConnectionGetPaginatedDto>({
    statePersistence: {
      persistenceKey: EntityType.TeslaConnection,
    },
  });

  const paginatedTeslaConnectionsRequest = useApiRequest(
    apiClient.teslaConnectionsApi.apiV01DemoProvidersTeslaConnectionsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      teslaConnectionGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps, propagatedDeps?.depsMap["refetch"]],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const paginatedTeslaConnections = useMemo(
    () => paginatedTeslaConnectionsRequest?.data,
    [paginatedTeslaConnectionsRequest.data],
  );

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.TeslaConnection)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedTeslaConnectionsRequest.handleEntityChanged(data);
    },
  });

  return (
    <ViewLayoutV2
      displayProps={displayProps}
      header={
        displayProps?.header && (
          <SimpleViewPageHeader
            title='Tesla connections'
            primaryActions={
              displayProps?.create && (
                <AuthorizedElement permissions={[AppPermission.TeslaConnectionManage]}>
                  <ConfirmationModalWithTrigger
                    trigger={
                      <Button variant='contained' color='primary' startIcon={<AppIcon of='add' />}>
                        Connect new Tesla account
                      </Button>
                    }
                    title='Connect new Tesla account?'
                    body={({ error }) => {
                      return (
                        <DialogContentText>
                          <Box>
                            {`You will be redirected to Tesla web site where you need to login with your Tesla account and grant access to the ${appCommonConfig.appName} app.`}
                          </Box>
                          <Box>{`Please check all the listed permissions so the connection works properly.`}</Box>
                        </DialogContentText>
                      );
                    }}
                    confirmButtonText='Connect'
                    onConfirm={async () => {
                      enqueueSnackbar("Redirecting to Tesla web site...", { variant: "success" });
                      teslaService.loginAsUserWithRedirect({
                        appState: {
                          redirectUrl: UrlHelper.getCurrentUrl(),
                        },
                      });
                    }}
                  />
                </AuthorizedElement>
              )
            }
          />
        )
      }
    >
      <DataTabular<TeslaConnectionDto>
        columns={[
          {
            field: getTypedPath<TeslaConnectionDto>().id.$path,
            title: "ID",
            width: 250,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.id,
            filters: {
              fieldType: FilterFieldType.Id,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<TeslaConnectionDto>().userId.$path,
            title: "User",
            width: 300,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) => <UserInline entityId={item.userId} entity={undefined} />,
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
          {
            field: getTypedPath<TeslaConnectionDto>().userEmail.$path,
            title: "User email",
            width: 300,
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.userEmail,
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<TeslaConnectionDto>().vehicles.$path,
            title: "Vehicles",
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) => (
              <TableCellContentOfCount
                count={item.vehicles?.length}
                popoverContent={
                  <Stack spacing={1}>
                    <AppTypography variant='subtitle1'>VINS:</AppTypography>
                    <Stack spacing={1}>
                      {item.vehicles?.map((x, i) => <Box key={i}>{x.vin}</Box>)}
                    </Stack>
                  </Stack>
                }
              />
            ),
          },
          {
            field: getTypedPath<TeslaConnectionDto>().vehiclesUpdatedAt.$path,
            title: "Vehicles updated at",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.vehiclesUpdatedAt ? (
                <Datetime datetime={item.vehiclesUpdatedAt} withDurationFromNow />
              ) : (
                "-"
              ),
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<TeslaConnectionDto>().tokens.type.$path,
            title: "Auth token type",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.tokens?.type ? (
                <InlineApiEnumValue type='TeslaAuthTokenType' value={item.tokens?.type} />
              ) : (
                "-"
              ),
            filters: {
              fieldType: FilterFieldType.Enum,
              isUseDefaultOperators: true,
              fieldTypeMeta: {
                enum: {
                  enumName: ApiEnumName.TeslaAuthTokenType,
                },
              },
            },
          },
          {
            field: getTypedPath<TeslaConnectionDto>().tokens.refreshedAt.$path,
            title: "Auth token refreshed at",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.tokens?.refreshedAt ? (
                <Datetime datetime={item.tokens?.refreshedAt} withDurationFromNow />
              ) : (
                "-"
              ),
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<TeslaConnectionDto>().tokens.refreshAccessTokenAfter.$path,
            title: "Auth token next refresh after",
            width: 240,
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.createdAt ? (
                <Datetime datetime={item.tokens?.refreshAccessTokenAfter} withDurationFromNow />
              ) : (
                "-"
              ),
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<TeslaConnectionDto>().createdAt.$path,
            title: "Created at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.createdAt ? <Datetime datetime={item.createdAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<TeslaConnectionDto>().updatedAt.$path,
            title: "Updated at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.updatedAt ? <Datetime datetime={item.updatedAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<TeslaConnectionDto>().createdBy.$path,
            title: "Created by",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.createdBy ? <InlineUser userId={item.createdBy} withAvatar /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
          {
            field: getTypedPath<TeslaConnectionDto>().updatedBy.$path,
            title: "Updated by",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.updatedBy ? <InlineUser userId={item.updatedBy} withAvatar /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
        ]}
        rows={paginatedTeslaConnections?.items}
        isLoading={paginatedTeslaConnectionsRequest.isLoading}
        getRowId={(item) => item.id!}
        rowTo={(item) => ROUTE_PATH.MANAGEMENT_INTEGRATION_TESLA_CONNECTION_VIEW(item.id)}
        renderRowAction={({ item }) => (
          <TeslaConnectionMenu
            entity={item}
            onDelete={() => paginatedTeslaConnectionsRequest.refetch()}
            onUpdate={() => paginatedTeslaConnectionsRequest.refetch()}
            displayProps={{
              actions: {
                refreshVehicles: true,
                refreshAuthTokens: true,
                delete: displayProps?.delete || false,
              },
            }}
          />
        )}
        statePersistence={commonRequestParams.dataTabularProps.statePersistence}
        pagination={commonRequestParams.dataTabularProps.pagination}
        sort={commonRequestParams.dataTabularProps.sort}
        quickFilter={commonRequestParams.dataTabularProps.quickFilter}
        filters={commonRequestParams.dataTabularProps.filters}
        refetch={commonRequestParams.dataTabularProps.refetch}
      />
    </ViewLayoutV2>
  );
}
