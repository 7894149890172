import {
  Box,
  Button,
  Chip,
  IconButton,
  MenuItem,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useMemo, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import InlineUser from "@/App/MainAppView/components/User/InlineUser";
import AppTooltip from "@/common/components/AppTooltip";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppIconButton from "@/common/components/Button/AppIconButton";
import DataExportModal from "@/common/components/DataExport/DataExportModal";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import DatetimeRange from "@/common/components/Datetime/DatetimeRange";
import EntityTagsInfoDisplay from "@/common/components/EntityInfo/EntityTagsInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import AppTypography from "@/common/components/Text/AppTypography";
import { ROUTE_PATH } from "@/common/constants/routing";
import { FilterCatalog } from "@/common/filters/filterCatalog";
import { EntityHelper } from "@/common/helpers/entity";
import { TagStaticFilterInputDto, TagsHelper } from "@/common/helpers/entity/tags";
import { getTypedPath } from "@/common/helpers/typedPath";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { PropagatedDeps } from "@/common/hooks/render/usePropagatedDeps";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { ApiEnumName, enumService } from "@/common/services/enum";
import { FilterFieldType } from "@/common/ts/filters";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  ContractDto,
  ContractFilterType,
  ContractGetPaginatedDto,
  DataUpdatesHubClientMethodName,
  EntityType,
  ExportEntityType,
  FilterDefinitionDto,
  GeneralAttachedTagsInputDto,
  TagEntityType,
} from "@/core/api/generated";

import AssetSubscriptionInline from "../../AssetSubscription/AssetSubscriptionInline";
import CustomerInline from "../../Customer/CustomerInline";
import AffiliationInfoDisplay from "../../EntityAffiliation/AffiliationInfoDisplay";
import GeneralAttachedTagsOfEntitiesEditModal from "../../General/GeneralTag/GeneralAttachedTagsOfEntitiesEditModal";
import TenantStructureMembershipOfEntityEditModal from "../../Membership/TenantStructureMembershipOfEntityEditModal";
import VehicleInline from "../../Vehicle/VehicleInline";
import ContractMenu from "../ContractMenu";
import ContractsDeleteModal from "../ContractsDeleteModal";

const defaultDisplayProps = {
  header: true,
  filters: true,
  counters: true,
  create: true,
  export: true,
  edit: true,
  delete: true,
  viewVariant: ViewLayoutVariant.Page,
};

enum BulkActionFlags {
  UpdateTenantStructureMembership = "UpdateTenantStructureMembership",
  UpdateTags = "UpdateTags",
  Export = "Export",
  Delete = "Delete",
}
const defaultPropagatedDeps = {
  refetch: {},
};

export type ContractPaginatedListPropagatedDepsMap = typeof defaultPropagatedDeps;

export interface ContractPaginatedListOwnProps {
  displayProps?: Partial<typeof defaultDisplayProps>;
  initialValues?: {
    filterDefinitionDto?: Nil<FilterDefinitionDto>;
  };
  defaultValues?: {
    limit?: number;
    vehicleId?: string;
    customerId?: string;
  };
  propagatedDeps?: PropagatedDeps<ContractPaginatedListPropagatedDepsMap>;
  sx?: SxProps<Theme>;
}

export type ContractPaginatedListProps = ContractPaginatedListOwnProps;

type ContractGetPaginatedInputDto = Omit<ContractGetPaginatedDto, "tags"> & {
  tags?: TagStaticFilterInputDto;
};
export default function ContractPaginatedList({
  displayProps,
  initialValues,
  defaultValues,
  propagatedDeps,
  sx,
}: ContractPaginatedListProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const history = useHistory();
  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<ContractGetPaginatedInputDto>({
    statePersistence: {
      persistenceKey: EntityType.Contract,
    },
    initialValues: {
      filterDefinitionDto: initialValues?.filterDefinitionDto || undefined,
    },
    defaultValues: {
      limit: defaultValues?.limit,
      params: {
        filterType: ContractFilterType.All,
        ...defaultValues,
      },
    },
    initParams: (filterDefinition) => {
      const tagFilter = filterDefinition.filterDefinition?.items.find(
        (x) => x.field === getTypedPath<ContractDto>().tags.$path,
      );

      return {
        tags: {
          operator: tagFilter?.operator,
          query: tagFilter?.value as GeneralAttachedTagsInputDto,
        } as TagStaticFilterInputDto,
      };
    },
  });

  const [isDataExportModalOpened, setIsDataExportModalOpened] = useState(false);

  const isCountersVisible = displayProps?.counters && !commonRequestParams.isAnyFilters;

  const countersRequest = useApiRequest(
    apiClient.contractsApi.apiV1ContractsCountersGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      contractGetCountersDto: {},
    },
    {
      deps: [propagatedDeps?.depsMap["refetch"]],
    },
  );
  const counters = countersRequest?.data;

  const paginatedContractsRequest = useApiRequest(
    apiClient.contractsApi.apiV1ContractsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      contractGetPaginatedDto: {
        ..._.omit(commonRequestParams.params, "tags"),
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        tags:
          commonRequestParams.filterDefinition?.items
            .filter((x) => x.field === getTypedPath<ContractDto>().tags.$path)
            .map((x) => TagsHelper.mapFromFilter(x))[0] || undefined,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [
        ...commonRequestParams.deps,
        commonRequestParams.filterDefinition,
        propagatedDeps?.depsMap["refetch"],
      ],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const paginatedContracts = useMemo(
    () => paginatedContractsRequest?.data,
    [paginatedContractsRequest.data],
  );
  const contractIds = useMemo(
    () => paginatedContracts?.items?.map((x) => x.id!) || [],
    [paginatedContracts],
  );

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.Contract)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedContractsRequest.handleEntityChanged(data);
    },
  });

  return (
    <ViewLayoutV2
      displayProps={displayProps}
      header={
        displayProps?.header && (
          <SimpleViewPageHeader
            title='Contracts'
            primaryActions={
              displayProps?.create && (
                <AuthorizedElement permissions={[AppPermission.ContractManage]}>
                  <Button
                    variant='contained'
                    color='primary'
                    startIcon={<AppIcon of='add' />}
                    component={RouterLink}
                    to={ROUTE_PATH.CONTRACT_CREATE()}
                  >
                    Create new contract
                  </Button>
                </AuthorizedElement>
              )
            }
            secondaryActions={
              displayProps?.export && (
                <AuthorizedElement permissions={[AppPermission.ExportManage]}>
                  <MenuWithTrigger
                    withAuthCloseOnClick
                    trigger={
                      <IconButton>
                        <AppIcon of='moreVert' />
                      </IconButton>
                    }
                  >
                    <MenuItem onClick={() => setIsDataExportModalOpened(true)}>
                      <AppIcon of='export' fontSize='small' sx={{ mr: 1 }} />
                      <Typography>Export contracts</Typography>
                    </MenuItem>
                  </MenuWithTrigger>
                </AuthorizedElement>
              )
            }
          />
        )
      }
    >
      <DataTabular<ContractDto>
        tabs={{
          value: commonRequestParams.params?.filterType,
          onChange: (e, newValue) => commonRequestParams.setOneParam2({ filterType: newValue }),
          tabs: enumService
            .getEnumValues("ContractFilterType", {
              except: [ContractFilterType.None],
            })
            .map((value) => ({
              label: (
                <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
                  <Box>
                    <InlineApiEnumValue type='ContractFilterType' value={value} />
                  </Box>
                  {isCountersVisible && counters?.countByFilterTypeMap && (
                    <Chip
                      variant='outlined'
                      size='small'
                      color='default'
                      label={counters.countByFilterTypeMap[value] ?? 0}
                    />
                  )}
                </Stack>
              ),
              value,
            })),
        }}
        columns={[
          {
            field: getTypedPath<ContractDto>().id.$path,
            title: "ID",
            width: 250,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.id,
            filters: {
              fieldType: FilterFieldType.Id,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<ContractDto>().localNumber.$path,
            title: "Number",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => (
              <Stack direction='column' alignItems='flex-start'>
                <Box>{item.localNumber}</Box>
                {item.externalNumber && (
                  <AppTooltip variant='helpText' title='External number'>
                    <AppTypography ellipsing={{ enabled: true }} component='div' variant='body2'>
                      ({item.externalNumber})
                    </AppTypography>
                  </AppTooltip>
                )}
              </Stack>
            ),
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<ContractDto>().externalNumber.$path,
            title: "External number",
            width: 100,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.externalNumber,
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<ContractDto>().stageHistory.stage.$path,
            title: "Stage",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => (
              <InlineApiEnumValue type='ContractStage' value={item.stageHistory!.stage} />
            ),
            filters: {
              fieldType: FilterFieldType.Enum,
              isUseDefaultOperators: true,
              fieldTypeMeta: {
                enum: {
                  enumName: ApiEnumName.ContractStage,
                },
              },
            },
          },
          {
            field: getTypedPath<ContractDto>().vehicle.id.$path,
            title: "Vehicle",
            width: 300,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) => <VehicleInline entity={item.vehicle} />,
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfVehicle(),
            },
          },
          {
            field: getTypedPath<ContractDto>().customer.id.$path,
            title: "Customer",
            width: 300,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) => <CustomerInline entity={item.customer} />,
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfCustomer(),
            },
          },
          {
            field: getTypedPath<ContractDto>().assetSubscription.id.$path,
            title: "Asset subscription",
            width: 300,
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.assetSubscription ? (
                <AssetSubscriptionInline entity={item.assetSubscription} />
              ) : (
                "-"
              ),
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfAssetSubscription(),
            },
          },
          {
            field: getTypedPath<ContractDto>().startsAt.$path,
            title: "Starts at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.startsAt ? <Datetime datetime={item.startsAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<ContractDto>().endsAt.$path,
            title: "Ends at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.endsAt ? <Datetime datetime={item.endsAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: "duration",
            title: "Duration",
            width: 100,
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) => (
              <DatetimeRange
                direction='column'
                variant='compact'
                range={{
                  startsAt: item.startsAt,
                  endsAt: item.endsAt,
                }}
                withDuration
              />
            ),
          },
          {
            field: getTypedPath<ContractDto>().type.$path,
            title: "Type",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => <InlineApiEnumValue type='ContractType' value={item.type} />,
            filters: {
              fieldType: FilterFieldType.Enum,
              isUseDefaultOperators: true,
              fieldTypeMeta: {
                enum: {
                  enumName: ApiEnumName.ContractType,
                },
              },
            },
          },
          {
            field: getTypedPath<ContractDto>().allocationStatus.$path,
            title: "Allocation status",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => (
              <InlineApiEnumValue type='AllocationStatus' value={item.allocationStatus} />
            ),
            filters: {
              fieldType: FilterFieldType.Enum,
              isUseDefaultOperators: true,
              fieldTypeMeta: {
                enum: {
                  enumName: ApiEnumName.AllocationStatus,
                },
              },
            },
          },
          {
            field: getTypedPath<ContractDto>().notes.$path,
            title: "Notes",
            width: 100,
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) => item.notes || "-",
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<ContractDto>().createdAt.$path,
            title: "Created at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.createdAt ? <Datetime datetime={item.createdAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<ContractDto>().updatedAt.$path,
            title: "Updated at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.updatedAt ? <Datetime datetime={item.updatedAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<ContractDto>().createdBy.$path,
            title: "Created by",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.createdBy ? <InlineUser userId={item.createdBy} withAvatar /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
          {
            field: getTypedPath<ContractDto>().updatedBy.$path,
            title: "Updated by",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.updatedBy ? <InlineUser userId={item.updatedBy} withAvatar /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
          {
            field: "affiliation",
            title: "Affiliation",
            description: "Affiliation to departments & locations",
            width: 120,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            isToDisabled: true,
            renderCell: (item) => (
              <AffiliationInfoDisplay
                variant='compact'
                withoutTenant
                tenantId={item.tenantId}
                departmentIds={item.departmentIds}
                locationIds={item.locationIds}
              />
            ),
          },
          {
            field: getTypedPath<ContractDto>().tags.$path,
            title: "Tags",
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            isColumnMenuDisabled: false,
            isToDisabled: true,
            renderCell: (item) => (
              <EntityTagsInfoDisplay
                entityType={TagEntityType.Contract}
                entity={item}
                noDataPlaceholder='-'
                edit={{
                  onSaved: (newValue) =>
                    paginatedContractsRequest.updateData((data) => {
                      data.items?.forEach((item2) => {
                        if (item2.id === item.id) {
                          item2.tags = newValue || undefined;
                        }
                      });
                    }),
                }}
              />
            ),
            filters: {
              fieldType: FilterFieldType.Arbitrary,
              isUseSingleFilter: true,
              operators: FilterCatalog.getOperatorsForTagsField(),
            },
          },
        ]}
        rows={paginatedContracts?.items}
        isLoading={paginatedContractsRequest.isLoading}
        getRowId={(item) => item.id!}
        rowTo={(item) => ROUTE_PATH.CONTRACT_VIEW(item.id)}
        renderRowAction={({ item }) => (
          <ContractMenu
            entity={item}
            onDelete={() => paginatedContractsRequest.refetch()}
            onUpdate={() => paginatedContractsRequest.refetch()}
            displayProps={{
              actions: {
                assessments: true,
                edit: displayProps?.edit || false,
                delete: displayProps?.delete || false,
                customerCommunication: false,
                contractRemainders: false,
                eventLog: false,
                //quick action
                createVisualInspection: false,
                createDamageDetection: false,
                createDamageCostEvaluation: false,
                createRepairOperation: false,
                createAccessoryCheck: false,
              },
            }}
          />
        )}
        statePersistence={commonRequestParams.dataTabularProps.statePersistence}
        pagination={commonRequestParams.dataTabularProps.pagination}
        sort={commonRequestParams.dataTabularProps.sort}
        quickFilter={commonRequestParams.dataTabularProps.quickFilter}
        filters={commonRequestParams.dataTabularProps.filters}
        refetch={commonRequestParams.dataTabularProps.refetch}
        bulkActions={{
          enabled: true,
          definition: BulkActionFlags,
          actionTriggers: ({ currentAction, startAction, selectedIds }) => (
            <>
              <AppIconButton
                tooltipProps={{ title: "Update affiliation" }}
                onClick={() => startAction(BulkActionFlags.UpdateTenantStructureMembership)}
              >
                <AppIcon of='department' />
              </AppIconButton>
              <AppIconButton
                tooltipProps={{ title: "Update tags" }}
                onClick={() => startAction(BulkActionFlags.UpdateTags)}
              >
                <AppIcon of='tag' />
              </AppIconButton>
              <AppIconButton
                tooltipProps={{ title: "Delete" }}
                onClick={() => startAction(BulkActionFlags.Delete)}
              >
                <AppIcon of='delete' />
              </AppIconButton>
              <AppIconButton
                tooltipProps={{ title: "Export" }}
                onClick={() => startAction(BulkActionFlags.Export)}
              >
                <AppIcon of='export' />
              </AppIconButton>
            </>
          ),
          actionHandlers: ({ selectedIds, currentAction, cancelAction, completeAction }) => {
            const selectedEntities = EntityHelper.filterEntitiesByIds(
              paginatedContracts?.items || [],
              selectedIds as string[],
            );
            return (
              <>
                <TenantStructureMembershipOfEntityEditModal
                  open={currentAction === BulkActionFlags.UpdateTenantStructureMembership}
                  onClose={() => cancelAction()}
                  tenantStructureMembershipOfEntityEditProps={{
                    entityType: EntityType.Contract,
                    entities: selectedEntities,
                    onSave: () => {
                      completeAction();
                      paginatedContractsRequest.refetch();
                    },
                  }}
                />
                <GeneralAttachedTagsOfEntitiesEditModal
                  tagEntityType={TagEntityType.Contract}
                  entities={selectedEntities}
                  open={currentAction === BulkActionFlags.UpdateTags}
                  onClose={() => cancelAction()}
                  onSaved={() => {
                    completeAction();
                    paginatedContractsRequest.refetch();
                  }}
                />
                <ContractsDeleteModal
                  entities={EntityHelper.filterEntitiesByIds(
                    paginatedContracts?.items || [],
                    selectedIds as string[],
                  )}
                  open={currentAction === BulkActionFlags.Delete}
                  onClose={() => cancelAction()}
                  onDelete={() => {
                    completeAction();
                    paginatedContractsRequest.refetch();
                  }}
                />
                <DataExportModal
                  open={currentAction === BulkActionFlags.Export}
                  onClose={() => cancelAction()}
                  exportDataProps={{
                    entityType: ExportEntityType.Contract,
                    ids: selectedIds as string[],
                    onExportDone: completeAction,
                    displayProps: {
                      mode: false,
                    },
                  }}
                />
              </>
            );
          },
        }}
      />

      {/* Export data modal */}
      <DataExportModal
        open={isDataExportModalOpened}
        onClose={() => setIsDataExportModalOpened(false)}
        exportDataProps={{
          entityType: ExportEntityType.Contract,
          ids: contractIds,
          onExportDone: () => {
            setIsDataExportModalOpened(false);
          },
        }}
      />
    </ViewLayoutV2>
  );
}
