import { apiClient } from "@/core/api/ApiClient";
import { AccessoryCheckDto, EntityType } from "@/core/api/generated";

import BaseEntityDeleteModal, {
  BaseEntityDeleteModalInheritableProps,
} from "../components/BaseEntityDeleteModal";

export interface OwnProps {
  entity: AccessoryCheckDto;
}

type Props = OwnProps & BaseEntityDeleteModalInheritableProps;

export default function AccessoryCheckDeleteModal({ entity, ...otherProps }: Props) {
  return (
    <BaseEntityDeleteModal
      entityType={EntityType.AccessoryCheck}
      entity={entity}
      entityId={entity.id}
      deleteFunc={async (params) => {
        await apiClient.accessoryChecksApi.apiV1AccessoryChecksAccessoryCheckIdDelete({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          accessoryCheckId: params.entityId,
          isDeleteCascade: params.isDeleteCascade,
        });
      }}
      {...otherProps}
    />
  );
}
