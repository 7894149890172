import { useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

interface AppHistory extends Omit<ReturnType<typeof useHistory>, "goBack"> {
  goBack: (countOfTryingGoBack?: number) => void;
}
export function useAppHistory(): AppHistory {
  const history = useHistory();
  const location = useLocation();

  const prevUrlRef = useRef<string | null>(null);
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    prevUrlRef.current = location.pathname;
  }, [location.pathname]);

  // Using setInterval to handle an edge case:
  // countOfTryingGoBack defines a limit for goBack() attempts to prevent infinite loops.
  // Example edge case: if the "prev" page redirects twice in a row to the same URL,
  // a single goBack() call would leave the user on the same page they are trying to exit.
  // This interval-based trick repeatedly calls goBack() until the URL changes
  // or the maximum number of attempts (countOfTryingGoBack) is reached.

  // The 100 ms delay is a trade-off between responsiveness and performance:
  // - Short enough to quickly detect URL changes after goBack().
  // - Long enough to avoid excessive calls, especially in cases with slower transitions
  //   (e.g., network delays or heavy applications).

  const goBack = (countOfTryingGoBack: number = 3) => {
    const initialUrl = location.pathname;
    let attempts = 0;

    const interval = setInterval(() => {
      if (location.pathname !== initialUrl || attempts >= countOfTryingGoBack) {
        clearInterval(interval);
        intervalIdRef.current = null;
      } else {
        history.goBack();
      }
      attempts += 1;
    }, 100);

    intervalIdRef.current = interval;
  };

  useEffect(() => {
    return () => {
      if (intervalIdRef.current) clearInterval(intervalIdRef.current);
    };
  }, []);

  return {
    ...history,
    goBack,
  };
}
