import { Stack, SxProps, Theme } from "@mui/material";
import _ from "lodash";

import { ContractDto } from "@/core/api/generated";

import BooleanValue from "../../Form/Display/BooleanValue";
import FieldValue from "../../Form/Display/FieldValue";

export interface OwnProps {
  contract: ContractDto;
  sx?: SxProps<Theme>;
}

export type ContractReminderSettingsDisplayProps = OwnProps;

export default function ContractReminderSettingsDisplay({
  contract,
  sx,
}: ContractReminderSettingsDisplayProps) {
  return (
    <Stack spacing={1} direction='row' sx={sx}>
      <FieldValue
        variant='filled'
        direction='column'
        label='Require contract start date'
        isEmpty={_.isNil(contract.settings?.isRequireStartDate)}
      >
        <BooleanValue value={contract.settings?.isRequireStartDate} />
      </FieldValue>

      <FieldValue
        variant='filled'
        direction='column'
        label='Require contract end date'
        isEmpty={_.isNil(contract.settings?.isRequireEndDate)}
      >
        <BooleanValue value={contract.settings?.isRequireEndDate} />
      </FieldValue>

      <FieldValue
        variant='filled'
        direction='column'
        label='Enable contract Check-In/Check-Out'
        isEmpty={_.isNil(contract.settings?.isCheckEnabled)}
      >
        <BooleanValue value={contract.settings?.isCheckEnabled} />
      </FieldValue>

      <FieldValue
        variant='filled'
        direction='column'
        label='Require visual inspection before check-out'
        isEmpty={_.isNil(contract.settings?.isRequireVisualInspectionBeforeCheckOut)}
      >
        <BooleanValue value={contract.settings?.isRequireVisualInspectionBeforeCheckOut} />
      </FieldValue>

      <FieldValue
        variant='filled'
        direction='column'
        label='Require visual inspection before check-in'
        isEmpty={_.isNil(contract.settings?.isRequireVisualInspectionBeforeCheckIn)}
      >
        <BooleanValue value={contract.settings?.isRequireVisualInspectionBeforeCheckIn} />
      </FieldValue>
    </Stack>
  );
}
