import { Stack } from "@mui/material";
import { useEffect, useState } from "react";

import { DatetimeHelper } from "@/common/helpers/datetime";
import { WidgetDataBaseDto, WidgetDataGetBaseDto } from "@/core/api/generated";

import AppIconButton from "../../Button/AppIconButton";
import AppIcon from "../../Icons/AppIcon";
import AppTypography from "../../Text/AppTypography";

const defaultDisplayProps = {
  lastComputedAt: true,
  refetch: false,
  recompute: true,
};

interface Props {
  data: Nil<WidgetDataBaseDto>;
  displayProps?: Partial<typeof defaultDisplayProps>;
  onRefetch?: (requestParams: Partial<WidgetDataGetBaseDto>) => void | Promise<void>;
  onRecompute?: (requestParams: Partial<WidgetDataGetBaseDto>) => void | Promise<void>;
}

export default function WidgetTitleDefaultActions({
  data,
  displayProps,
  onRefetch,
  onRecompute,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const [computedAtHumanized, setComputedAtHumanized] = useState(
    DatetimeHelper.humanizeDateRangeDurationFromNow(data?.computedAt),
  );

  const refreshLastComputedTextInterval = 30_000;
  useEffect(() => {
    setComputedAtHumanized(DatetimeHelper.humanizeDateRangeDurationFromNow(data?.computedAt));

    const intervalId = setInterval(() => {
      setComputedAtHumanized(DatetimeHelper.humanizeDateRangeDurationFromNow(data?.computedAt));
    }, refreshLastComputedTextInterval);

    return () => {
      clearInterval(intervalId);
    };
  }, [data?.computedAt]);

  return (
    <Stack direction='row' spacing={1} sx={{ justifyContent: "flex-start", alignItems: "center" }}>
      {displayProps?.lastComputedAt && computedAtHumanized && (
        <AppTypography variant='caption2'>Last computed {computedAtHumanized || "-"}</AppTypography>
      )}

      {displayProps?.refetch && (
        <AppIconButton
          variant='text'
          color='inherit'
          size='small'
          tooltipProps={{ title: "Refresh" }}
          isAutoTrackLoading={false}
          onClick={async () => {
            onRefetch &&
              (await onRefetch({
                isRecompute: false,
              }));
          }}
        >
          <AppIcon of='refresh' />
        </AppIconButton>
      )}

      {displayProps?.recompute && (
        <AppIconButton
          variant='text'
          color='inherit'
          size='small'
          tooltipProps={{ title: "Recompute to get actual data" }}
          isAutoTrackLoading={false}
          onClick={async () => {
            onRecompute &&
              (await onRecompute({
                isRecompute: true,
              }));
          }}
        >
          <AppIcon of='recompute' />
        </AppIconButton>
      )}
    </Stack>
  );
}
