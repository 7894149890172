import { Box, Button, IconButton, ListItemIcon, ListItemText, Stack } from "@mui/material";
import { useCallback, useState } from "react";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import AuthorizedMenuItem from "@/common/components/Auth/AuthorizedMenuItem";
import PoolItemCreateManyModal from "@/common/components/Entity/PoolItem/PoolItemCreateManyModal";
import PoolItemPaginatedList, {
  PoolItemPaginatedListPropagatedDepsMap,
} from "@/common/components/Entity/PoolItem/PoolItemPaginatedList";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import { ROUTE_PATH } from "@/common/constants/routing";
import { usePropagatedDeps } from "@/common/hooks/render/usePropagatedDeps";
import { AppPermission, PoolDto } from "@/core/api/generated";

import PoolItemDeleteAllModal from "../../../PoolItem/PoolItemDeleteAllModal";
import PoolAvailabilityDisplay, {
  PoolAvailabilityDisplayPropagatedDepsMap,
} from "../../PoolAvailabilityDisplay";

interface Props {
  pool: PoolDto;
}

export default function ItemsTabContent({ pool }: Props) {
  const listPropagatedDeps = usePropagatedDeps<PoolItemPaginatedListPropagatedDepsMap>();
  const availabilityPropagatedDeps = usePropagatedDeps<PoolAvailabilityDisplayPropagatedDepsMap>();

  const [isAddPoolItemsModalOpen, setIsAddPoolItemsModelOpen] = useState(false);
  const [isDeleteAllPoolItemsModalOpen, setIsDeleteAllPoolItemsModalOpen] = useState(false);

  const onPooItemsChange = useCallback(() => {
    listPropagatedDeps.trigger("refetch");
    availabilityPropagatedDeps.trigger("refetch");
  }, [listPropagatedDeps]);

  return (
    <PageTabContent>
      <ViewLayoutV2
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={
          <PageTabHeader
            title={"Pool items"}
            primaryAction={
              <Button
                variant='contained'
                color='primary'
                onClick={() => setIsAddPoolItemsModelOpen(true)}
              >
                Add pool items
              </Button>
            }
            secondaryAction={
              <MenuWithTrigger
                withAuthCloseOnClick
                trigger={
                  <IconButton sx={{ ml: "auto" }}>
                    <AppIcon of='moreVert' />
                  </IconButton>
                }
              >
                <AuthorizedMenuItem
                  permissions={[AppPermission.FleetAppAccess, AppPermission.PoolRead]}
                  component={AppLink}
                  to={ROUTE_PATH.POOL_ITEMS(pool.id)}
                >
                  <ListItemIcon>
                    <AppIcon of='view' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>View all items</ListItemText>
                </AuthorizedMenuItem>
                <AuthorizedMenuItem
                  permissions={[AppPermission.FleetAppAccess, AppPermission.PoolManage]}
                  onClick={() => setIsDeleteAllPoolItemsModalOpen(true)}
                >
                  <ListItemIcon>
                    <AppIcon of='delete' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>Delete all items</ListItemText>
                </AuthorizedMenuItem>
              </MenuWithTrigger>
            }
          />
        }
      >
        <Stack direction='column' spacing={2}>
          <Box>
            <PoolAvailabilityDisplay
              poolId={pool?.id}
              availability={undefined}
              isLoad
              propagatedDeps={availabilityPropagatedDeps}
            />
          </Box>

          <PoolItemPaginatedList
            poolId={undefined}
            pool={pool}
            displayProps={{
              viewVariant: ViewLayoutVariant.Tab,
              breadcrumbs: false,
              header: false,
              filters: false,
            }}
            propagatedDeps={listPropagatedDeps}
            onChange={() => {
              onPooItemsChange();
            }}
          />
        </Stack>

        {/* Create many pool items */}
        <PoolItemCreateManyModal
          createManyProps={{
            defaultValues: {
              poolId: pool.id!,
            },
            onSave: () => {
              setIsAddPoolItemsModelOpen(false);
              onPooItemsChange();
            },
          }}
          open={isAddPoolItemsModalOpen}
          onClose={() => {
            setIsAddPoolItemsModelOpen(false);
          }}
        />

        {/* Delete all pool items */}
        <PoolItemDeleteAllModal
          pool={pool}
          open={isDeleteAllPoolItemsModalOpen}
          onClose={() => {
            setIsDeleteAllPoolItemsModalOpen(false);
          }}
          onDeleted={() => {
            setIsDeleteAllPoolItemsModalOpen(false);
            onPooItemsChange();
          }}
        />
      </ViewLayoutV2>
    </PageTabContent>
  );
}
