import { VehicleMileageDto } from "@/core/api/generated";

import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import AppTypography from "../../Text/AppTypography";

interface Props {
  value?: VehicleMileageDto;
}

export default function VehicleMileageInline({ value }: Props) {
  return (
    <>
      {value && (
        <AppTypography>
          {value.mileage} <InlineApiEnumValue type='MileageUnitType' value={value.unit} />
        </AppTypography>
      )}
      {!value && "-"}
    </>
  );
}
