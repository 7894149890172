import { apiClient } from "@/core/api/ApiClient";
import { EntityType, SupplierDto } from "@/core/api/generated";

import BaseEntityDeleteModal, {
  BaseEntityDeleteModalInheritableProps,
} from "../components/BaseEntityDeleteModal";

export interface OwnProps {
  entity: SupplierDto;
}

type Props = OwnProps & BaseEntityDeleteModalInheritableProps;

export default function SupplierDeleteModal({ entity, ...otherProps }: Props) {
  return (
    <BaseEntityDeleteModal
      entityType={EntityType.Supplier}
      entity={entity}
      entityId={entity.id}
      deleteFunc={async (params) => {
        await apiClient.suppliersApi.apiV1SuppliersSupplierIdDelete({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          supplierId: params.entityId,
          isDeleteCascade: params.isDeleteCascade,
        });
      }}
      {...otherProps}
    />
  );
}
