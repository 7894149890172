import { Box } from "@mui/material";
import { ReactNode } from "react";

export interface WidgetHeaderProps {
  children: ReactNode;
}

export default function WidgetHeader({ children }: WidgetHeaderProps) {
  return (
    <Box sx={{ pb: 2, mb: 1, borderBottom: (th) => `1px solid ${th.palette.divider}` }}>
      {children}
    </Box>
  );
}
