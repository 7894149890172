import { Stack } from "@mui/material";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { DamageCostEvaluationListPageLocationState } from "@/App/MainAppView/DamageCostEvaluations/DamageCostEvaluationsPage";
import { MaintenanceListPageLocationState } from "@/App/MainAppView/Maintenances/MaintenancesPage";
import { RepairOperationListPageLocationState } from "@/App/MainAppView/RepairOperations/RepairOperationsPage";
import { TenantRequestListPageLocationState } from "@/App/MainAppView/TenantToTenant/TenantRequest/TenantRequestsPage";
import { WashListPageLocationState } from "@/App/MainAppView/Washes/WashesPage";
import { WheelOperationListPageLocationState } from "@/App/MainAppView/WheelOperation/WheelOperationsPage";
import AppIcon from "@/common/components/Icons/AppIcon";
import { ROUTE_PATH } from "@/common/constants/routing";
import { WidgetHelper } from "@/common/helpers/widget";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { cast } from "@/common/ts/conversions";
import { apiClient } from "@/core/api/ApiClient";

import AppLink from "../../Link/AppLink";
import BaseWidget from "../Base/BaseWidget";
import WidgetContent from "../Base/WidgetContent";
import WidgetHeader from "../Base/WidgetHeader";
import WidgetTitle from "../Base/WidgetTitle";
import WidgetTitleDefaultActions from "../Base/WidgetTitleDefaultActions";
import CounterWidgetCard from "../General/Counter/CounterWidgetCard";
import CounterWidgetCardGrid from "../General/Counter/CounterWidgetCardGrid";
import CounterWidgetContent from "../General/Counter/CounterWidgetContent";

export default function ActiveOperationCountWidget() {
  const history = useHistory();

  const dataRequest = useApiRequest(
    apiClient.widgetDataApi.apiV1WidgetDataActiveOperationSummaryGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
    },
    {
      deps: [],
    },
  );
  const data = dataRequest?.data;

  useEffect(() => {
    return WidgetHelper.handleRefetchAndRecomputeDataRequestInEffect((requestParams) => {
      dataRequest.refetchWithNewParams({
        widgetDataGetBaseDto: {
          ...requestParams,
        },
      });
    });
  }, [dataRequest]);

  return (
    <BaseWidget>
      <WidgetHeader>
        <WidgetTitle
          icon={<AppIcon of='dataConnected' />}
          actions={
            <WidgetTitleDefaultActions
              data={data}
              onRecompute={async (requestParams) => {
                await dataRequest.refetchWithNewParams({
                  widgetDataGetBaseDto: {
                    ...requestParams,
                  },
                });
              }}
            />
          }
        >
          Active operations
        </WidgetTitle>
      </WidgetHeader>

      <WidgetContent>
        <Stack>
          <CounterWidgetCardGrid>
            <CounterWidgetCard>
              <CounterWidgetContent
                title='Damage cost evaluations'
                icon={<AppIcon of='damageCostEvaluation' />}
                // counter={data?.damageCostEvaluation?.count}
                counter={
                  <AppLink
                    to={ROUTE_PATH.DAMAGE_COST_EVALUATIONS}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(
                        ROUTE_PATH.DAMAGE_COST_EVALUATIONS,
                        cast<DamageCostEvaluationListPageLocationState>({
                          filterDefinitionDto: data?.damageCostEvaluation?.filterDefinition,
                        }),
                      );
                    }}
                  >
                    {data?.damageCostEvaluation?.count}
                  </AppLink>
                }
                isLoading={dataRequest.isLoading}
              />
            </CounterWidgetCard>

            <CounterWidgetCard>
              <CounterWidgetContent
                title='Repairs'
                icon={<AppIcon of='repairOperation' />}
                // counter={data?.repairOperation?.count}
                counter={
                  <AppLink
                    to={ROUTE_PATH.REPAIR_OPERATIONS}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(
                        ROUTE_PATH.REPAIR_OPERATIONS,
                        cast<RepairOperationListPageLocationState>({
                          filterDefinitionDto: data?.repairOperation?.filterDefinition,
                        }),
                      );
                    }}
                  >
                    {data?.repairOperation?.count}
                  </AppLink>
                }
                isLoading={dataRequest.isLoading}
              />
            </CounterWidgetCard>

            <CounterWidgetCard>
              <CounterWidgetContent
                title='Maintenance'
                icon={<AppIcon of='maintenance' />}
                // counter={data?.maintenance?.count}
                counter={
                  <AppLink
                    to={ROUTE_PATH.MAINTENANCES}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(
                        ROUTE_PATH.MAINTENANCES,
                        cast<MaintenanceListPageLocationState>({
                          filterDefinitionDto: data?.maintenance?.filterDefinition,
                        }),
                      );
                    }}
                  >
                    {data?.maintenance?.count}
                  </AppLink>
                }
                isLoading={dataRequest.isLoading}
              />
            </CounterWidgetCard>

            <CounterWidgetCard>
              <CounterWidgetContent
                title='Wash'
                icon={<AppIcon of='wash' />}
                // counter={data?.wash?.count}
                counter={
                  <AppLink
                    to={ROUTE_PATH.WASHES}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(
                        ROUTE_PATH.WASHES,
                        cast<WashListPageLocationState>({
                          filterDefinitionDto: data?.wash?.filterDefinition,
                        }),
                      );
                    }}
                  >
                    {data?.wash?.count}
                  </AppLink>
                }
                isLoading={dataRequest.isLoading}
              />
            </CounterWidgetCard>

            <CounterWidgetCard>
              <CounterWidgetContent
                title='Wheels & tires'
                icon={<AppIcon of='wheelOperation' />}
                // counter={data?.wheelOperation?.count}
                counter={
                  <AppLink
                    to={ROUTE_PATH.WHEEL_OPERATIONS}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(
                        ROUTE_PATH.WHEEL_OPERATIONS,
                        cast<WheelOperationListPageLocationState>({
                          filterDefinitionDto: data?.wheelOperation?.filterDefinition,
                        }),
                      );
                    }}
                  >
                    {data?.wheelOperation?.count}
                  </AppLink>
                }
                isLoading={dataRequest.isLoading}
              />
            </CounterWidgetCard>

            <CounterWidgetCard>
              <CounterWidgetContent
                title='Company-to-company requests'
                icon={<AppIcon of='tenantRequest' />}
                // counter={data?.tenantRequest?.count}
                counter={
                  <AppLink
                    to={ROUTE_PATH.TENANT_REQUESTS}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(
                        ROUTE_PATH.TENANT_REQUESTS,
                        cast<TenantRequestListPageLocationState>({
                          filterDefinitionDto: data?.tenantRequest?.filterDefinition,
                        }),
                      );
                    }}
                  >
                    {data?.tenantRequest?.count}
                  </AppLink>
                }
                isLoading={dataRequest.isLoading}
              />
            </CounterWidgetCard>
          </CounterWidgetCardGrid>
        </Stack>
      </WidgetContent>
    </BaseWidget>
  );
}
