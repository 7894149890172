import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { AppPermission, WheelOperationDto, WheelOperationReferenceDto } from "@/core/api/generated";

import BaseEntityLink, { BaseEntityLinkInheritableProps } from "../components/BaseEntityLink";
import WheelOperationInline, { WheelOperationInlineProps } from "./WheelOperationInline";

interface Props extends BaseEntityLinkInheritableProps {
  entity: WheelOperationDto | WheelOperationReferenceDto | null | undefined;
  entityId?: string | null;
  inlineProps?: Partial<WheelOperationInlineProps>;
}

export default function WheelOperationLink({
  entity,
  entityId,
  inlineProps,
  ...otherProps
}: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  if (!entity && !entityId) {
    return null;
  }

  return (
    <BaseEntityLink
      enabled={hasPermissions([AppPermission.FleetAppAccess, AppPermission.WheelOperationRead])}
      to={ROUTE_PATH.WHEEL_OPERATION_VIEW(entity?.id || entityId || "")}
      icon={undefined}
      content={
        <WheelOperationInline
          entity={entity}
          entityId={entityId}
          {...inlineProps}
          {...otherProps}
        />
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Wheel operation"}
    />
  );
}
