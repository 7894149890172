import { apiClient } from "@/core/api/ApiClient";
import { EntityType, NegotiationDto } from "@/core/api/generated";

import BaseEntityDeleteModal, {
  BaseEntityDeleteModalInheritableProps,
} from "../components/BaseEntityDeleteModal";

export interface OwnProps {
  entity: NegotiationDto;
}

type Props = OwnProps & BaseEntityDeleteModalInheritableProps;

export default function NegotiationDeleteModal({ entity, ...otherProps }: Props) {
  return (
    <BaseEntityDeleteModal
      entityType={EntityType.Negotiation}
      entity={entity}
      entityId={entity.id}
      deleteFunc={async (params) => {
        await apiClient.negotiationsApi.apiV1NegotiationsNegotiationIdDelete({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          negotiationId: params.entityId,
        });
      }}
      {...otherProps}
    />
  );
}
