import { Link, LinkProps, SxProps, Theme, TooltipProps } from "@mui/material";
import _ from "lodash";
import { forwardRef } from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";

import AppTooltip from "../AppTooltip";

export interface AppLinkProps extends LinkProps {
  /** If true - wraps children with the link, false - children are rendered directly. */
  enabled?: boolean;
  to: RouterLinkProps["to"] | undefined;
  sx?: SxProps<Theme>;
  /** When set, tooltip is rendered. */
  tooltipTitle?: string;
  tooltipProps?: TooltipProps;
  /** Allows to disable tooltip */
  withTooltip?: boolean;
  isInheritStyles?: boolean;
  disableLinkStyles?: boolean;
  icon?: React.ReactElement;
  children?: React.ReactNode;
}

export default forwardRef<HTMLAnchorElement, AppLinkProps>(function AppLink(
  {
    enabled = true,
    to,
    sx,
    tooltipTitle,
    tooltipProps,
    withTooltip,
    isInheritStyles,
    disableLinkStyles,
    icon,
    children,
    ...other
  }: AppLinkProps,
  ref,
) {
  const tooltipEnabled = _.isNil(withTooltip) ? !!tooltipTitle : withTooltip;

  if (!enabled) {
    return children;
  }

  return (
    <AppTooltip enabled={tooltipEnabled} {...tooltipProps} title={tooltipTitle}>
      <Link
        ref={ref}
        component={to ? RouterLink : "a"}
        to={to}
        fontSize='inherit'
        sx={{
          display: "block",
          minWidth: 0,
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          textDecorationColor: "inherit",
          ...(isInheritStyles
            ? {
                color: "inherit",
              }
            : undefined),
          ...(disableLinkStyles
            ? {
                color: "inherit",
                textDecoration: "inherit",
                textDecorationColor: "inherit",
              }
            : undefined),
          ...(sx || {}),
        }}
        {...other}
      >
        {icon && <span style={{ marginRight: "4px" }}>{icon} </span>}
        {children}
      </Link>
    </AppTooltip>
  );
});
