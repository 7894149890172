import { Alert, Button, DialogProps, Stack, TypographyProps } from "@mui/material";
import { useMemo, useState } from "react";

import PageTabsV2 from "@/App/Layouts/PageBody/PageTabsV2";
import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { ContractHelper } from "@/common/helpers/entity/contract";
import { StringHelper } from "@/common/helpers/string";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useCommonViewParams } from "@/common/hooks/layout/useCommonViewParams";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { useAppHistory } from "@/common/hooks/useAppHistory";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  ContractDto,
  ContractStage,
  DataUpdatesHubClientMethodName,
  EntityType,
  TagEntityType,
} from "@/core/api/generated";

import AssetSubscriptionReallocateAssetModal from "../../AssetSubscription/AssetSubscriptionReallocateAssetModal";
import GeneralAttachedTagsDisplay from "../../General/GeneralTag/GeneralAttachedTagsDisplay";
import VisualInspectionCreateUpdateModal from "../../VisualInspection/VisualInspectionCreateUpdateModal";
import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import ContractCustomerCommunicationInfoModal from "../ContractCustomerCommunicationInfoModal";
import ContractMenu from "../ContractMenu";
import ContractReallocateVehicleModal from "../ContractReallocateVehicleModal";
import ContractVehicleCheckInModal from "../ContractVehicleCheckInModal";
import ContractVehicleCheckOutModal from "../ContractVehicleCheckOutModal";
import AssetSubscriptionTabContent from "./Tabs/AssetSubscriptionTabContent";
import CommentsTabContent from "./Tabs/CommentsTabContent";
import CustomerTabContent from "./Tabs/CustomerTabContent";
import GeneralHistoryTabContent from "./Tabs/GeneralHistoryTabContent";
import OperationsTabContent from "./Tabs/OperationsTabContent";
import OverviewTabContent from "./Tabs/OverviewTabContent";
import VehicleTabContent from "./Tabs/VehicleTabContent";

export enum ContractViewPageTabs {
  Overview = "Overview",
  Vehicle = "Vehicle",
  Customer = "Customer",
  Operations = "Operations",
  AssetSubscription = "AssetSubscription",
  GeneralHistory = "GeneralHistory",
  Comments = "Comments",
}

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};

const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};

interface OwnProps extends BaseEntityViewInheritableProps<ContractDto> {
  contractId: string | null | undefined;
  contract: ContractDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
  onDelete?: () => void;
  dialogProps?: DialogProps;
}

export type ContractViewProps = OwnProps;

export default function ContractView({
  contractId,
  contract,
  displayProps = defaultDisplayProps,
  headerProps = defaultHeaderProps,
  dialogProps,
  onDelete,
}: ContractViewProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const currentTenant = useCurrentTenant();
  const commonViewParams = useCommonViewParams({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityView(EntityType.Contract),
      viewVariant: displayProps.viewVariant,
    },
  });

  const history = useAppHistory();
  const [isCommunicationInfoModalOpen, setIsCommunicationInfoModalOpen] = useState(false);
  const [isConfirmAllocation, setIsConfirmAllocation] = useState<boolean | undefined>(undefined);
  const [isReallocateVehicleModalOpen, setIsReallocateVehicleModalOpen] = useState(false);
  const [isCheckOutVehicleModalOpen, setIsCheckOutVehicleModalOpen] = useState(false);
  const [isCheckInVehicleModalOpen, setIsCheckInVehicleModalOpen] = useState(false);
  const [isCreateVisualInspectionModalOpen, setIsCreateVisualInspectionModalOpen] = useState(false);
  const [createVisualInspectionBefore, setCreateVisualInspectionBefore] = useState<
    "CheckOut" | "CheckIn" | undefined
  >(undefined);

  const contractRequest = useApiRequest(
    apiClient.contractsApi.apiV1ContractsContractIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      contractId: contractId!,
    },
    {
      deps: [contractId],
      skip: !contractId || !!contract,
    },
  );
  contract = contract || contractRequest?.data;

  const assetSubscriptionRequest = useApiRequest(
    apiClient.assetSubscriptionsApi.apiV1AssetSubscriptionsAssetSubscriptionIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      assetSubscriptionId: contract?.assetSubscription?.id || "",
    },
    {
      deps: [contract?.assetSubscription?.id],
      skip: !contract?.assetSubscription?.id,
    },
  );
  const assetSubscription = assetSubscriptionRequest?.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(currentTenant?.id, EntityType.Contract, contractId || ""),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      contractRequest.handleEntityChanged(data);
    },
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        contract && {
          idValue: contract.id!,
          newTitle: contract.localNumber || "",
        }) ||
      undefined,
  });

  const contractComputedState = useMemo(
    () => ContractHelper.getComputedState(contract),
    [contract],
  );

  return (
    <BaseEntityView
      entityType={EntityType.Contract}
      entityId={contractId}
      entity={contract}
      entityRequest={contractRequest}
    >
      <ViewLayoutV2
        displayProps={displayProps}
        dialogProps={dialogProps}
        header={
          displayProps?.header && (
            <>
              <DetailedViewPageHeader
                image={undefined}
                title={`Contract ${StringHelper.joinIntoString(
                  [contract?.localNumber, contract?.externalNumber],
                  " / ",
                  { skipEmpty: true },
                )} `}
                titleProps={{
                  to: headerProps?.withLink ? ROUTE_PATH.CONTRACT_VIEW(contract?.id) : undefined,
                  typographyProps: headerProps?.typographyProps,
                }}
                subtitle={
                  <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                    <GeneralAttachedTagsDisplay
                      tags={contract?.tags}
                      defaultIsFolded={false}
                      edit={{
                        entityType: TagEntityType.Contract,
                        entityId: contract?.id,
                        onSaved: (newValue) => {
                          contractRequest.updateData((x) => {
                            x.tags = newValue || undefined;
                          });
                        },
                      }}
                    />
                  </AuthorizedElement>
                }
                primaryActions={
                  displayProps.actions && (
                    <Stack direction='row' spacing={1}>
                      <AuthorizedElement permissions={[AppPermission.ContractManage]}>
                        <Button
                          component={AppLink}
                          to={ROUTE_PATH.CONTRACT_EDIT(contract?.id)}
                          variant='outlined'
                          color='text'
                          size='medium'
                          startIcon={<AppIcon of='edit' />}
                        >
                          Edit
                        </Button>
                      </AuthorizedElement>

                      {contractComputedState?.confirmAllocation?.can && (
                        <AuthorizedElement permissions={[AppPermission.ContractManage]}>
                          <Button
                            variant='contained'
                            color='primary'
                            size='medium'
                            startIcon={<AppIcon of='done' />}
                            onClick={() => {
                              setIsConfirmAllocation(true);
                              setIsReallocateVehicleModalOpen(true);
                            }}
                          >
                            Confirm allocation
                          </Button>
                        </AuthorizedElement>
                      )}

                      {contractComputedState?.visualInspectionBeforeCheckOut?.can && (
                        <AuthorizedElement permissions={[AppPermission.ContractManage]}>
                          <Button
                            variant='contained'
                            color='primary'
                            size='medium'
                            startIcon={<AppIcon of='visualInspection' />}
                            onClick={() => {
                              setCreateVisualInspectionBefore("CheckOut");
                              setIsCreateVisualInspectionModalOpen(true);
                            }}
                          >
                            Perform visual inspection before check-out
                          </Button>
                        </AuthorizedElement>
                      )}

                      {contractComputedState?.visualInspectionBeforeCheckIn?.can && (
                        <AuthorizedElement permissions={[AppPermission.ContractManage]}>
                          <Button
                            variant='contained'
                            color='primary'
                            size='medium'
                            startIcon={<AppIcon of='visualInspection' />}
                            onClick={() => {
                              setCreateVisualInspectionBefore("CheckIn");
                              setIsCreateVisualInspectionModalOpen(true);
                            }}
                          >
                            Perform visual inspection before check-in
                          </Button>
                        </AuthorizedElement>
                      )}

                      {contractComputedState?.vehicleCheckOut?.can && (
                        <AuthorizedElement permissions={[AppPermission.ContractManage]}>
                          <Button
                            variant='contained'
                            color='primary'
                            size='medium'
                            startIcon={<AppIcon of='checkOut' />}
                            onClick={() => setIsCheckOutVehicleModalOpen(true)}
                          >
                            Check-out vehicle
                          </Button>
                        </AuthorizedElement>
                      )}

                      {contractComputedState?.vehicleCheckIn?.can && (
                        <AuthorizedElement permissions={[AppPermission.ContractManage]}>
                          <Button
                            variant='contained'
                            color='primary'
                            size='medium'
                            startIcon={<AppIcon of='checkIn' />}
                            onClick={() => setIsCheckInVehicleModalOpen(true)}
                          >
                            Check-in vehicle
                          </Button>
                        </AuthorizedElement>
                      )}
                    </Stack>
                  )
                }
                secondaryActions={
                  displayProps.actions &&
                  contract && (
                    <ContractMenu
                      onDelete={() => (onDelete ? onDelete() : history.goBack())}
                      onUpdate={(newValue) =>
                        newValue ? contractRequest.replaceData(newValue) : contractRequest.refetch()
                      }
                      entity={contract}
                    />
                  )
                }
              />
              <Stack spacing={1}>
                {contract?.stageHistory?.stage === ContractStage.Draft && (
                  <Alert severity='info'>
                    The contract is on{" "}
                    <InlineApiEnumValue
                      type='ContractStage'
                      value={contract?.stageHistory?.stage}
                      sx={{ fontWeight: "bold" }}
                    />{" "}
                    stage. You can delete it or change vehicle only while on this stage. Also, most
                    of the operations are not allowed on this stage.
                  </Alert>
                )}

                {((contract?.settings?.isRequireVisualInspectionBeforeCheckOut &&
                  !contract.checkOutSpotInfo?.visualInspectionId) ||
                  (contract?.settings?.isRequireVisualInspectionBeforeCheckIn &&
                    !contract.checkInSpotInfo?.visualInspectionId)) && (
                  <Alert severity='info'>
                    The contract requires visual inspection before vehicle{" "}
                    {StringHelper.joinIntoString(
                      [
                        contract?.settings?.isRequireVisualInspectionBeforeCheckOut &&
                        !contract.checkOutSpotInfo?.visualInspectionId
                          ? "check-out"
                          : undefined,
                        contract?.settings?.isRequireVisualInspectionBeforeCheckIn &&
                        !contract.checkInSpotInfo?.visualInspectionId
                          ? "check-in"
                          : undefined,
                      ],
                      " and ",
                      { skipEmpty: true },
                    )}
                    .
                  </Alert>
                )}
              </Stack>
            </>
          )
        }
      >
        <PageTabsV2
          tabIdsDefinition={ContractViewPageTabs}
          defaultTabId={ContractViewPageTabs.Overview}
          viewVariant={displayProps?.viewVariant}
          commonViewParams={commonViewParams}
          tabs={[
            { label: "Overview", value: ContractViewPageTabs.Overview, isHideable: false },
            { label: "Vehicle", value: ContractViewPageTabs.Vehicle },
            { label: "Customer", value: ContractViewPageTabs.Customer },
            { label: "Operations", value: ContractViewPageTabs.Operations },
            ...(contract?.assetSubscription
              ? [
                  {
                    label: "Asset subscription",
                    value: ContractViewPageTabs.AssetSubscription,
                  },
                ]
              : []),
            { label: "History", value: ContractViewPageTabs.GeneralHistory },
            { label: "Comments", value: ContractViewPageTabs.Comments },
          ]}
        >
          {({ activeTabId: activeTab }) =>
            contract && (
              <>
                {activeTab === ContractViewPageTabs.Overview && (
                  <OverviewTabContent
                    contract={contract}
                    assetSubscription={assetSubscription}
                    onUpdate={(newValue) =>
                      newValue ? contractRequest.replaceData(newValue) : contractRequest.refetch()
                    }
                    onReallocateVehicle={() => setIsReallocateVehicleModalOpen(true)}
                  />
                )}
                {activeTab === ContractViewPageTabs.Vehicle && (
                  <VehicleTabContent contract={contract} />
                )}
                {activeTab === ContractViewPageTabs.Customer && (
                  <CustomerTabContent contract={contract} />
                )}
                {activeTab === ContractViewPageTabs.Operations && (
                  <OperationsTabContent contract={contract} />
                )}
                {activeTab === ContractViewPageTabs.AssetSubscription && (
                  <AssetSubscriptionTabContent contract={contract} />
                )}
                {activeTab === ContractViewPageTabs.GeneralHistory && (
                  <GeneralHistoryTabContent contract={contract} />
                )}
                {activeTab === ContractViewPageTabs.Comments && (
                  <CommentsTabContent contract={contract} />
                )}
              </>
            )
          }
        </PageTabsV2>

        {/* Communication info */}
        {contract && (
          <ContractCustomerCommunicationInfoModal
            contractCustomerCommunicationInfoProps={{
              contract: contract,
            }}
            open={isCommunicationInfoModalOpen}
            onClose={() => setIsCommunicationInfoModalOpen(false)}
          />
        )}

        {/* Reallocate vehicle */}
        {contract && !assetSubscription && (
          <ContractReallocateVehicleModal
            open={isReallocateVehicleModalOpen}
            onClose={() => setIsReallocateVehicleModalOpen(false)}
            updateProps={{
              contract: contract,
              defaultValues: {
                isConfirmed: isConfirmAllocation,
              },
              onSave: (newValue) => {
                setIsReallocateVehicleModalOpen(false);
                contractRequest?.refetch();
                assetSubscriptionRequest?.refetch();
              },
            }}
          />
        )}

        {/* Reallocate asset */}
        {assetSubscription && (
          <AssetSubscriptionReallocateAssetModal
            open={isReallocateVehicleModalOpen}
            onClose={() => setIsReallocateVehicleModalOpen(false)}
            updateProps={{
              assetSubscription: assetSubscription,
              defaultValues: {
                isConfirmed: isConfirmAllocation,
              },
              onSave: (newValue) => {
                setIsReallocateVehicleModalOpen(false);
                contractRequest?.refetch();
                assetSubscriptionRequest?.refetch();
              },
            }}
          />
        )}

        {/* Check-out vehicle */}
        {contract && (
          <ContractVehicleCheckOutModal
            open={isCheckOutVehicleModalOpen}
            onClose={() => setIsCheckOutVehicleModalOpen(false)}
            checkOutProps={{
              contract: contract,
              onSave: (newValue) => {
                contractRequest.replaceData(newValue);
                setIsCheckOutVehicleModalOpen(false);
              },
            }}
          />
        )}

        {/* Check-in vehicle */}
        {contract && (
          <ContractVehicleCheckInModal
            open={isCheckInVehicleModalOpen}
            onClose={() => setIsCheckInVehicleModalOpen(false)}
            checkInProps={{
              contract: contract,
              onSave: (newValue) => {
                contractRequest.replaceData(newValue);
                setIsCheckInVehicleModalOpen(false);
              },
            }}
          />
        )}

        {/* Create VisualInspection */}
        {contract && (
          <VisualInspectionCreateUpdateModal
            open={isCreateVisualInspectionModalOpen}
            onClose={() => setIsCreateVisualInspectionModalOpen(false)}
            createUpdateProps={{
              defaultValues: {
                vehicleId: contract.vehicle?.id,
                contractId: contract.id,
                departmentId: contract.departmentIds?.[0],
                locationId: contract.locationIds?.[0],
              },
              onSave: async (newValue) => {
                setIsCreateVisualInspectionModalOpen(false);

                // save to contract
                if (createVisualInspectionBefore === "CheckOut") {
                  const response =
                    await apiClient.contractsApi.apiV1ContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPut(
                      {
                        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                        contractId: contract?.id || "",
                        contractSpotInfoVisualInspectionInputDto: {
                          visualInspectionId: newValue?.id,
                        },
                      },
                    );
                  contractRequest.replaceData(response.data);
                } else if (createVisualInspectionBefore === "CheckIn") {
                  const response =
                    await apiClient.contractsApi.apiV1ContractsContractIdVehicleCheckInSpotInfoVisualInspectionPut(
                      {
                        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                        contractId: contract?.id || "",
                        contractSpotInfoVisualInspectionInputDto: {
                          visualInspectionId: newValue?.id,
                        },
                      },
                    );
                  contractRequest.replaceData(response.data);
                }
              },
            }}
          />
        )}
      </ViewLayoutV2>
    </BaseEntityView>
  );
}
